<article>
    <div class="testimony">
        <div class="testimony__content">
            <div class="testimony__header">
                <span class="tag">{{testimony.tags[0].name}}</span>
                <p>{{testimony.publishDate | date :'d MMMM' | titlecase}}</p>
            </div>
            <div class="testimony__image">
                <img [src]="testimony.imageUrl" alt="image" class="customised">
            </div>
            <p class="title">{{testimony.title}}</p>
            <p class="description">{{truncatedDescription}}</p>
        </div>
        <div class="cta">
            <span class="time">
                <img src="/assets/images/v-2024/icones/icon-time.svg" alt="clock" class="customised" />
                <time>{{testimony.readingTime}}</time>
            </span>
            <a [routerLink]="replaceUrl()" class="ds-btn ds-btn--secondary">Lire
                <dep-icon [icon]="'icon-arrow_forward'"></dep-icon>
            </a>
        </div>
    </div>
</article>