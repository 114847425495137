import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SuccessStory } from 'src/app/models/success-stories/SuccessStories.model';
import { GlobalURLs } from 'src/app/GlobalURLs';
import { environment } from 'src/environments/environment';
import { SuccessStoryRequest } from 'src/app/models/success-stories/successStoriesRequest.model';
import { Wrapper } from 'src/app/models/wrapper/wrapper.model';

@Injectable({
  providedIn: 'root'
})
export class SuccessStoriesService {
  private SUCCESS_STORY_PATH = '';

  constructor(private httpClient: HttpClient, private ressourcePath: GlobalURLs) {}

  getSuccessStories(request:SuccessStoryRequest): Observable<Wrapper<SuccessStory>> {
    let params = new HttpParams()
      .set('page', request.page.toString())
      .set('size', request.pageSize.toString());

    if (request.tagIds && request.tagIds.length > 0) {
      params = params.appendAll({"tagIds": request.tagIds});
    }

    return this.httpClient.get<Wrapper<SuccessStory>>(environment.apiUrl + this.ressourcePath.apiSuccessStory, { params });
  }

  public getSuccessStoryById(id: number): Observable<SuccessStory> {
    this.SUCCESS_STORY_PATH = this.ressourcePath.apiSuccessStory + '/' + id;
    return this.httpClient.get<SuccessStory>(environment.apiUrl + this.SUCCESS_STORY_PATH);
  }
  

}