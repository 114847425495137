<article>
    <div class="card">
        <div class="card__content">
            <div class="card__header">
                <span class="tags__container">
                    <span class="tag" *ngFor="let tagName of successStory.tags">
                        <ng-container *ngIf="tagName?.name !== 'France' && tagName?.name !== 'Europe'"> {{tagName.name}}</ng-container>
                      </span>
                    </span>
                <p>{{successStory.publishDate | date :'d MMMM' | titlecase}}</p>
            </div>
            <div class="card__image" [class.modify-height]="isHighlighted">
                <img [src]="successStory.image" alt="image" class="customised">
            </div>
            <p class="title">{{successStory.title}}</p>
            <p class="successStory.description" [innerHTML]="truncatedDescription"></p>
        </div>
        <div class="cta">
            <span class="time">
                <img src="/assets/images/v-2024/icones/icon-time.svg" alt="clock" class="customised" />
                <time>{{successStory.readingTime}}</time>
            </span>
            <a [routerLink]="replaceUrl(successStory)" class="ds-btn ds-btn--secondary">Lire
                <dep-icon [icon]="'icon-arrow_forward'"></dep-icon>
            </a>
        </div>
    </div>
</article>