import { Component, Input, OnInit } from '@angular/core';
import { SuccessStory } from 'src/app/models/success-stories/SuccessStories.model';
import { FormatUrlPipe } from 'src/app/shared/pipes/format-url.pipe';

@Component({
  selector: 'dep-success-story-thumbnail',
  templateUrl: './success-story-thumbnail.component.html',
  styleUrls: ['./success-story-thumbnail.component.scss']
})
export class SuccessStoryThumbnailComponent implements OnInit {
  @Input() successStory: SuccessStory;
  @Input() successStoryIndex: number;
  @Input() isHighlighted: boolean = false;
  public truncatedDescription: string;
  public url: string;

  constructor(private urlFormatter: FormatUrlPipe) { }
  
  ngOnInit(): void {
    this.truncatedDescription = this.successStory.description.length > 150 ? this.successStory.description.substring(0, 150) + '...' : this.successStory.description;
  }

  replaceUrl(successStory: SuccessStory): string {
    successStory.tags.forEach((tag, index, array) => {
      if (['France', 'Europe'].includes(tag.name) && array.length == 1) {
        this.url = "/success-stories/Autres/" + successStory.id + '-' + this.urlFormatter.transform(successStory.url) + '.html'
      } else if (['France', 'Europe'].includes(tag.name) && array.length > 1) {
        array.splice(index, 1)
      } else {
        this.url = "/success-stories/" + this.urlFormatter.transform(array[0].name) + "/" + successStory.id + '-' + this.urlFormatter.transform(successStory.url) + '.html'
      }

    });

    return this.url;
  }

}
