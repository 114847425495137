import { CommonModule } from '@angular/common';
import { NgModule, SecurityContext } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { AutresRessourcesComponent } from './autres-ressources/autres-ressources.component';
import { CardFullWidthComponent } from './card-full-width/card-full-width.component';
import { CardComponent } from './card/card.component';
import { ContributorComponent } from './contributor/contributor.component';
import { DocumentComponent } from './document/document.component';
import { FiltreComponent } from './filtre/filtre.component';
import { ButtonComponent } from './forms/button/button.component';
import { IconTextComponent } from './forms/icon-text/icon-text.component';
import { InputTelComponent } from './forms/input-tel/input-tel.component';
import { InputTextComponent } from './forms/input-text/input-text.component';
import { SelectComponent } from './forms/select/select.component';
import { TextareaComponent } from './forms/textarea/textarea.component';
import { IdeaComponent } from './idea/idea.component';
import { QuoteComponent } from './quote/quote.component';
import { RedirectionComponent } from './redirection/redirection.component';
import { ResourceComponent } from './resource/resource.component';
import { StepComponent } from './step/step.component';
import { TagComponent } from './tag/tag.component';
import { VideoComponent } from './video/video.component';
import { InfoBlocComponent } from './info-bloc/info-bloc.component';
import { MarkdownModule } from 'ngx-markdown';
import { CollapseComponent } from './collapse/collapse.component';
import { SelectMultipleComponent } from './select-multiple/select-multiple.component';
import { InfoCardComponent } from './info-card/info-card.component';
import { ImageCardComponent } from './image-card/image-card.component';
import { InfoCardListComponent } from './info-card-list/info-card-list.component';
import { ImageCardCarouselComponent } from './image-card-carousel/image-card-carousel.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { LinkCardComponent } from './link-card/link-card.component';
import { BtnIconComponent } from './btn-icon/btn-icon.component';
import { GuidedTourPopupComponent } from './guided-tour-popup/guided-tour-popup.component';
import { ExternalLinkComponent } from './v-2024/external-link/external-link.component';
import { IconComponent } from './icon/icon.component';

@NgModule({
  declarations: [
    AutresRessourcesComponent,
    CardComponent,
    CardFullWidthComponent,
    ContributorComponent,
    DocumentComponent,
    FiltreComponent,
    IdeaComponent,
    QuoteComponent,
    RedirectionComponent,
    ResourceComponent,
    StepComponent,
    VideoComponent,
    TagComponent,
    CollapseComponent,
    GuidedTourPopupComponent,
    IconComponent,

    //basic forms module
    InputTelComponent,
    InputTextComponent,
    SelectComponent,
    TextareaComponent,
    IconTextComponent,
    ButtonComponent,
    InfoBlocComponent,
    SelectMultipleComponent,

    //cards
    InfoCardComponent,
    ImageCardComponent,
    InfoCardListComponent,
    ImageCardCarouselComponent,
    LinkCardComponent,

    //buttons
    BtnIconComponent,
    ExternalLinkComponent

  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MatSelectModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    SlickCarouselModule,
    MarkdownModule.forRoot({
      sanitize: SecurityContext.NONE, // turn off sanitization
    })
  ],
  exports: [
    AutresRessourcesComponent,
    ButtonComponent,
    CardComponent,
    CardFullWidthComponent,
    ContributorComponent,
    DocumentComponent,
    FiltreComponent,
    IconTextComponent,
    IdeaComponent,
    QuoteComponent,
    RedirectionComponent,
    ResourceComponent,
    StepComponent,
    VideoComponent,
    TagComponent,
    CollapseComponent,
    GuidedTourPopupComponent,
    IconComponent,

    //basic forms module
    InputTelComponent,
    InputTextComponent,
    SelectComponent,
    TextareaComponent,

    MatSelectModule,
    MatFormFieldModule,
    InfoBlocComponent,
    SelectMultipleComponent,

    //cards
    InfoCardComponent,
    ImageCardComponent,
    InfoCardListComponent,
    ImageCardCarouselComponent,
    LinkCardComponent,

    //buttons
    BtnIconComponent,
    ExternalLinkComponent
    
  ],
  providers: [],
})
export class BasicElementsModule {}
