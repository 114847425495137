import { Component, HostListener, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'dep-menu-sticky',
  templateUrl: './menu-sticky.component.html',
  styleUrls: ['./menu-sticky.component.scss']
})
export class MenuStickyComponent {
  @Input() url: string;
  @Input() links: { fragment: string, label: string }[];

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setActiveLink();
      }
    });
    this.setActiveLink();
  }

  private setActiveLink(): void {
    const fragment = this.router.url.split('#')[1];
    const links = document.querySelectorAll('.navbar__container a');

    links.forEach(link => {
      const linkFragment = link.getAttribute('data-fragment');
      if (linkFragment === fragment && fragment) {
        link.classList.add('highlight');
      } else {
        link.classList.remove('highlight');
      }
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    if (window.scrollY === 0) {
      this.resetActiveLink();
    }
  }

  private resetActiveLink(): void {
    const links = document.querySelectorAll('.navbar__container a');
    links.forEach(link => {
      link.classList.remove('highlight');
    });
  }

}
