<dep-navbar-public-space class="main-header"></dep-navbar-public-space>

<main class="main">
  <!-- Article header content -->
  <dep-article-header-v2 [articleTitle]="video?.title"
    [articleDate]="video?.publishDate | date :'d MMMM yyy' | titlecase"
    [articleDateTime]="video?.publishDate | date :'d MMMM yyy'" [articleReadingTime]="video?.duration"
    [buttonIcon]="'icon-share'" [buttonText]="'Partager'" [articleCategories]="video?.tags"  (buttonAction)="copyUrl()" [isPopover]="true" [title]="video.title" content>
    <div class="breadcrumb" breadcrumb>
      <a [routerLink]="['/']">Accueil</a>
      <span>/</span>
      <a [routerLink]="['/actualites-ressources/.']">Actualités et ressources</a>
      <span>/</span>
      <a [routerLink]="['/videos/.']">Vidéos</a>
      <span>/</span>
      <a [routerLink]="['/videos/.']" class="highlight">{{video.title | slice:0:30 }}...</a>
    </div>
    <button class="btn-previous" (click)="goBack()" breadcrumb>
      <dep-icon [icon]="'icon-arrow_previous'" class="arrow"></dep-icon>
      <p>Retour</p>
    </button>
  </dep-article-header-v2>

  <!-- Article content -->
  <div class="article__wrapper">
    <ng-container *ngIf="video?.imageUrl">
      <img src="{{video?.imageUrl}}" alt="{{video.altImage}}" class="actuality__image customised">
    </ng-container>
    <ng-container *ngFor="let videoContent of video?.contents">

      <!-- Text -->
      <ng-container *ngIf="videoContent.textId">
        <p [innerHTML]="videoContent.paragraph | markdown | async"></p>
      </ng-container>

      <!-- Video -->
      <ng-container *ngIf="videoContent.videoId && videoContent.videoUrl">
        <video id="video" controls src="{{videoContent.videoUrl}}"></video>
        <div class="video-content">
          <p class="video-title">{{videoContent.videoTitle}}</p>
          <p class="video-source">{{videoContent.videoSource}}</p>
        </div>
      </ng-container>

      <!-- YOUTUBE video -->
      <!-- TODO : Allow youtube urls in inputs -->
      <ng-container *ngIf=" videoContent.videoId && !videoContent.videoUrl && videoContent.videoSource">
        <dep-article-youtube-video id="video" [videoUrl]="videoContent.videoSource"
          [videoTitle]="videoContent.videoTitle"></dep-article-youtube-video>
      </ng-container>
      <!-- Document -->
      <ng-container *ngIf="videoContent.documentId">
        <section>
          <dep-article-document [fileName]="videoContent.documentTitle"
            [fileButtonName]="videoContent.downloadButtonTitle" [fileURL]="videoContent.documentUrl">
          </dep-article-document>
        </section>
      </ng-container>

      <!-- Quote -->
      <ng-container *ngIf="videoContent.quoteId">
        <dep-article-quote [quote]="videoContent.quote" [quoteSource]="videoContent.authorName">
        </dep-article-quote>
      </ng-container>

    </ng-container>
    <!-- Likes & vues -->
    <div class="actuality-data" [class.add-margin]="isOpenPopup && suggestions.length == 0">
      <div class="actuality-data__container">
        <div class="actuality-data__item">
          <button (click)="likeVideo()" [disabled]="hasLiked">
            <img src="/assets/images/v-2024/icones/icon-like.svg" alt="icon like" class="customised">
          </button>
          <span>{{ video?.like | formatLikes }}</span>
        </div>
      </div>
      <!-- btn partager -->
      <dep-btn-cta-article [buttonIcon]="'icon-share'" (buttonAction)="setPopupStatus($event)" [buttonText]="'Partager'" [isPopover]="true" [title]="video.title">
      </dep-btn-cta-article>
    </div>
  </div>
  <!-- Suggestions -->
  <section class="suggestions" *ngIf="suggestions?.length > 0">
    <div class="suggestions__container">
      <div class="section__header">
        <p class="title">Sur le même <strong class="highlight">thème</strong></p>
        <a class="ds-btn ds-btn--secondary" [routerLink]="'/videos/.'">Voir toutes les vidéos</a>
      </div>
      <div class="suggestions__cards">
        <ng-container *ngFor="let suggestion of suggestions| slice:0:3">
          <dep-suggestion-card [btnLibelle]="" [suggestion]="suggestion"></dep-suggestion-card>
        </ng-container>
      </div>
      <div class="actus__cta">
        <a class="ds-btn ds-btn--secondary section__cta" [routerLink]="'/videos/.'">Voir toutes les vidéos</a>
      </div>
    </div>
  </section>
</main>
<div *ngIf="isOpen">
  <dep-newsletter [newsletter]="newsletter" (closeNewsletter)='closeNewsletter($event)'></dep-newsletter>
</div>
<dep-footer-public-space></dep-footer-public-space>