import { Component } from '@angular/core';

@Component({
  selector: 'dep-multiple-circles-background',
  templateUrl: './circles-background.component.html',
  styleUrls: ['./circles-background.component.scss']
})
export class MultipleCirclesBackgroundComponent {

}
