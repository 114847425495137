<article class="video-card" *ngIf="video">
    <div class="video-card__container">
        <div class="tags--mobile">
            <ng-container *ngFor="let tag of video?.tags">
                <span class="tag" *ngIf="tag?.name !== 'France' && tag?.name !== 'Europe'">{{tag?.name}}</span>
            </ng-container>
        </div>
        <div class="video-card__image">
            <img src="{{video.imageUrl}}" alt="{{video.altImage}}" loading="lazy" class="customised">
        </div>
        <div class="video-card__content">
            <div class="video-card__tags">
                <ng-container *ngFor="let tag of video?.tags">
                    <span class="tag" *ngIf="tag?.name !== 'France' && tag?.name !== 'Europe'">{{tag?.name}}</span>
                </ng-container>
            </div>
            <p class="title">{{video.title}}</p>
            <p class="author">{{video.authorNameAndRole}}</p>
            <p class="description">{{video.description}}</p>
        </div>
    </div>
    <div class="video-card__content--footer">
        <span class="duration">
            <img src="/assets/images/v-2024/icones/icon-time.svg" alt="clock" class="customised" />
            {{video?.duration}}
        </span>
        <a [routerLink]="replaceUrl()" class="ds-btn ds-btn--secondary">
            <span>Visionner</span>
            <dep-icon [icon]="'icon-play'"></dep-icon>
        </a>
    </div>
</article>