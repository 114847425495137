import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { ForumService } from '../forums/forum.service';
import { ActualityService } from '../actualities/actuality.service';
import { PodcastService } from '../podcasts/podcast.service';
import { StudyService } from '../studies/study.service';
import { VideosService } from '../videos/videos.service';
import { FormatUrlPipe } from 'src/app/shared/pipes/format-url.pipe';
import { Forum } from 'src/app/models/forums/forum.model';
import { Actuality } from 'src/app/models/actualities/actuality.model';
import { Podcast } from 'src/app/models/podcasts/podcast.model';
import { Study } from 'src/app/models/studies/study.model';
import { Video } from 'src/app/models/videos/videos.model';
import { Suggestion, TypeArticle } from 'src/app/models/suggestions/Suggestions.model';
import { map, switchMap } from 'rxjs/operators';
import { ForumRequest } from 'src/app/models/forums/forum-request.model';
import { ActualityRequest } from 'src/app/models/actualities/actualityRequest.model';
import { PodcastRequest } from 'src/app/models/podcasts/podcast-request.model';
import { StudyRequest } from 'src/app/models/studies/study-request.model';
import { VideoRequest } from 'src/app/models/videos/video-request.model';
import { SuccessStory } from 'src/app/models/success-stories/SuccessStories.model';
import { SuccessStoryRequest } from 'src/app/models/success-stories/successStoriesRequest.model';
import { SuccessStoriesService } from '../success-stories/success-stories.service';


@Injectable({
  providedIn: 'root'
})
export class SuggestionService {
  constructor(
    private forumService: ForumService,
    private actualityService: ActualityService,
    private podcastService: PodcastService,
    private studyService: StudyService,
    private videoService: VideosService,
    private successStoryService: SuccessStoriesService,
    private urlFormatter: FormatUrlPipe
  ) {}

  setupSuggestions(
    suggestions: Suggestion[],
    article: Forum | Actuality | Podcast | Study | Video | SuccessStory,
    articleType: TypeArticle
  ): Observable<Suggestion[]> {
    let tags = article.tags
       const request = { tagIds: tags.map(tag => tag.id), page: 1, pageSize: 3 };

    return this.addSuggestionsIfNeeded(suggestions, article.id, articleType, request, TypeArticle.ACTUALITE).pipe(
      switchMap(() => this.addSuggestionsIfNeeded(suggestions, article.id, articleType, request, TypeArticle.FORUM)),
      switchMap(() => this.addSuggestionsIfNeeded(suggestions, article.id, articleType, request, TypeArticle.VIDEO)),
      switchMap(() => this.addSuggestionsIfNeeded(suggestions, article.id, articleType, request, TypeArticle.ETUDE)),
      switchMap(() => this.addSuggestionsIfNeeded(suggestions, article.id, articleType, request, TypeArticle.PODCAST)),
      switchMap(() => this.addSuggestionsIfNeeded(suggestions, article.id, articleType, request, TypeArticle.SUCCESS_STORY)),
      map(() => suggestions)
    );
  }

  setupSuggestionsSuccessStories(
    suggestions: Suggestion[],
    article: Forum | Actuality | Podcast | Study | Video | SuccessStory,
    articleType: TypeArticle
  ): Observable<Suggestion[]> {
    let tags = article.tags
       const request = { tagIds: tags.map(tag => tag.id), page: 1, pageSize: 3 };

    return this.addSuggestionsIfNeeded(suggestions, article.id, articleType, request, TypeArticle.SUCCESS_STORY).pipe(
      switchMap(() => this.addSuggestionsIfNeeded(suggestions, article.id, articleType, request, TypeArticle.ACTUALITE)),
      switchMap(() => this.addSuggestionsIfNeeded(suggestions, article.id, articleType, request, TypeArticle.FORUM)),
      switchMap(() => this.addSuggestionsIfNeeded(suggestions, article.id, articleType, request, TypeArticle.VIDEO)),
      switchMap(() => this.addSuggestionsIfNeeded(suggestions, article.id, articleType, request, TypeArticle.ETUDE)),
      switchMap(() => this.addSuggestionsIfNeeded(suggestions, article.id, articleType, request, TypeArticle.PODCAST)),
      map(() => suggestions)
    );
  }

  private addSuggestionsIfNeeded(suggestions: Suggestion[], currentArticleId: number,articleType:TypeArticle, request: ForumRequest | ActualityRequest | PodcastRequest | StudyRequest | VideoRequest | SuccessStoryRequest, typeArticle: TypeArticle): Observable<any> {
    if (suggestions.length >= 3) {
      return of(null);
    }

    let serviceCall: Observable<any>;

    switch (typeArticle) {
      case TypeArticle.ACTUALITE:
        serviceCall = this.actualityService.getActualities(request);
        break;
      case TypeArticle.FORUM:
        serviceCall = this.forumService.getForums(request);
        break;
      case TypeArticle.PODCAST:
        serviceCall = this.podcastService.getPodcasts(request);
        break;
      case TypeArticle.VIDEO:
        serviceCall = this.videoService.getVideos(request);
        break;
      case TypeArticle.ETUDE:
        serviceCall = this.studyService.getStudies(request);
        break;
      case TypeArticle.SUCCESS_STORY:
        serviceCall = this.successStoryService.getSuccessStories(request);
        break;
      default:
        return of(null);
    }

    return serviceCall.pipe(
      map(result => {
        this.addSuggestions(suggestions, result.data, currentArticleId,articleType, typeArticle);
      })
    );
  }

  private addSuggestions(suggestions: Suggestion[], items: Forum[] | Actuality[] | Podcast[] | Study[] | Video[] | SuccessStory[], currentArticleId: number,articleType:TypeArticle, typeArticle: TypeArticle): void {
    items.forEach(item => {
      if (suggestions.length < 3 ) {
        let suggestion: Suggestion;

        switch (typeArticle) {
          case TypeArticle.ACTUALITE:
            suggestion = {
              id: item.id,
              url: this.replaceUrl(item, typeArticle),
              title: item.title,
              description: item.description,
              readingTime: item.readingTime,
              imageUrl: item.imageUrl,
              publishDate: new Date(item.publishDate),
              typeArticle: typeArticle
            };
            break;
          case TypeArticle.FORUM:
            suggestion = {
              id: item.id,
              url: this.replaceUrl(item, typeArticle),
              title: item.title,
              description: item.altMetaDescription,
              readingTime: item.readingTime,
              imageUrl: item.imageUrl,
              publishDate: new Date(item.publishDate),
              typeArticle: typeArticle
            };
            break;
          case TypeArticle.PODCAST:
            suggestion = {
              id: item.id,
              url: this.replaceUrl(item, typeArticle),
              title: item.title,
              description: item.description,
              readingTime: item.duration,
              imageUrl: item.imageUrl,
              publishDate: new Date(item.publishDate),
              typeArticle: typeArticle
            };
            break;
          case TypeArticle.VIDEO:
            suggestion = {
              id: item.id,
              url: this.replaceUrl(item, typeArticle),
              title: item.title,
              description: item.description,
              readingTime: item.duration,
              imageUrl: item.imageUrl,
              publishDate: new Date(item.publishDate),
              typeArticle: typeArticle
            };
            break;
          case TypeArticle.ETUDE:
            suggestion = {
              id: item.id,
              url: this.replaceUrl(item, typeArticle),
              title: item.title,
              description: item.description,
              readingTime: item.readingTime,
              imageUrl: item.imageUrl,
              publishDate: new Date(item.publishDate),
              typeArticle: typeArticle
            };
            break;
          case TypeArticle.SUCCESS_STORY:
              suggestion = {
                id: item.id,
                url: this.replaceUrl(item, typeArticle),
                title: item.title,
                description: item.description,
                readingTime: item.readingTime,
                imageUrl: item.imageUrl,
                publishDate: new Date(item.publishDate),
                typeArticle: typeArticle
              };
              break;  
          default:
            throw new Error('TypeArticle non supporté');
        }
        if(suggestion.id !== currentArticleId || suggestion.typeArticle !== articleType){
          suggestions.push(suggestion);
        }
      }
    });
  }

  public replaceUrl(article: Forum | Actuality | Podcast | Study | Video | SuccessStory, typeArticle: TypeArticle): string {
    const tags = article.tags;
    if (!tags) {
      console.error('Tags are undefined for the given article:', article);
      return article.url || '';
    }
    tags.forEach((tag, index, array) => {
      if (['France', 'Europe'].includes(tag.name) && array.length == 1) {
        article.url = "/" + typeArticle + "/Autres/" + article.id + '-' + this.urlFormatter.transform(article.url) + '.html';
      } else if (['France', 'Europe'].includes(tag.name) && array.length > 1) {
        array.splice(index, 1);
      } else {
        article.url = "/" + typeArticle + "/" + this.urlFormatter.transform(array[0].name) + "/" + article.id + '-' + this.urlFormatter.transform(article.url) + '.html';
      }
    });

    return article.url;
  }
}