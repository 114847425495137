<dep-navbar-public-space class="main-header"></dep-navbar-public-space>

<main class="main">
  <!-- Article header content -->
  <dep-article-header-v2 [articleTitle]="actuality?.title"
    [articleDate]="actuality?.publishDate | date :'d MMMM yyy' | titlecase"
    [articleDateTime]="actuality?.publishDate | date :'d MMMM yyy'" [articleReadingTime]="actuality?.readingTime"
    [buttonIcon]="'icon-share'" [buttonText]="'Partager'" [articleCategories]="actuality?.tags" [isPopover]="true" [title]="actuality?.title">
    <div class="breadcrumb" breadcrumb>
      <a [routerLink]="['/']">Accueil</a>
      <span>/</span>
      <a [routerLink]="['/actualites-ressources/.']">Actualités et ressources</a>
      <span>/</span>
      <a [routerLink]="['/actualites/.']">Actualités</a>
      <span>/</span>
      <a class="highlight" [routerLink]="['/actualites/.']">{{actuality?.title | slice:0:30 }}...</a>
    </div>
    <button class="btn-previous" (click)="goBack()" breadcrumb>
      <dep-icon [icon]="'icon-arrow_previous'" class="arrow"></dep-icon>
      <p>Retour</p>
    </button>
  </dep-article-header-v2>

  <div class="actualities__container">
    <!-- Article content -->
    <div class="article-wrapper" [class.modify-margin]="!offer">
      <!-- Image -->
      <ng-container *ngIf="actuality?.bannerImageUrl ? actuality?.bannerImageUrl : actuality?.imageUrl">
        <img src="{{actuality?.bannerImageUrl ? actuality?.bannerImageUrl : actuality?.imageUrl}}" alt="{{actuality.altImage}}" class="actuality__image customised">
      </ng-container>
      <ng-container *ngFor="let actualityContent of actuality?.contents">
        <!-- Text -->
        <ng-container *ngIf="actualityContent.textId">
          <p [innerHTML]="actualityContent.paragraph | markdown | async"></p>
        </ng-container>

        <!-- Quote -->
        <ng-container *ngIf="actualityContent.quoteId">
          <dep-article-quote [quote]="actualityContent.quote" [quoteSource]="actualityContent.authorName">
          </dep-article-quote>
        </ng-container>

        <!-- Video -->
        <ng-container *ngIf="actualityContent.videoId && actualityContent.videoUrl">
          <dep-article-video [videoUrl]="actualityContent.videoUrl" [videoType]="'video/mp4'"
            [videoTitle]="actualityContent.videoTitle" [videoSource]="actualityContent.videoSource">
          </dep-article-video>
        </ng-container>

        <!-- YOUTUBE video -->
        <!-- TODO : Allow youtube urls in inputs -->
        <ng-container *ngIf="actualityContent.videoId && !actualityContent.videoUrl && actualityContent.videoSource">
          <dep-article-youtube-video [videoUrl]="actualityContent.videoSource"
            [videoTitle]="actualityContent.videoTitle"></dep-article-youtube-video>
        </ng-container>

        <!-- Images carousel -->

        <ng-container *ngIf="actualityContent.imagesId">
          <div class="data__carrousel">
            <dep-new-carousel #carousel>
              <ng-container *ngFor="let imageUrl of actualityContent.imagesUrls;let i=index">
                <dep-new-carousel-item [imageSrc]="imageUrl" [imageAlt]="actualityContent.altImages[i]"
                  *appCarouselSlide>
                </dep-new-carousel-item>
              </ng-container>
            </dep-new-carousel>
          </div>
          <br />
          <!--   case of independant title and title -->
          <p class="image-carousel__slide-title">{{actualityContent.slideShowTitle}}</p>
          <p class="image-carousel__slide-text">{{actualityContent.imageSource}}</p>
        </ng-container>

        <!-- Image -->
        <ng-container *ngIf="actualityContent.imageId">
          <dep-article-image-carousel [carouselTitle]="actualityContent.imageTitle"
            [carouselSource]="actualityContent.imageSource">
            <dep-article-image-carousel-item [imageSrc]="actualityContent.imageUrl"
              [imageAlt]="actualityContent.altImage" *appCarouselSlide></dep-article-image-carousel-item>
          </dep-article-image-carousel>
        </ng-container>

        <!-- Document -->
        <ng-container *ngIf="actualityContent.documentId">
          <dep-article-document [fileName]="actualityContent.documentTitle"
            [fileButtonName]="actualityContent.downloadButtonTitle"
            [fileURL]="actualityContent.documentUrl"></dep-article-document>
        </ng-container>

        <!-- Podcast -->
        <ng-container *ngIf="actualityContent.podcastId">
          <dep-article-podcast [podcast]="actualityContent.iframe"></dep-article-podcast>
        </ng-container>
      </ng-container>
      <!-- Likes & vues -->
      <div class="actuality-data" [class.add-margin]="isOpenPopup && suggestions.length == 0">
        <div class="actuality-data__container">
          <div class="actuality-data__item">
            <button (click)="likeActuality()" [disabled]="hasLiked">
              <img src="/assets/images/v-2024/icones/icon-like.svg" alt="icon like" class="customised">
            </button>
            <span>{{ actuality?.like | formatLikes }}</span>
          </div>
        </div>
        <!-- btn partager -->
        <dep-btn-cta-article [buttonIcon]="'icon-share'" (buttonAction)="setPopupStatus($event)" [buttonText]="'Partager'" [isPopover]="true" [title]="actuality?.title" >
        </dep-btn-cta-article>
      </div>
    </div>
    <div class="actualities__sidebar" *ngIf="offer">
      <p class="title">Offre de recrutement affiliée</p>
      <div class="offer__container">
        <div class="offer__content">
          <img *ngIf="offer?.logo" src="{{offer?.logo}}" alt="logo startup" class="customised" />
          <p class="title company__title">{{offer?.companyLabel}}</p>
          <p class="title company__profile">{{offer?.projectName}}</p>
          <span class="city">
            <dep-icon [icon]="'icon-place'"></dep-icon>
            {{offer?.city}}
          </span>
        </div>
        <a class="ds-btn ds-btn--secondary apply-cta" [routerLink]="offer?.internalUrl">Consulter l'offre
          <dep-icon [icon]="'icon-open_in_new'"></dep-icon>
        </a>
      </div>
      <a class="bloc-tango__container" [routerLink]="'/devenir-advisor/.'">
        <img src="/assets/images/v-2024/logos/logo-tango.svg" alt="logo tango" class="customised">
        <p class="title">Tango : recrutez ou rejoignez un advisory board</p>
        <div class="bloc-tango__content">
          <p class="description">Un advisory board est un comité qui apporte une expertise sur des sujets stratégiques à
            titre consultatif.</p>
          <dep-icon [icon]="'icon-arrow_forward'"></dep-icon>
        </div>
      </a>
    </div>
  </div>
  <!-- Suggestions -->
  <section class="suggestions" *ngIf="suggestions?.length > 0">
    <div class="suggestions__container">
        <div class="section__header">
          <p class="title">Sur le même <strong class="highlight">thème</strong></p>
          <a class="ds-btn ds-btn--secondary" [routerLink]="'/actualites/.'">Voir toutes les actualités</a>
      </div>
        <div class="suggestions__cards">
            <ng-container *ngFor="let suggestion of suggestions| slice:0:3">
              <dep-suggestion-card [btnLibelle]="" [suggestion]="suggestion"></dep-suggestion-card>
            </ng-container>
        </div>
        <div class="actus__cta">
          <a class="ds-btn ds-btn--secondary section__cta" [routerLink]="'/actualites/.'"
           >Voir toutes les actualités</a>
        </div>
    </div>
</section>
</main>
<div *ngIf="isOpen">
  <dep-newsletter [newsletter]="newsletter" (closeNewsletter)='closeNewsletter($event)'></dep-newsletter>
</div>
<dep-footer-public-space></dep-footer-public-space>