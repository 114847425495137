<dep-navbar-public-space></dep-navbar-public-space>

<main class="main">
  <!-- Fil d'ariane et titre -->
  <dep-page-header pageTitle="Comprendre l’écosystème d’accompagnement" [longTitle]='true'>
    <dep-breadcrumb [breadcrumbLabel]="'Fil d’ariane'" breadcrumb>
      <dep-breadcrumb-item [itemRouterLink]="'/'" *appBreadcrumbItem>Accueil</dep-breadcrumb-item>
      <dep-breadcrumb-item [itemRouterLink]="'/creer-sa-startup/.'" *appBreadcrumbItem>Envie
        d'entreprendre</dep-breadcrumb-item>
      <dep-breadcrumb-item *appBreadcrumbItem>Comprendre l’écosystème d’accompagnement</dep-breadcrumb-item>
    </dep-breadcrumb>
  </dep-page-header>
  <!--  -->

  <!-- Bloc vagues bleu clair  -->
  <dep-vagues-bleu-clair titleVaguesBleu="Vous n’êtes pas seul">
    <p>
      Créer une entreprise peut sembler long et complexe, mais rassurez-vous : vous ne serez jamais seul !
    </p>
    <p>
      L’accompagnement permet aux startups, outre la réduction de moitié du risque de faillite de bénéficier d’une large
      gamme de prestations à toutes les étapes d’un projet : aide au dérisquage de la technologie, soutien sur la
      propriété intellectuelle, accès à des formations, du coaching, des investisseurs, des experts, des
      infrastructures…
    </p>
  </dep-vagues-bleu-clair>
  <!--  -->

  <!-- Contenu éditorial -->
  <dep-page-section sectionTitle="Qui sont les structures d’accompagnement ?">
    <article class="article">
      <!-- Qui sont les structures d’accompagnement ?  -->
      <div class="article__section grid-container">
        <div class="lg-grid-col-9 md-grid-col-8 sm-grid-col-4">
          <p>Beaucoup se sont hasardés à tenter de représenter l’écosystème d’accompagnement deeptech. Peu y sont
            parvenus. La raison est simple : les écosystèmes deeptech sont fondamentalement régionaux, avec leurs
            propres modes de fonctionnement et interactions. </p>

          <p>On y retrouve des acteurs emblématiques</p>

          <ul>
            <li>
              <b>Les structures de valorisation</b> : les SATT bien évidemment, mais aussi les filiales d’organismes de
              recherche, très positionnés sur l’amont de la chaîne de valeur,
            </li>
            <li>
              <b>Les incubateurs :</b> pour booster le démarrage du projet. Ils sont publics et privés,
            </li>
            <li>
              <b>Des accélérateurs :</b> à la fois sectoriels et géographiques. Ils aident le projet à passer un cap,
            </li>
            <li>
              <b>Des startups studios :</b> ils construisent un projet de toutes pièces,
            </li>
            <li>
              <b>Les partenaires :</b> avocats, conseils, juristes, spécialistes, experts, ils gravitent autour des
              projets pour les structurer et leur apporter une expertise précise.
            </li>
          </ul>

          <p>Notre parti pris consiste à ne pas présenter l’offre par acteur, mais par thématique. Vous pourrez par la
            suite trouver dans son écosystème l’acteur qui propose l’offre qui l vous intéresse.</p>

          <h2>L’accompagnement s’adapte à votre maturité et vos enjeux</h2>

          <p>Nous distinguons 5 grandes catégories d’offres d’accompagnement:</p>

          <ul class="content-list">
            <li>
              Juridique et règlementaire,
            </li>
            <li>
              Technologie et production,
            </li>
            <li>
              Développement commercial,
            </li>
            <li>
              Financement,
            </li>
            <li>
              Constitution et organisation de l’équipe,
            </li>
            <li style="list-style-type: none;">
              … et 3 grands stades de maturité
            </li>
          </ul>

          <!-- Tableau -->
          <table id="t01">
            <tr>
              <th style="width:120px;"></th>
              <th style="width:170px;">Idéation</th>
              <th style="width:190px;">Lancement</th>
              <th>Croissance</th>
            </tr>
            <tr>
              <td>Enjeu</td>
              <td>Dérisquer sa technologie et analyser les conditions de succès sur le marché</td>
              <td>Créer la société, tester les premières versions de l’offre et itérer sur le produit en fonction des
                retours clients
              </td>
              <td>Développer son chiffre d’affaire sur ses segments de marché et industrialiser sa production</td>
            </tr>
            <tr>
              <td class="bg-tab-row">Taille de l’entreprise</td>
              <td class="bg-tab-row">Associés</td>
              <td class="bg-tab-row">
                < 5 employés (dont stagiaires)</td>
              <td class="bg-tab-row">5 – 50 employés
              </td>
            </tr>
            <tr>
              <td>Source de financement de l’entreprise</td>
              <td>Fonds de maturation et fonds propre des associés</td>
              <td>Seed et financement public (CIR, subventions)</td>
              <td>Série A, prêst et financement public</td>
            </tr>
            <tr>
              <td class="bg-tab-row">Structures clés</td>
              <td class="bg-tab-row">Structures de valorisation et studio</td>
              <td class="bg-tab-row">Incubateur et studio</td>
              <td class="bg-tab-row">Accélérateur</td>
            </tr>
          </table>
          <!--  -->
        </div>
      </div>
      <!--  -->

      <!-- Idéation -->
      <div class="article__section grid-container">
        <div class="lg-grid-col-9 md-grid-col-8 sm-grid-col-4">
          <h3>Idéation</h3>
          <p>
            <b>Objectifs : maturation technologique, propriété intellectuelle, formation de l’équipe fondatrice,
              validation du business plan</b>
          </p>
          <p>
            A cette étape, le projet est au stade d’idée ou de concept
          </p>
          <p>
            Les porteurs de projet doivent constituer une équipe fondatrice et résoudre des défis clés :
          </p>

          <ul>
            <li>
              <b>Business :</b> analyser les potentiels de marchés,
            </li>
            <li>
              <b>Equipe :</b> constituer une équipe fondatrice qui partage une vision et des valeurs communes,
            </li>
            <li>
              <b>Technologie :</b> dérisquer la technologie et tester une Proof of Concept auprès de partenaires
              potentiels,
            </li>
            <li>
              <b>Règlementaire :</b> protéger et acquérir la propriété intellectuelle,
            </li>
            <li>
              <b>Financement :</b> financer la maturation technologique du projet.
            </li>
          </ul>

          <p>L’accompagnement proposé dépend de votre profil</p>

          <p>Si vous avez un profil scientifique, vous pourrez être accompagnés dans la maturation technologique, la
            compréhension des enjeux marchés, la protection de la propriété intellectuelle, la recherche d’associé, le
            financement…</p>

          <p>Si vous êtes entrepreneur issu du monde de l’entreprise, vous pourrez être formés à la découverte du monde
            la recherche, la compréhension de la propriété intellectuelle, la vulgarisation des technologies qui
            répondent aux besoins marchés que vous avez identifié, à la recherche d’associé…</p>

          <p>Le mentoring par des experts métiers (tech, avocats, ingénieurs, propriété intellectuelle) et sectoriels
            permet aux porteurs de projet d’éviter les écueils liés au lancement, et le partage de pairs à pairs incite
            à l’entraide et aux retours d’expériences collectifs et individuels.</p>
        </div>

        <!-- <aside class="lg-grid-col-3 md-grid-col-4 sm-grid-col-3">
          <dep-idea ideaTitle="La SAS, société par actions simplifiées" ideaAlignOffset="medium"
            externalRedirection="https://bpifrance-creation.fr/encyclopedie/structures-juridiques/entreprendre-a-plusieurs/sas-societe-actions-simplifiee"
            titleLink="Les SAS">
            Découvrez cette forme juridique sur Bpifrance Université
          </dep-idea>
        </aside> -->
      </div>

      <!--Lancement-->
      <div class="article__section grid-container">
        <div class="lg-grid-col-9 md-grid-col-8 sm-grid-col-4">
          <h3>Lancement</h3>
          <p>
            <b>Objectifs : finalisation du produit et commercialisation, premiers financements, premiers recrutements,
              rédaction de contrats</b>
          </p>
          <p>
            La startup deeptech est créée, la maturation technologique a abouti sur un produit cible qu’il faut affiner
            en fonction des besoins des premiers clients. Des contrats de collaboration sont signés, l’entreprise
            recrute ses premières ressources et finance son amorçage (seed money et subventions).
          </p>
          <p>
            A cette étape l’offre d’accompagnement porte principalement sur :
          </p>

          <ul>
            <li>
              <b>Business :</b> prospection, conduite d’entretiens clients, choix des indicateurs de pilotage de
              l’activité...,
            </li>
            <li>
              <b>Recrutement des premières ressources :</b> visibilité de l’offre, entretien d’embauche, process
              d’onboarding, cadre managérial…,
            </li>
            <li>
              <b>Questions juridiques :</b> rédaction de contrats commerciaux ou d’embauche,
            </li>
            <li>
              <b>Accès aux financements:</b> recherche de financements publics, préparation aux pitchs, mise en relation
              investisseurs,
            </li>
            <li>
              <b>Evolution technologique :</b> amélioration continue du produit par itération (méthode de design
              thinking).
            </li>
          </ul>

        </div>

        <aside class="lg-grid-col-3 md-grid-col-4 sm-grid-col-3">
          <dep-idea ideaTitle="Des exemples de ressources mises à disposition" ideaAlignOffset="medium">
            <ul>
              <li>
                Licences d’outisl (CRM, compta…) </li>
              <li>
                Pool d’experts métiers (tech, RH, marketing…) </li>
              <li>
                Mise en relation grands comptes et investisseurs </li>
              <li>
                Participation à des évènements </li>
              <li>
                Visibilité et communication </li>
              <li>Hébergement (gratuit ou payant) </li>
            </ul>

          </dep-idea>
        </aside>
      </div>

      <!-- Croissance -->
      <div class="article__section grid-container">
        <div class="lg-grid-col-9 md-grid-col-8 sm-grid-col-4">
          <h3>Croissance</h3>
          <p>
            <b>Objectifs : passage à l’échelle, industrialisation, financements en série A et B, croissance du CA et de
              la rentabilité, internationalisation</b>
          </p>
          <p>
            La startup deeptech propose un produit mature et bien adapté à son marché. Il s’agit maintenant
            d’industrialiser la production, de s’adapter aux contraintes légales liées au passage à l’échelle, de bien
            structurer son équipe et de financer son développement (série A puis B). La startup peut maintenant penser à
            son expansion à l’international et sur de nouveaux marchés, et continuer l’optimisation de ses processus et
            de sa supply chain.
          </p>
          <p>
            Pour poursuivre son développement marché, l’entreprise peut être accompagnée, souvent par des structures de
            type « accélérateur » sur du business développement, sa structuration RH, son développement industriel et
            son internationalisation.
          </p>

          <p>L’accompagnateur peut aider notamment sur les aspects suivants :</p>

          <ul>
            <li>
              <b>Juridique :</b> contraintes réglementaires internationales, mise à disposition d’une veille
              réglementaire…,
            </li>
            <li>
              <b>Ressources humaines :</b> accompagner la croissance de l’équipe, trouver des profils séniors …,
            </li>
            <li>
              <b>Financement :</b> mise en relation avec des investisseurs, soutien à la levée de fonds, financement
              public ou par la dette…,
            </li>
            <li>
              <b>Business :</b> booster sa traction commerciale et conquérir de nouveaux marchés, optimiser la chaîne
              d’approvisionnement…,
            </li>
            <li>
              <b>Technologie :</b> industrialiser la production, conception de nouveaux produits….
            </li>
          </ul>

          <p>Ce qui n’était à l’origine qu’un projet né dans l’imagination d’un entrepreneur est devenu une PME deeptech
            en pleine croissance. Grâce à l’accompagnement, l’entrepreneur a pu éviter de nombreux écueils et accéder à
            des offres et services déterminants pour son succès. L’entreprise est maintenant capable de voler de ses
            propres ailes !</p>

        </div>

        <aside class="lg-grid-col-3 md-grid-col-4 sm-grid-col-3">
          <dep-idea ideaTitle="Des exemples de ressources mises à disposition " ideaAlignOffset="medium">
            <ul>
              <li>Mise en relation grands comptes et investisseurs</li>
              <li>Participation à des évènements spécialisés (VivaTech)</li>
              <li>Financement : certains accélérateurs investissent en propre dans les startups</li>
            </ul>
          </dep-idea>
        </aside>
      </div>

    </article>
  </dep-page-section>
  <!-- FIN de Contenu éditorial -->

  <!-- Comprendre les contreparties à l’accompagnement -->
  <dep-page-section sectionTitle="Comprendre les contreparties à l’accompagnement" sectionTheme="medium">
    <article class="article">
      <div class="article__section grid-container">
        <div class="lg-grid-col-9 md-grid-col-8 sm-grid-col-4">
          <p>Les contreparties attendues par l’accompagnateur peuvent prendre plusieurs formes, financières ou non. Il
            est
            donc important de les clarifier avec lui et de s’assurer qu’elles soient proportionnées à la valeur ajoutée
            de
            l’offre d’accompagnement.</p>
        </div>
      </div>
    </article>

    <div class="grid-container">
      <dep-icon-text class="lg-grid-col-3 md-grid-col-2 sm-grid-col-4" alignContent="center"
        icon="/assets/images/contreparties/ic-nes-organisateur.svg">
        Prestations non financières : dédier du temps, prêter son image…
      </dep-icon-text>
      <dep-icon-text class="lg-grid-col-3 md-grid-col-2 sm-grid-col-4" alignContent="center"
        icon="/assets/images/contreparties/ic-nes-billet.svg">
        Paiement cash, paiement « upfront », forfait mensuel…
      </dep-icon-text>
      <dep-icon-text class="lg-grid-col-3 md-grid-col-2 sm-grid-col-4" alignContent="center"
        icon="/assets/images/contreparties/ic-nes-euro.svg">
        Pourcentage sur les revenus (royalties), une rémunération sur la perspective de revenus futurs
      </dep-icon-text>
      <dep-icon-text class="lg-grid-col-3 md-grid-col-2 sm-grid-col-4" alignContent="center"
        icon="/assets/images/contreparties/ic-nes-facture.svg">
        Entrée au Capital de la startup, prise de participation en espèces ou en conversion de créances
      </dep-icon-text>
    </div>
  </dep-page-section>
  <!-- FIN de Comprendre les contreparties à l’accompagnement -->

  <!-- 6 conseils pour choisir votre accompagnateur -->
  <dep-page-section>
    <article class="article">
      <div class="article__section grid-container">
        <div class="lg-grid-col-12 md-grid-col-8 sm-grid-col-4">
          <h2>6 conseils pour choisir votre accompagnateur</h2>

          <div class="resources grid-container">
            <dep-resource class="lg-grid-col-4 md-grid-col-4" [noDecoration]="true">
              Autodiagnostiquez votre projet et ses enjeux du moment pour identifier votre besoin d’accompagnement.
            </dep-resource>

            <dep-resource class="lg-grid-col-4 md-grid-col-4" [noDecoration]="true">
              Rationnalisez votre choix avec des indicateurs de performances (trackrecord; nombres de RH
              d’accompagnement / nombre de startups; rythme de formation; nombre de mises en relation
              investisseurs/clients…)
            </dep-resource>
            <dep-resource class="lg-grid-col-4 md-grid-col-4" [noDecoration]="true">
              Demandez des retours d’expérience sur la structure de la part d’entrepreneurs alumni (rejoignez la
              communauté Les Deeptech pour vous connecter à tous les entrepreneurs Deeptech de France)
            </dep-resource>
            <dep-resource class="lg-grid-col-4 md-grid-col-4" [noDecoration]="true">
              Renseignez-vous sur les contreparties demandées pour pouvoir comparer les structures entre elles (cash,
              participations, autres…).
            </dep-resource>
            <dep-resource class="lg-grid-col-4 md-grid-col-4" [noDecoration]="true">
              Faites confiance au « fit » avec le personnel de la structure. Vous allez passer du temps avec eux, autant
              que ce soit dans un climat de travail agréable !
            </dep-resource>
            <dep-resource class="lg-grid-col-4 md-grid-col-4" [noDecoration]="true">
              Ne multipliez pas les prestations d’accompagnement : cinq accompagnateurs simultanés peuvent avoir moins
              de valeur ajoutée qu’un seul bien adapté à vos enjeux !
            </dep-resource>
          </div>
        </div>
      </div>
      <!-- Fin de 6 conseils pour choisir votre accompagnateur -->

      <!-- Les autres composantes -->
      <h2>Les autres composantes</h2>
      <div class="article__section grid-container">

        <dep-step class="lg-grid-col-3 md-grid-col-8 sm-grid-col-4"><a
            [routerLink]="['/creer-sa-startup/transfert-de-technologies.html']">Créer la startup et intégrer la PI</a>
        </dep-step>

        <dep-step class="lg-grid-col-3 md-grid-col-8 sm-grid-col-4"><a
            [routerLink]="['/creer-sa-startup/financer-son-projet.html']">Financer
            son projet</a></dep-step>

      </div>
    </article>
  </dep-page-section>
  <!-- FIN de Les autres composantes -->
</main>

<dep-footer-public-space></dep-footer-public-space>