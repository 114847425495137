import { Component, Input } from '@angular/core';
import { Actuality } from 'src/app/models/actualities/actuality.model';
import { FormatUrlPipe } from 'src/app/shared/pipes/format-url.pipe';
import Swiper, { Navigation, Pagination } from 'swiper';

@Component({
  selector: 'dep-actualities-carousel',
  templateUrl: './actualities-carousel.component.html',
  styleUrls: ['./actualities-carousel.component.scss']
})
export class ActualitiesCarouselComponent {
  @Input() actualities : Actuality[];
  public url: string;
  @Input() isImage : boolean = false;

  constructor(private urlFormatter: FormatUrlPipe){}

  ngAfterViewInit(): void {
    Swiper.use([Navigation, Pagination]);
    this.initializeSwiper();
  }

  private initializeSwiper() {
    new Swiper('.swiper-container', {
      spaceBetween: 24,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        0: {
          slidesPerView: 1.5,
          slidesPerGroup: 1
        },
        768: {
          slidesPerView: 2.5,
          slidesPerGroup: 2
        },
        1320: { 
          slidesPerView: 3.5,
          slidesPerGroup: 3
        }
      
      }
     
    });
  }

  replaceUrl(actuality : Actuality): string {
    actuality.tags.forEach((tag, index, array) => {
      if (['France', 'Europe'].includes(tag.name) && array.length == 1) {
        this.url = "/actualites/Autres/" + actuality.id + '-' + this.urlFormatter.transform(actuality.url) + '.html'
      } else if (['France', 'Europe'].includes(tag.name) && array.length > 1) {
        array.splice(index, 1)
      } else {
        this.url = "/actualites/" + this.urlFormatter.transform(array[0].name) + "/" +actuality.id + '-' + this.urlFormatter.transform(actuality.url) + '.html'
      }

    });

    return this.url;
  }


}