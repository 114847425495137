<dep-navbar-public-space class="main-header"></dep-navbar-public-space>

<main class="main">
  <!-- Sticky header -->
  <dep-article-sticky-header [articleTitle]="video?.title" [articleCategories]="video?.tags" [buttonIcon]="'icon-share'"
    [buttonText]="'Partager'" (buttonAction)="copyUrl()" [buttonMessage]="'Lien copié !'"
    [showButtonMessage]="clipboardCopySuccess">
  </dep-article-sticky-header>

  <!-- Page header -->
  <dep-page-header-image class="page-header-wrapper"
    [headerImage]="video?.bannerImageUrl ? video?.bannerImageUrl : video?.imageUrl" [altImage]="video?.altImage">
    <!-- Article header content -->
    <dep-article-header [articleTitle]="video?.title"
      [articleDate]="video?.publishDate | date :'d MMMM yyy' | titlecase"
      [articleDateTime]="video?.publishDate | date :'d MMMM yyy'" [articleReadingTime]="video?.duration"
      [buttonIcon]="'icon-share'" [buttonText]="'Partager'" (buttonAction)="copyUrl()" [isPopover]="true"
      [showButtonMessage]="clipboardCopySuccess" content>
      <dep-breadcrumb [breadcrumbLabel]="'Fil d’ariane'" breadcrumb>
        <dep-breadcrumb-item [itemRouterLink]="'/'" *appBreadcrumbItem>Accueil</dep-breadcrumb-item>
        <dep-breadcrumb-item [itemRouterLink]="'/actualites-ressources/.'" *appBreadcrumbItem>
          Actualités et ressources
        </dep-breadcrumb-item>
        <dep-breadcrumb-item *appBreadcrumbItem>Vidéos</dep-breadcrumb-item>
      </dep-breadcrumb>
    </dep-article-header>

    <!-- Themes -->
    <dep-article-categories [articleCategories]="video?.tags" subheader></dep-article-categories>
  </dep-page-header-image>

  <!-- Article content -->
  <div class="article-wrapper">
    <ng-container *ngFor="let videoContent of video?.contents">

      <!-- Text -->
      <ng-container *ngIf="videoContent.textId">
        <p [innerHTML]="videoContent.paragraph | markdown"></p>
      </ng-container>

      <!-- Video -->
      <ng-container *ngIf="videoContent.videoId && videoContent.videoUrl">
        <video id="video" controls src="{{videoContent.videoUrl}}"></video>
        <div class="video-content">
          <p class="video-title">{{videoContent.videoTitle}}</p>
          <p class="video-source">{{videoContent.videoSource}}</p>
        </div>
      </ng-container>

      <!-- YOUTUBE video -->
      <!-- TODO : Allow youtube urls in inputs -->
      <ng-container *ngIf=" videoContent.videoId && !videoContent.videoUrl && videoContent.videoSource">
        <dep-article-youtube-video id="video" [videoUrl]="videoContent.videoSource"
          [videoTitle]="videoContent.videoTitle"></dep-article-youtube-video>
      </ng-container>
      <!-- Document -->
      <ng-container *ngIf="videoContent.documentId">
        <section>
          <dep-article-document [fileName]="videoContent.documentTitle"
            [fileButtonName]="videoContent.downloadButtonTitle" [fileURL]="videoContent.documentUrl">
          </dep-article-document>
        </section>
      </ng-container>

      <!-- Quote -->
      <ng-container *ngIf="videoContent.quoteId">
        <dep-article-quote [quote]="videoContent.quote" [quoteSource]="videoContent.authorName">
        </dep-article-quote>
      </ng-container>

      <!-- Related content -->
      <ng-container *ngIf="videoContent.linkId">
        <dep-article-related-content [relatedContent]="videoContent.linkTitle"
          [relatedLinkContent]="videoContent.linkUrl">
        </dep-article-related-content>
      </ng-container>


    </ng-container>

  </div>
</main>


<dep-scroll-to-top></dep-scroll-to-top>
<div *ngIf="isOpen">
  <dep-newsletter [newsletter]="newsletter" (closeNewsletter)='closeNewsletter($event)'></dep-newsletter>
</div>
<dep-footer-public-space></dep-footer-public-space>