import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalURLs } from 'src/app/GlobalURLs';
import { PageSectorielle } from 'src/app/models/observatory/sectors/PageSectorielle';
import { SectoralPage } from 'src/app/models/observatory/sectors/SectorialPage';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SectoralPagesService {

  private ObsUrl = '';


  constructor(private httpClient: HttpClient, globalUrls: GlobalURLs) {
    this.ObsUrl = environment.apiUrlAws + globalUrls.apiObsSectors;
  }

  public getSectoralPages(): Observable<SectoralPage[]> {
    return this.httpClient.get<PageSectorielle[]>(this.ObsUrl).pipe(
      map(
        results =>
          results.map(
            result => this.mapToSectoralPages(result)
          )
      )
    );
  }


  private mapToSectoralPages(data: PageSectorielle): SectoralPage {
    return {
      sector:data.secteur,
      suCount:data.nb_su,
      fundsTotal:data.mnt_levee,
      jobsCount:data.nb_emploi,
    }
  }
}
