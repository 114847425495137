<dep-navbar-public-space class="main-header"></dep-navbar-public-space>

<main class="main">
    <!-- Article header content -->
    <dep-article-header-v2 [articleTitle]="forum?.title"
        [articleDate]="forum?.publishDate | date :'d MMMM yyy' | titlecase" [articleDateTime]="'2020-12-15'"
        [articleReadingTime]="forum?.readingTime" [articleCategories]="forum?.tags"  [buttonIcon]="'icon-share'" [buttonText]="'Partager'"
        [isPopover]="true" 
        [title]="forum.title"
        content>
        <div class="breadcrumb" breadcrumb>
            <a [routerLink]="['/']">Accueil</a>
            <span>/</span>
            <a [routerLink]="['/actualites-ressources/.']">Actualités et ressources</a>
            <span>/</span>
            <a [routerLink]="['/tribunes/.']">Tribunes</a>
            <span>/</span>
            <a [routerLink]="['/etudes/.']" class="highlight">{{forum.title | slice:0:30 }}...</a>
        </div>
        <button class="btn-previous" (click)="goBack()" breadcrumb>
            <dep-icon [icon]="'icon-arrow_previous'" class="arrow"></dep-icon>
            <p>Retour</p>
        </button>
    </dep-article-header-v2>

    <!-- Article content -->
    <div class="article__wrapper">
        <ng-container *ngIf="forum?.imageUrl">
            <img src="{{forum?.imageUrl}}" alt="{{forum.altImage}}" class="actuality__image customised">
        </ng-container>

        <ng-container *ngFor=" let forumContent of forum?.contents">

            <!-- Text -->
            <ng-container *ngIf="forumContent.textId">
                <p [innerHTML]="forumContent.paragraph | markdown | async"></p>
            </ng-container>

            <!-- Quote -->
            <ng-container *ngIf="forumContent.quoteId">
                <dep-article-quote [quote]="forumContent.quote" [quoteSource]="forumContent.authorName">
                </dep-article-quote>
            </ng-container>

            <!-- Video -->
            <ng-container *ngIf="forumContent.videoId && forumContent.videoUrl">
                <dep-article-video [videoUrl]="forumContent.videoUrl" [videoType]="'video/mp4'"
                    [videoTitle]="forumContent.videoTitle" [videoSource]="forumContent.videoSource">
                </dep-article-video>
            </ng-container>

            <!-- YOUTUBE video -->
            <ng-container *ngIf="forumContent.videoId && forumContent.videoSource && !forumContent.videoUrl">
                <dep-article-youtube-video [videoUrl]="forumContent.videoSource"
                    [videoTitle]="forumContent.videoTitle"></dep-article-youtube-video>
            </ng-container>

            <!-- Image carousel -->
            <ng-container *ngIf="forumContent.imagesId">
                <dep-article-image-carousel [carouselTitle]="forumContent.slideShowTitle"
                    [carouselSource]="forumContent.imagesSource">
                    <ng-container *ngFor=" let carouselPictures of forumContent.imagesUrls">
                        <dep-article-image-carousel-item [imageSrc]="carouselPictures"
                            [imageAlt]="forumContent.altImages" *appCarouselSlide></dep-article-image-carousel-item>
                    </ng-container>

                </dep-article-image-carousel>
            </ng-container>

            <!-- Image -->
            <ng-container *ngIf="forumContent.imageId">
                <dep-article-image-carousel [carouselTitle]="forumContent.imageTitle"
                    [carouselSource]="forumContent.imageSource">
                    <dep-article-image-carousel-item [imageSrc]="forumContent.imageUrl"
                        [imageAlt]="forumContent.altImage" *appCarouselSlide></dep-article-image-carousel-item>
                </dep-article-image-carousel>
            </ng-container>

            <!-- Document -->
            <ng-container *ngIf="forumContent.documentId">
                <dep-article-document [fileName]="forumContent.documentTitle"
                    [fileButtonName]="forumContent.downloadButtonTitle"
                    [fileURL]="forumContent.documentUrl"></dep-article-document>
            </ng-container>

            <!-- Podcast -->
            <ng-container *ngIf="forumContent.podcastId">
                <dep-article-podcast></dep-article-podcast>
            </ng-container>
        </ng-container>
        <!-- Likes & vues -->
        <div class="actuality-data" [class.add-margin]="isOpenPopup && suggestions.length == 0">
            <div class="actuality-data__container">
                <div class="actuality-data__item">
                    <button (click)="likeForum()" [disabled]="hasLiked">
                        <img src="/assets/images/v-2024/icones/icon-like.svg" alt="icon like" class="customised">
                    </button>
                    <span>{{ forum?.like | formatLikes }}</span>
                </div>
            </div>
            <!-- btn partager -->
            <dep-btn-cta-article [buttonIcon]="'icon-share'" [buttonText]="'Partager'"  (buttonAction)="setPopupStatus($event)" [isPopover]="true" [title]="forum.title">
            </dep-btn-cta-article>
        </div>
    </div>
    <!-- Suggestions -->
    <section class="suggestions" *ngIf="suggestions?.length > 0">
        <div class="suggestions__container">
            <div class="section__header">
                <p class="title">Sur le même <strong class="highlight">thème</strong></p>
                <a class="ds-btn ds-btn--secondary" [routerLink]="'/tribunes/.'">Voir toutes les tribunes</a>
            </div>
            <div class="suggestions__cards">
                <ng-container *ngFor="let suggestion of suggestions| slice:0:3">
                    <dep-suggestion-card [btnLibelle]="" [suggestion]="suggestion"></dep-suggestion-card>
                </ng-container>
            </div>
            <div class="actus__cta">
                <a class="ds-btn ds-btn--secondary section__cta" [routerLink]="'/tribunes/.'">Voir toutes les
                    tribunes</a>
            </div>
        </div>
    </section>
</main>
<div *ngIf="isOpen">
    <dep-newsletter [newsletter]="newsletter" (closeNewsletter)='closeNewsletter($event)'></dep-newsletter>
</div>
<dep-footer-public-space></dep-footer-public-space>