<div class="page-header__container">
    <dep-single-circle-background></dep-single-circle-background>
    <div class="page-header__content" [ngStyle]="{ 'max-width': maxWidth }">
        <div class="page-header__breadcrumb">
            <ng-content select="[breadcrumb]"></ng-content>
        </div>
        <h1 class="title" [innerHtml]="title"></h1>
        <p class="description" [innerHtml]="description"></p>
        <ng-content select="[btn-cta]"></ng-content>
    </div>
</div>