<section class="hero">
    <div class="hero__wrapper">
        <dep-single-circle-background></dep-single-circle-background>
        <div class="breadcrumb__container">
            <ng-content select="[breadcrumb]"></ng-content>
        </div>
        <div class="hero__container">
            <div class="hero__content">
                <h1 class="title" [innerHtml]="title"></h1>
                <p class="text" [innerHtml]="text"></p>
                <div class="cta">
                    <ng-content select="[cta]"></ng-content>
                </div>
            </div>
            <div class="img__container">
                <button class="btn-previous" (click)="goBack()" breadcrumb>
                    <dep-icon [icon]="'icon-arrow_previous'" class="arrow"></dep-icon>
                    <p>Retour</p>
                </button>
                <div class="hero__image">
                    <img src="{{imageUrl}}" alt="image" class="customised" />
                </div>
            </div>
        </div>
    </div>
</section>