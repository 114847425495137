<article>
    <div class="card">
        <div class="card__content">
            <div class="card__header" [class.no-tag]="!suggestion.tag">
                <span *ngIf="suggestion.tag" class="tag">{{suggestion.tag.name}}</span>
                <p>{{suggestion.publishDate | date :'d MMMM yyy' | titlecase}}</p>
            </div>
            <div class="card__image">
                <img [src]="suggestion.imageUrl" alt="image" class="customised">
            </div>
            <p class="title">{{suggestion.title}}</p>
            <p class="suggestion.description" [innerHTML]="truncatedDescription"></p>
        </div>
        <div class="cta">
            <span class="time">
                <img src="/assets/images/v-2024/icones/icon-time.svg" alt="clock" class="customised" />
                <time>{{suggestion.readingTime}}</time>
            </span>
            <a *ngIf="internalLink" [href]="suggestion.url" target="_blank" rel="noopener noreferrer" class="ds-btn ds-btn--secondary">{{btnLibelle}}
                <dep-icon [icon]="'icon-arrow_forward'"></dep-icon>
            </a>
            <a *ngIf="!internalLink" [routerLink]="suggestion.url" class="ds-btn ds-btn--secondary">{{btnLibelle}}
                <dep-icon [icon]="'icon-arrow_forward'"></dep-icon>
            </a>
        </div>
    </div>
</article>