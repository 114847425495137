<div class="article-header__container">
    <dep-single-circle-background></dep-single-circle-background>
    <div class="article-header__breadcrumb">
        <ng-content select="[breadcrumb]"></ng-content>
    </div>
    <div class="article-header__wrapper">
        <div class="article-header__content">
            <div class="article-header__tags">
                <ng-container *ngIf="!replaceTag">
                    <span class="tag" *ngFor="let category of articleCategories">
                        <ng-container
                            *ngIf="category.name !== 'France' && category.name !== 'Europe'">{{category.name}}</ng-container>
                    </span>
                </ng-container>
                <ng-container>
                    <span class="tag" *ngIf="replaceTag">Autres</span>
                </ng-container>
            </div>
            <h1 class="title">{{articleTitle}}</h1>
            <div class="article-header__date">
                <span *ngIf="articleReadingTime">
                    <dep-icon [icon]="'icon-clock'"></dep-icon>
                    <time [attr.datetime]="'PT' + articleReadingTime + 'M'">{{articleReadingTime}}</time>
                </span>
                <p *ngIf="articleDateTime">Publié le <time [attr.datetime]="articleDateTime">{{articleDate}}</time>
                </p>
            </div>
        </div>
        <dep-btn-cta-article [buttonIcon]="buttonIcon" [buttonText]="buttonText"  [isPopover]="isPopover" [title]="this.title"(buttonAction)="scroll()">
            <ng-content select="[breadcrumb]"></ng-content>
        </dep-btn-cta-article>
    </div>