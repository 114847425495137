<article class="study">
    <div class="study__container">
        <div class="study__image">
            <img src="{{study.imageUrl}}" alt="{{study.altImage}}" loading="lazy" class="customised">
        </div>
        <div class="study__content">
            <div class="study__description">
                <div class="study__header">
                    <div class="study__tags">
                        <span class="study__category" *ngFor="let tag of study.tags">{{tag.name}}</span>
                    </div>
                    <p class="study__date">
                        {{study.publishDate | date :'d MMMM' | titlecase}}
                    </p>
                </div>
                <p class="title">{{study.title}}</p>
                <p class="intro">{{study.description}}</p>
            </div>
            <div class="study__footer">
                <p class="study__duration">{{study.readingTime}}</p>
                <a [routerLink]="['/etudes', (study.tags[0].name | formatUrl), study.id +'-'+ study.url + '.html']" class="ds-btn ds-btn--secondary">
                    Lire
                    <dep-icon class="icon" [icon]="'icon-arrow_forward'"></dep-icon>
                </a>
            </div>
        </div>
    </div>
</article>