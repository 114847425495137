<dep-navbar-public-space></dep-navbar-public-space>

<main class="main">
  <!-- Fil d'ariane et titre -->
  <dep-page-header pageTitle="Dérisquage technico-économique du projet">
    <dep-breadcrumb [breadcrumbLabel]="'Fil d’ariane'" breadcrumb>
      <dep-breadcrumb-item [itemRouterLink]="'/'" *appBreadcrumbItem>Accueil</dep-breadcrumb-item>
      <dep-breadcrumb-item [itemRouterLink]="'/creer-sa-startup/.'" *appBreadcrumbItem>Envie
        d'entreprendre</dep-breadcrumb-item>
      <dep-breadcrumb-item *appBreadcrumbItem>Dérisquage technico-économique du projet</dep-breadcrumb-item>
    </dep-breadcrumb>
  </dep-page-header>

  <!-- Les deux axes de développement du projet -->
  <dep-page-section sectionTitle="Les deux axes de développement du projet" sectionTheme="medium">
    <article class="article">
      <dep-text>
        <p>
          La maturation est l’étape pendant laquelle l’équipe projet peut bénéficier de fonds de la part de la structure
          de valorisation pour transformer le concept scientifique en un usage applicable sur un marché que l’on appelle
          le POC : Proof of Concept.
        </p>
        <p>
          Tout au long de cette étape la structure de valorisation peut vous accompagner sur deux axes :
        </p>
        <ol>
          <li>
            Suivi de la maturation technologique vers le POC
          </li>
          <li>
            Identification des marchés et du business model potentiels.
          </li>
        </ol>
        <p>
          L’accompagnement technico-économique de la structure de valorisation doit permettre de répondre à un certain
          nombre de questions stratégiques sur le projet pour en estimer le potentiel économique.
        </p>
        <p>
          A l’issue de cette phase d’accompagnement technico-économique, un termsheet sera établi entre la structure de
          valorisation et le/les porteur(s) du projet de startup. C’est un document clé qui préfigure les grandes lignes
          des futurs droits d’utilisation de la propriété intellectuelle par la startup à la manière d’un accord de
          principe.
        </p>
      </dep-text>
    </article>
  </dep-page-section>
  <!-- FIN de Les deux axes de développement du projet -->

  <!-- Contenu éditorial -->
  <dep-page-section>
    <article class="article">
      <div class="article__section grid-container">
        <div class="lg-grid-col-9 md-grid-col-8 sm-grid-col-4">
          <h2>Objectif : valider l’opportunité de création de startup</h2>

          <p>L’objectif des deux axes d’accompagnement est d’évaluer la pertinence du projet du point de vue
            concurrentiel et économique pour valider l’opportunité de création de startup.</p>

          <h3>Analyse technologique et évaluation des besoins de maturation</h3>

          <p>L’objectif de toute les parties prenantes doit être d’estimer au mieux la pertinence de la technologie par
            rapport à l’existant ainsi que le chemin qu’il reste à parcourir pour développer une première version du
            produit. Il s’agit pour l’organisme de valorisation d’évaluer les coûts, délais et ressources humaines
            nécessaire à la maturation.</p>

          <p>Le résultat attendu de la maturation est l’obtention d’un POC (Proof Of Concept) : c’est la transformation
            du concept scientifique en un usage applicable à l’industrie. Le POC n’est pas un produit commercialisable,
            il est la première étape qui permet de démontrer aux clients et financeurs potentiels la faisabilité
            technique du concept.</p>

          <h3>Identification des marchés et du business model potentiel</h3>

          <p>Une invention n’a de la valeur que si elle répond à un besoin d’un ou plusieurs clients qui sont prêts à
            payer pour son usage. L’identification des usages et des marchés clés et l’évaluation de leur taille
            confirmeront le potentiel de valorisation de la technologie.</p>

          <p>Une fois le(s) marché(s) potentiel(s) identifié(s), chercheur, entrepreneur et OTT doivent creuser
            l’analyse pour en comprendre l’environnement concurrentiel et les attentes des clients potentiels vis-à-vis
            du futur produit ou service de la startup. Cette analyse doit en outre permettre de définir la proposition
            de valeur, business model et la stratégie d’accès au marché.</p>
        </div>
      </div>

      <div class="article__section grid-container">
        <div class="lg-grid-col-9 md-grid-col-8 sm-grid-col-4">
          <h3>Un outil pour vous accompagner : le business model canvas</h3>

          <p>Le business model canvas est un document type qui permet une représentation systémique de votre entreprise
            pour en définir la proposition de valeur et le business model. Il est primordial pour les chercheurs et les
            entrepreneurs d’aborder le projet du point de vue marché et business model dès le début de la maturation.
          </p>

          <dep-video legend="L’explication du business model canvas en vidéo">
            <iframe title="Faire un Business Model : Comment se démarquer ?"
              src="https://www.youtube.com/embed/VgWdyYAiX08?feature=oembed" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="" name="fitvid1" width="100%" height="auto"></iframe>
          </dep-video>

          <a href="https://bpifrance-creation.fr/moment-de-vie/business-model-canvas-outil-incontournable-createur"
            target="_blank" rel="noopener noreferrer" class="ds-btn ds-btn--primary">En savoir plus</a>
        </div>

        <aside class="lg-grid-col-3 md-grid-col-4 sm-grid-col-3">
          <dep-resource resourceTitle="Le business model Canvas" resource="/assets/docs/business-model-canva.png"
            resourceAlignOffset="medium">
            Faites l’exercice de remplir le canvas pour votre propre projet grâce au modèle ci-dessous. Pour vous aider,
            n’hésitez pas à suivre le tutoriel de Bpifrance Création sur le business model canvas.
          </dep-resource>
        </aside>
      </div>

      <div class="article__section grid-container">
        <div class="lg-grid-col-9 md-grid-col-8 sm-grid-col-4">
          <h2>De l’analyse du projet à la signature du termsheet, accord de principe pour l’exploitation de la PI par la
            future startup</h2>

          <div class="resources grid-container">
            <dep-resource class="lg-grid-col-6 md-grid-col-4" resourceTitle="Grille d’analyse technico-économique"
              resource="/assets/docs/analyse-technico-eco.pdf" [noDecoration]="true">
              La maturation est le moment où chercheur et entrepreneur doivent changer de point de vue sur le projet
              pour passer d’une vision centrée sur la technologie à une vision centrée sur le marché et les besoins
              clients. Répondre aux questions présentées dans la grille ci-dessous peut aider à opérer ce pivot.
            </dep-resource>

            <dep-resource class="lg-grid-col-6 md-grid-col-4" resourceTitle="Termsheet"
              resource="/assets/docs/term-sheet.pdf" [noDecoration]="true">
              Le transfert de technologie est officialisé à la signature du contrat de licence entre l’OTT mandaté par
              les établissements de recherche pour valoriser la technologie que la startup exploitera. Ce contrat fait
              l’objet d’une négociation commerciale précédée d’un accord de principe retranscrit dans la termsheet. Le
              document ci-dessous permet de mieux comprendre les principaux éléments que vous pourriez rencontrer dans
              une termsheet.
            </dep-resource>
          </div>
        </div>
      </div>
    </article>
  </dep-page-section>
  <!-- FIN de Contenu éditorial -->

  <!-- Les parties prenantes clés -->
  <dep-page-section sectionTitle="Les parties prenantes clés" sectionTheme="medium">
    <div class="grid-container">
      <dep-icon-text class="lg-grid-col-3 md-grid-col-2 sm-grid-col-4" alignContent="center"
        icon="/assets/images/groupe.svg">
        Les porteurs de projet
      </dep-icon-text>
    </div>
  </dep-page-section>
  <!-- FIN de Les parties prenantes clés -->

  <!-- Les autres composantes -->
  <dep-page-section sectionTitle="Les autres composantes">
    <div class="grid-container">
      <dep-step class="lg-grid-col-3 md-grid-col-8 sm-grid-col-4"><a
          [routerLink]="['/creer-sa-startup/transfert-de-technologies.html']">Créer la startup et intégrer la
          PI</a></dep-step>

      <dep-step class="lg-grid-col-3 md-grid-col-8 sm-grid-col-4"><a
          [routerLink]="['/creer-sa-startup/financer-son-projet.html']">Financer son projet</a></dep-step>

      <dep-step class="lg-grid-col-3 md-grid-col-8 sm-grid-col-4"><a
          [routerLink]="['/creer-sa-startup/accompagnement-startup.html']">Se faire accompagner</a></dep-step>
    </div>
  </dep-page-section>
  <!-- FIN de Les autres composantes -->
</main>

<dep-footer-public-space></dep-footer-public-space>