<section class="navbar">
    <div class="navbar__container">
        <ng-container *ngFor="let link of links; let i = index">
            <a [routerLink]="[url]" [attr.data-fragment]="link.fragment" fragment="{{link.fragment}}"
                skipLocationChange="true" alt="link.alt">
                {{ link.label }}
            </a>
            <span class="line" [hidden]="i === links.length - 1"></span>
        </ng-container>
    </div>
</section>