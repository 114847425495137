import { Component, Input } from '@angular/core';
import { Actuality } from 'src/app/models/actualities/actuality.model';
import { FormatUrlPipe } from 'src/app/shared/pipes/format-url.pipe';

@Component({
  selector: 'dep-actuality-card',
  templateUrl: './actuality-card.component.html',
  styleUrls: ['./actuality-card.component.scss']
})
export class ActualityCardComponent {
  @Input() actuality : Actuality;
  public url: string;
  @Input() isImage: boolean = false;
  @Input() actualityIndex: number;
  @Input() isHighlighted: boolean = false;

  constructor(private urlFormatter: FormatUrlPipe) { }


  replaceUrl(actuality: Actuality): string {
    actuality.tags.forEach((tag, index, array) => {
      if (['France', 'Europe'].includes(tag.name) && array.length == 1) {
        this.url = "/actualites/Autres/" + actuality.id + '-' + this.urlFormatter.transform(actuality.url) + '.html'
      } else if (['France', 'Europe'].includes(tag.name) && array.length > 1) {
        array.splice(index, 1)
      } else {
        this.url = "/actualites/" + this.urlFormatter.transform(array[0].name) + "/" + actuality.id + '-' + this.urlFormatter.transform(actuality.url) + '.html'
      }

    });

    return this.url;
  }

}
