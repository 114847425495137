<div class="actuality__card">
    <div class="actuality__card-content">
        <span class="actuality__date">
            <span class="actuality__tag">{{actuality.tags[0].name}}</span>
            <p>{{actuality.publishDate | date: 'dd LLLL' }}</p>
        </span>
        <div class="actuality__image" *ngIf="isImage" [class.modify-height]="isHighlighted">
            <img  src="{{actuality.imageUrl}}" alt="{{actuality.altImage}}" class="customised">
        </div>
        <p class="actuality__title title">{{ actuality.title }}</p>
        <p class="actuality__description" [innerHTML]="(actuality.description | slice:0:200) + '...'"></p>
    </div>
    <div class="actuality__footer">
        <span class="time">
            <dep-icon [icon]="'icon-clock'"></dep-icon>
            <time>{{actuality.readingTime}}</time>
        </span>
        <a [routerLink]="replaceUrl(actuality)" class="ds-btn ds-btn--secondary">
            Lire
            <dep-icon [icon]="'icon-arrow_forward'"></dep-icon>
        </a>
    </div>
</div>