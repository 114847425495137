import { Injectable } from '@angular/core';
@Injectable()
export class GlobalURLs {
    private apiFaqs = '/faqs';
    private apiForums = '/forums';
    private apiMemberships = '/memberships';
    private apiContactForms = '/contact-forms';
    private apiFilters = '/filters';
    private apiActualities = '/actualities';
    private apiPodcasts = '/podcasts';
    private apiContests = '/contests';
    private apiBriefs = '/briefs';
    private apiStudies = '/studies';
    private apiCount = '/count';
    private apiCategories = '/categories';
    private apiEvents = '/events';
    private apiEventsCalendar = '/events/calendar';
    private apiTags = '/tags';
    private apiJobOffers = '/job-offers';
    private apiSectors = '/sectors';
    private apiTraining = '/trainings';
    private apiTrainingLevel = '/traininglevel';
    private apiGlossary = '/glossaries';
    private apiFaqDetail = '/faqdetails';
    private apiToolbox = '/toolboxes';
    private apiRegion = '/regional-branches';
    private apiStructure = '/structures';
    private apiFinance = '/finances';
    private apiVideos = '/videos';
    private apiNewsLetters = "/newsletters";
    private apiNotification = "/notification";
    private apiPartners = '/partners';
    private apiServices = '/services';
    private apiFavorites = '/favorites';
    private apiRessources = '/ressources';
    private apiPartnerOffers = '/partneroffers';
    private apiWitnesses = '/witnesses';
    private apiInfoMails = '/infomails';
    private apiMails = '/mails';
    private apiBds = '/bds';
    private apiKeyNumbers = '/keynumbers';
    private apiDirectAccesses = '/directaccesses';
    public apiFormats = '/formats';
    public apiRegionEspacePartenaire ="/region-espace-partenaire";
    public apiBftLab ="/bftlab";
    public apiWitnessKeyNumber ="/key-numbers";
    public apiPopup ="/popups";
    public apiPassCreas="/passcreas";
    private apiPartnerSpace = "/partnerspace"
    private apiInsideboard ="/insideboard";
    public apiSearchedKeyword="/searchedkeyword"
    public apiSuccessStory ="/success-stories"
    public apiPublishOffer="/publishoffer";
    public apiNugget="/nuggets";


    public get apiVideosRegion(): string {
        return this.apiVideos + "/region"
    }

    public get apiPodcastsRegion(): string {
        return this.apiPodcasts + "/region"
    }

    public get apiContestsRegion(): string {
        return this.apiContests + "/region"
    }

    public get apiSendContactEmail(): string {
        return this.apiMails+"/contactMail";
    }


    //--------Observatory---------
    private observatoryRoot = '/observatoire';

    public get apiAnnualNumbers(): string {
        return this.observatoryRoot + "/chiffresannuels";
    }

    public get apiRegionsNumbers(): string {
        return this.observatoryRoot + "/chiffresregions";
    }

    public get apiRegionsDetails(): string {
        return this.observatoryRoot + "/detailsregions"
    }

    public get apiDeeptechSu(): string {
        return this.observatoryRoot + "/detailssudeeptech"
    }

    public get apiDeeptechCount(): string {
        return this.observatoryRoot + "/nbdeeptech"
    }

    public get apiObsSectors(): string {
        return this.observatoryRoot + "/pagessectorielles"
    }

    public get apiObsDashboard(): string {
        return this.observatoryRoot + "/dashboard/front"
    }

    //-----------------------------
    getContests(): string {
        return this.apiContests;
    }

    getContestCount(): string {
        return this.apiContests + this.apiCount;
    }

    getForums(): string {
        return this.apiForums;
    }

    getForumsCount(): string {
        return this.apiForums + this.apiCount;
    }

    submitMembership(): string {
        return this.apiMemberships;
    }

    sendNotificationNew(): string {
        return this.apiNotification + '/startup/new';
    }

    sendNotificationExist(): string {
        return this.apiNotification + '/startup/exist';
    }

    getForumById(id: number): string {
        return this.apiForums + '/' + id;
    }

    submitContactForm(): string {
        return this.apiContactForms;
    }

    getJobOfferByIdUrl(id: number): string {
        return this.apiJobOffers + '/' + id;
    }

    getTagsUrl(): string {
        return this.apiTags;
    }

    getJobOffersFilterUrl(): string {
        return this.apiJobOffers + this.apiFilters;
    }

    getPodcastId(id: number): string {
        return this.apiPodcasts + '/' + id;
    }

    getPodcastsCount(): string {
        return this.apiPodcasts + this.apiCount;
    }

    getPodcasts(): string {
        return this.apiPodcasts;
    }


    getActualities(): string {
        return this.apiActualities;
    }

    getActualityById(id: number): string {
        return this.apiActualities + '/' + id;
    }

    getActualitiesCount(): string {
        return this.apiActualities + this.apiCount;
    }

    getBriefs(): string {
        return this.apiBriefs;
    }
    getBriefsSources(): string {
        return this.apiBriefs+"/sources";
    }

    getCountBriefs(): string {
        return this.apiBriefs + this.apiCount;
    }

    getStudies(): string {
        return this.apiStudies;
    }

    getStudyById(id: number): string {
        return this.apiStudies + '/' + id;
    }

    getStudiesCount(): string {
        return this.apiStudies + this.apiCount;
    }

    getCategories(): string {
        return this.apiCategories;
    }

    getEvents(): string {
        return this.apiEvents;
    }

    getEventsCount(): string {
        return this.apiEvents + this.apiCount;
    }

    getEventsCalendar(): string {
        return this.apiEventsCalendar;
    }

    getJobOffers(): string {
        return this.apiJobOffers;
    }
    getJobOfferById(id: number): string {
        return this.apiJobOffers + '/' + id;
    }

    getMenuSectors(): string {
        return this.apiSectors;
    }

    getSectorById(id: number): string {
        return this.apiSectors + '/' + id;
    }

    getTrainings(): string {
        return this.apiTraining;
    }
    getTrainingsCount(): string {
        return this.apiTraining + this.apiCount;
    }
    getTrainingsLevels(): string {
        return this.apiTrainingLevel;
    }

    getFaqs(): string {
        return this.apiFaqs;
    }
    getFaqDetailById(id: number): string {
        return this.apiFaqDetail + '/' + id;
    }
    getFaqDetails(): string {
        return this.apiFaqDetail;
    }

    getGlossaries(): string {
        return this.apiGlossary;
    }

    getGlossariesCount(): string {
        return this.apiGlossary + this.apiCount;
    }

    getToolboxes(): string {
        return this.apiToolbox;
    }

    getRegionById(id?: number): string {
        return this.apiRegion + '/' + id;
    }

    getRegions(): string {
        return this.apiRegion;
    }

    getStructures(): string {
        return this.apiStructure;
    }

    getFinances(): string {
        return this.apiFinance
    }

    getVideos(): string {
        return this.apiVideos;
    }
    getNewsLetters(): string {
        return this.apiNewsLetters;
    }

    getVideoId(id: number): string {
        return this.apiVideos + '/' + id;
    }

    getVideosCount(): string {
        return this.apiVideos + this.apiCount;
    }

    getPartners(): string {
        return this.apiPartners;
    }

    getPartnerById(id: number): string {
        return this.apiPartners + '/' + id;
    }

    getPartnersCount(): string {
        return this.apiPartners + this.apiCount;
    }

    getServices(): string {
        return this.apiServices;
    }

    getServiceById(id: number): string {
        return this.apiServices + '/' + id;
    }

    getServicesCount(): string {
        return this.apiServices + this.apiCount;
    }

    getFavorites(): string {
        return this.apiFavorites;
    }
    
    getRessources(): string {
        return this.apiRessources;
    }

    getRessourceById(id: number): string {
        return this.apiRessources + '/' + id;
    }

    getRessourceByFaqId(id: number): string {
        return this.apiRessources + '/faq-category/' + id;
    }

    getPartnerOffers(): string {
        return this.apiPartnerOffers;
    }

    updatePartnerOfferById(id: string): string {
        return this.apiPartnerOffers+ '/' + id;
    }

    getWitnesses(): string {
        return this.apiWitnesses;
    }

    getWitnessById(id: number): string {
        return this.apiWitnesses+ '/' + id;
    }

    getWitnessFilter(): string {
        return this.apiWitnesses+ '/filters' ;
    }

    postWitnessContactForm(): string {
        return this.apiWitnesses+ '/firstmail' ;
    }

    getLastWitnesses(): string {
        return this.apiWitnesses + "/last-witnesses";
    }

    getInfoMails(): string {
        return this.apiInfoMails;
    }
    
    updateInfoMail(): string {
        return this.apiInfoMails;
    }

    createInfoMail(): string {
        return this.apiInfoMails;
    }

    getBds(): string {
        return this.apiBds;
    }

    getBdById(id: number): string {
        return this.apiBds + '/' + id;
    }

    getKeyNumbers(): string {
        return this.apiKeyNumbers;
    }

    getMembersKeyNumbers(): string {
        return this.apiKeyNumbers + "/members";
    }

    getDirectAccesses(): string {
        return this.apiDirectAccesses;
    }

    getFavoriteDirectAccesses(): string {
        return this.apiDirectAccesses + "/links";
    }

    getInsideboardCommunity(): string {
        return this.apiPartnerSpace + this.apiInsideboard  + "/community";
    }

    getInsideboardDocuments(): string {
        return this.apiPartnerSpace + this.apiInsideboard  + "/documents";
    }

    getInsideboardNews(): string {
        return this.apiPartnerSpace + this.apiInsideboard  + "/lastnews";
    }

    getInsideboardNotifications(): string {
        return this.apiPartnerSpace + this.apiInsideboard  + "/notifications";
    }

    getBftProviders(): string {
        return this.apiBftLab + "/providers";
    }

    getBftFormats(): string {
        return this.apiBftLab + "/provider-format";
    }

    getBftTypes(): string {
        return this.apiBftLab + "/provider-type";
    }

}