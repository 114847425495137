<section class="hero">
    <dep-multiple-circles-background></dep-multiple-circles-background>
    <div class="breadcrumb__container">
        <ng-content select="[breadcrump]"></ng-content>
    </div>
    <div class="hero__wrapper">
        <div class="hero__container">
            <div class="hero__content">
                <h1 class="title" [innerHtml]="title"></h1>
                <p class="text" [innerHtml]="text"></p>
                <ng-content select="[tag]"></ng-content>
            </div>
            <div class="img__container">
                <button class="btn-previous" (click)="goBack()" breadcrumb>
                    <span>
                    <dep-icon [icon]="'icon-arrow_previous'" class="icon"></dep-icon>
                   </span>
                    <p>Retour</p>
                </button>
                <div class="hero__image">
                    <img src="{{imageUrl}}" alt="image" class="customised" />
                </div>
            </div>
        </div>
    </div>
</section>