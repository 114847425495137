import { Component, Input } from '@angular/core';
import { Study } from 'src/app/models/studies/study.model';

@Component({
  selector: 'dep-study-card',
  templateUrl: './study-card.component.html',
  styleUrls: ['./study-card.component.scss']
})
export class StudyCardComponent {
 @Input() study : Study;
 @Input() isImage : boolean;
}
