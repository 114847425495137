import { Component, Input, OnInit } from '@angular/core';
import { Contest } from 'src/app/models/contests/contest.model';

@Component({
  selector: 'dep-contest-card',
  templateUrl: './contest-card.component.html',
  styleUrls: ['./contest-card.component.scss']
})
export class ContestCardComponent implements OnInit {
  @Input() contest: Contest;
  displayStyle: number = 1;
  truncatedDescription: string;
  dayLeft: string;

  constructor() { }

  ngOnInit(): void {
    this.truncatedDescription = this.contest.description.length > 250 ? this.contest.description.substring(0, 250) + '...' : this.contest.description;
    this.calculateDaysLeft();
    if (this.contest != null && this.contest.endDate != null) {
      let start = new Date(this.contest.startDate);
      let end = new Date(this.contest.endDate);
      if (start.getFullYear() == end.getFullYear() && start.getMonth() == end.getMonth()) {
        this.displayStyle = 2;
      }
      else {
        this.displayStyle = 3;
      }
    }
  }

  getFormattedType(type: string): string {
    if (!type) return '';
    return type.charAt(0).toUpperCase() + type.slice(1).toLowerCase();
  }

  private calculateDaysLeft(): void {
    const today = new Date();
    const endDate = new Date(this.contest.endDate);
    const timeDiff = endDate.getTime() - today.getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if (daysDiff === 0) {
      this.dayLeft = 'Dernier jour';
    } else if (daysDiff === 1) {
      this.dayLeft = '1 jour restant';
    } else if (daysDiff > 1) {
      this.dayLeft = `${daysDiff} jours restants`;
    } else {
      this.dayLeft = null;
    }
  }

}
