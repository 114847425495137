import { Component, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges } from '@angular/core';
import * as L from 'leaflet';
import { RegionDetails } from 'src/app/models/observatory/regions-details/regions-details';
import { SuDetails } from 'src/app/models/observatory/su-details/SuDetails';
import { RegionsDetailsService } from 'src/app/services/observatory/regions-details.service';


@Component({
  selector: 'dep-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnChanges {

  @Input() startups: SuDetails[];
  @Input() hideLegend: boolean = false;
  @Input() isSector: boolean = false;
  @Input() startupsRegion: RegionDetails[];

  @Output() mapLoaded = new EventEmitter<void>();

  public enabledLegend : boolean = false ;
  public iconCollapse : string ;

  public map: L.Map;
  public sideBar = new L.Control({ position: 'topleft'});
  public singleMarkers:L.Layer = new L.LayerGroup();
  public groupMarkers:L.Layer = new L.LayerGroup();

  public selectedStartups: RegionDetails[] = [];
  public allStartups: RegionDetails[];
  public groupByCoordonate: Map<string, RegionDetails[]> = new Map();

  public groupingRadius = 0.0001;
  public groupStartupIcon = "/assets/images/icons-map/high-rise-building-full.png";
  
  constructor(
    private renderer: Renderer2,
    private regionDetailService: RegionsDetailsService
  ){ }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.map){
      this.getStartups();
    }
  }

  ngOnInit(): void {
    this.createMap();
    this.getStartups();
  }

  initMarkers(){
    this.map?.removeLayer(this.singleMarkers);
    this.map?.removeLayer(this.groupMarkers);

    this.singleMarkers = new L.LayerGroup();
    this.groupMarkers = new L.LayerGroup();

    this.selectedStartups = [];
    this.groupByCoordonate = new Map();
  }

  createMap(): void { 
    const france = { lat: 47.164900, lng: 2.348014};
    this.map = L.map('map', { zoom: 6, center: [france.lat, france.lng] });
    const mainLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    });
    mainLayer.addTo(this.map);
    const zoom = L.control.zoom({ position: 'bottomright' });
    this.map.zoomControl.remove();
    zoom.addTo(this.map);
 
    if (!this.hideLegend) {
      this.addLegend();
    } 

    this.map.whenReady(() => {
      this.mapLoaded.emit();
    });
  }

  getStartups(): void{
    if(this.isSector){
      this.regionDetailService.getRegionsDetails().subscribe(
        results => {
          this.initMarkers();
          this.allStartups = results;
          this.startups.forEach(startup => {
            let s = this.allStartups.find(start => start.siren === startup.siren);
            if(s){
              this.selectedStartups.push(this.suDetailsToRegionDetails(startup,s));
            }
            else{
              this.selectedStartups.push(startup);
            }
          });
          this.processStartups();
        } 
      )
    }
    else{
      this.initMarkers();
      this.selectedStartups = this.startupsRegion
      this.processStartups();
    }
  }

  suDetailsToRegionDetails(su:RegionDetails,region:RegionDetails): RegionDetails{
    region.companyName = su.companyName;
    region.sector = su.sector;
    region.adressLat = su.adressLat;
    region.adressLng = su.adressLng;
    region.postalCode = su.postalCode;
    return region;
  }

  processStartups(): void{
    this.selectedStartups.forEach(s => {
      this.changeMarkerColor();
    });
    this.groupByLatLng();
    this.addMarkerToMap();
  }

 
  getStartupDescription(s: RegionDetails, startupList? :RegionDetails[]): void{
    this.sideBar.onAdd = function () {
      let div = L.DomUtil.create('div', 'sidebar');
      let content: string = "";
      div.innerHTML = content;
      content += '<div class="sidebar_container" id="sideBar">' +
        '<div class="sidebar_content">';
      if (s.companyName.length > 0) {
        content += '<h4 class="sidebar_title">Nom de la startup</h4>' +
          '<p class="sidebar_text">' + s.companyName + '</p>';
      }
      if (s.sector.length > 0) {
        content += '<h4 class="sidebar_title">Secteur d\'activité</h4>' +
          '<p class="sidebar_text">' + s.sector + '</p>';
      }
      if (s.description) {
        content += '<h4 class="sidebar_title">Description</h4>' +
          '<p class="sidebar_text">' + s.description + '</p>';
      }
      if (s.address) {
        content += '<h4 class="sidebar_title">Adresse</h4>' +
          '<p class="sidebar_text">' + s.address + '</p>';
      }
      if (s.companyUrl) {
        content += '<h4 class="sidebar_title">Site web</h4>' +
          '<a class="sidebar_text" href="' + s.companyUrl + '">' + s.companyUrl + '</a>'
      }
      if (s.logo) {
        content += '<h4 class="sidebar_title">Logo</h4>' +
          '<img src="' + s.logo + '" alt="logo startup" class="startup_logo"/>';
      }
      if(startupList.length>0){
        content += '<div class="btn-back">'+
                      '<button id="back"><img src="/assets/images/icons-map/arrow-back.png" alt="arrow back" class="arrow_back"/>Retour</button>'+
                   '</div>'
      }
      content += '</div>' +
        '<img src="/assets/images/icons-map/close.png" alt="close button" id="closeBtn" class="btn-close"/></div>';

      div.innerHTML = content;
      L.DomEvent.disableScrollPropagation(div);
      L.DomEvent.disableClickPropagation(div);
      return div;

    }

    this.sideBar.addTo(this.map);

    if(startupList?.length>0){
      const buttonBack = L.DomUtil.get("back");
      L.DomEvent.addListener(buttonBack, 'click', (ee) => {
        this.getStartupList(startupList);
      });
    }

    this.closeSideBar();
  }

  closeSideBar(): void {
    document.getElementById('closeBtn').addEventListener("click", e => {
      this.renderer.addClass(document.getElementById('sideBar'), "hide");
    });
  }

  getStartupList(startups: RegionDetails[]): void{
    let groupStartupIcon = this.groupStartupIcon;
    this.sideBar.onAdd = function () {
      let div = L.DomUtil.create('div', 'sidebar');
      let content: string = "";
      div.innerHTML = content;
      content += '<div class="sidebar_container" id="sideBar">' ;
      content += '<div class="sidebar_content">';
      content += '<div class="sidebar_header">' ;
      content += '<img src='+groupStartupIcon+' alt="logo startup" class="sidebar_logo" />';
      content += '<p class="legend_title">Liste des startups</>';
      content += '</div>' ;
      content += '<div class="startup_list">';

      let index = 1;
      let starupsWithNames =[];
      let starupsWithoutNames =[];
      
      startups.forEach(s => {
        if(s.companyName){
          starupsWithNames.push(s)
        }
        else{
          starupsWithoutNames.push(s);
        }
      });
      starupsWithNames.concat(starupsWithoutNames).forEach(
        s=>{
          let title = s.companyName ? s.companyName : "Startup "+index++;
          content += '<div id='+ s.siren +' class="startup_item">';
          content += '<p class="startup_title">' + title + '</p>';
          content += '</div>';
        }
      )
      content += '</div>' ;
      content += '</div>' ;
      content += '<img src="/assets/images/icons-map/close.png" alt="close button" id="closeBtn" class="btn-close"/>';
      content += '</div>';

      div.innerHTML = content;


      L.DomEvent.disableClickPropagation(div);
      L.DomEvent.disableScrollPropagation(div);
      return div;
    }

    this.sideBar.addTo(this.map);

    startups.forEach(s => {
      const buttonSubmit = L.DomUtil.get(s.siren);
      L.DomEvent.addListener(buttonSubmit, 'click', (ee) => {
        this.getStartupDescription(s,startups);
      });
    });

    this.closeSideBar();

  }

  addLegend() {
    let groupStartupIcon = this.groupStartupIcon;
    let legend = new L.Control();
    legend.onAdd = function () {
      let div = L.DomUtil.create('div', 'legend');
      div.innerHTML += '<div class="legend_header" id="toggleLegend">'+
        '<span class="legend_title">Secteurs d’activité​</span>' +
        '<img src="/assets/images/icones/arrow_down.png" alt="arrow down" class="arrow_up"/>'+
        '</div>'+
        '<div class="legend_items" id="legendItems">'+
        '<div class="legend_item" id="legendItem"><img src="/assets/images/icons-map/marker9.png" alt="marker" class="legend_marker"/>' +
        '<p class="legend_text">Biotech</p>' +
        '</div>' +
        '<div class="legend_item" id="legendItem"><img src="/assets/images/icons-map/marker1.png" alt="marker" class="legend_marker"/>' +
        '<p class="legend_text">Cybersécurité​</p>' +
        '</div>' +
        '<div class="legend_item" id="legendItem"><img src="/assets/images/icons-map/marker10.png" alt="marker" class="legend_marker"/>' +
        '<p class="legend_text">Energie</p>' +
        '</div>' +
        '<div class="legend_item" id="legendItem"><img src="/assets/images/icons-map/marker11.png" alt="marker" class="legend_marker"/>' +
        '<p class="legend_text">Foodtech et Agritech​</p>' +
        '</div>' +
        '<div class="legend_item" id="legendItem"><img src="/assets/images/icons-map/marker6.png" alt="marker" class="legend_marker"/>' +
        '<p class="legend_text">IA et Software​</p>' +
        '</div>' +
        '<div class="legend_item" id="legendItem"><img src="/assets/images/icons-map/marker3.png" alt="marker" class="legend_marker"/>' +
        '<p class="legend_text">Industrie 4.0​​</p>' +
        '</div>' +
        '<div class="legend_item"><img src="/assets/images/icons-map/marker2.png" alt="marker" class="legend_marker"/>' +
        '<p class="legend_text">Medtech​</p>' +
        '</div>' +
        '<div class="legend_item"><img src="/assets/images/icons-map/marker4.png" alt="marker" class="legend_marker"/>' +
        '<p class="legend_text">Mobilité et ville durable​</p>' +
        '</div>' +
        '<div class="legend_item"><img src="/assets/images/icons-map/marker5.png" alt="marker" class="legend_marker"/>' +
        '<p class="legend_text">Quantique​</p>' +
        '</div>' +
        '<div class="legend_item"><img src="/assets/images/icons-map/marker7.png" alt="marker" class="legend_marker"/>' +
        '<p class="legend_text">Spacetech​</p>' +
        '</div>' +
        '<div class="legend_item"><img src="/assets/images/icons-map/marker8.png" alt="marker" class="legend_marker"/>' +
        '<p class="legend_text">Autres​​</p>' +
        '</div>' +
        '<div class="legend_item"><img src='+groupStartupIcon+' alt="marker" class="legend_marker"/>' +
        '<p class="legend_text">Groupement</p>' +
        '</div>';
      L.DomEvent.disableClickPropagation(div);
      L.DomEvent.disableScrollPropagation(div);
      return div;
    }
    legend.addTo(this.map);
    this.toggleLegend();

  }

  toggleLegend() {

    document.getElementById('toggleLegend').addEventListener("click", e => {
      this.enabledLegend=!this.enabledLegend;
      if(this.enabledLegend){
       this.renderer.addClass(document.getElementById('legendItems'), "show");
       this.renderer.removeClass(document.getElementById('legendItems'), "hide");
      }else {
       this.renderer.addClass(document.getElementById('legendItems'), "hide");
       this.renderer.removeClass(document.getElementById('legendItems'), "show");
      }
    });
  }

  changeMarkerColor(): void {
    this.selectedStartups.forEach(s => {
      if (s.sector === 'Agritech et Foodtech') {
        s.marker = '/assets/images/icons-map/marker11.png';
      }
      if (s.sector === 'Biotech, Medtech et e-santé') {
        s.marker = '/assets/images/icons-map/marker9.png';
      }
      if (s.sector === 'Energie et Greentech') {
        s.marker = '/assets/images/icons-map/marker4.png';
      }
      if (s.sector === 'Industrie 4.0 : Robotique et IoT') {
        s.marker = '/assets/images/icons-map/marker3.png';
      }
      if (s.sector === 'Numérique : IA et ordinateur quantique') {
        s.marker = '/assets/images/icons-map/marker1.png';
      }
    });
  }

  groupByLatLng(): void{
    this.selectedStartups.forEach(s => {
      let fitInGroup:Boolean = false;
      this.groupByCoordonate.forEach((group,key) => {
        let coord = key.split('_');
        if(this.isInCircle(s.adressLng,s.adressLat,Number(coord[0]),Number(coord[1]),this.groupingRadius)){
          fitInGroup = true;
          group.push(s);
        }
      });
      if(!fitInGroup){
        this.groupByCoordonate.set(s.adressLng+'_'+s.adressLat,[s]);
      }
    });
  }

  isInCircle(Xpoint:number,Ypoint:number,XCircle:number,YCircle:number,radius:number):boolean{
    return (Xpoint - XCircle)*(Xpoint - XCircle) + (Ypoint - YCircle)*(Ypoint - YCircle) < radius*radius
  }

  addMarkerToMap():void{
    let markersForBounds = [];
    this.groupByCoordonate.forEach(group => {
      if(group.length<2){
        const title = group[0].companyName as string;
        const lat = group[0].adressLat as number;
        const lng = group[0].adressLng as number;
        const marker = L.marker(
          new L.LatLng(lat, lng),
          {
            title: title,
            icon: new L.Icon({ iconUrl: group[0].marker, iconSize: [40, 40], className: 'icon-style'})
          }
        );
        marker.on("click", this.getStartupDescription.bind(this, group[0]));
        this.singleMarkers.addLayer(marker);
        if (!this.isSector){
          markersForBounds.push(marker);
        }
      }
      else{
        const lat = group[0].adressLat as number;
        const lng = group[0].adressLng as number;
        const marker = L.marker(
          new L.LatLng(lat, lng),
          {
            icon: L.divIcon({
              html: "<img src="+this.groupStartupIcon+"><span class='markerNumber'>"+group.length+"</span>",
              className: 'icon-style',
                iconSize: [40, 40] 
            })
          }
        );
        marker.on("click", this.getStartupList.bind(this, group));
        this.groupMarkers.addLayer(marker);
        if (!this.isSector){
          markersForBounds.push(marker);
        }
      }
    })

    if (!this.isSector && markersForBounds.length>0 && !this.startupsRegion[0].rd.includes("Ile-de-France")) {
      let latlngs = markersForBounds.map(marker => marker.getLatLng())
      let latlngBounds = L.latLngBounds(latlngs)
      this.map.fitBounds(latlngBounds);
    }

    if (!this.isSector && this.startupsRegion[0].rd.includes("Ile-de-France")) {
      this.map.setZoom(14);
      const paris = { lat: 48.84, lng: 2.348014};
      this.map.setView(paris, 10);
    }

    this.map?.addLayer(this.singleMarkers);
    this.map?.addLayer(this.groupMarkers);
  }

}