<div class="swiper-container">
  <div class="swiper-wrapper">
      <div class="swiper-slide" *ngFor="let startup of startups">
        <a class="startup__card" href="#" target="_blank" rel="noopener noreferrer">
            <span class="startup__tag">{{startup.sector}}</span>
            <img src="{{startup.logo}}" alt="logo startup" class="startup__logo customised" />
            <p class="startup__title title">{{ startup.companyName }}</p>
            <p *ngIf="startup?.description.length>100" class="startup__description" [innerHTML]="(startup.description | slice:0:100) + '...' "></p>
            <p *ngIf="!(startup?.description.length>100)" class="startup__description" [innerHTML]="startup.description "></p>
            <img src="/assets/images/icones/arrow_forward.svg" alt="icon arrow" class="customised">
        </a>
      </div>
    </div>
    <!-- Add Pagination -->
    <div class="swiper-pagination"></div>
  </div>