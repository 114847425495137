import { Component, Input, input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { JobOfferRequest } from 'src/app/models/job-offers/job-offer-request.model';
import { JobOfferType } from 'src/app/models/job-offers/job-offer-type.enum';
import { JobOffer } from 'src/app/models/job-offers/job-offer.model';
import { SuccessStory } from 'src/app/models/success-stories/SuccessStories.model';
import { SuccessStoryRequest } from 'src/app/models/success-stories/successStoriesRequest.model';
import { Suggestion, TypeArticle } from 'src/app/models/suggestions/Suggestions.model';
import { JobOfferService } from 'src/app/services/job-offers/job-offers.service';
import { SuccessStoriesService } from 'src/app/services/success-stories/success-stories.service';
import { Location } from '@angular/common';
import { CookieNewsletterService } from 'src/app/services/cookie-newsletter/cookie-newsletter.service';
import { NEWSLETTER_TYPES } from 'src/app/mocks/newsletter-type/mock-newsletter.model';
import { ActivatedRoute } from '@angular/router';
import { ServerErrorInterceptorService } from 'src/app/shared/services/servererrorinterceptor.service';
import { SuggestionService } from 'src/app/services/suggestions/suggestion.service';
import { Title } from '@angular/platform-browser'; 

@Component({
  selector: 'app-success-story-detail',
  templateUrl: './success-story-detail.component.html',
  styleUrl: './success-story-detail.component.scss'
})
export class SuccessStoryDetailComponent implements OnInit, OnDestroy {
  successStory: SuccessStory;
  @Input() public selectedSuccessStory: number;
  public isOpen = true;
  public successStoryRequest: SuccessStoryRequest = new SuccessStoryRequest();
  public suggestions: Suggestion[] = [];
  public offer: JobOffer ;
  public offers: JobOffer[] = [];
  private jobOfferRequest: JobOfferRequest = new JobOfferRequest();
  public isOpenPopup : boolean = false;
  public hasLiked: boolean = false;
  public newsletter = NEWSLETTER_TYPES[1];

  constructor(private renderer: Renderer2,
    private jobOfferService: JobOfferService,
    private location : Location,
    private cookieNewsletterService: CookieNewsletterService,
    private activatedRoute: ActivatedRoute,
    private successStoriesService: SuccessStoriesService,
    public suggestionService: SuggestionService,
    private serverErrorInterceptor: ServerErrorInterceptorService,
    private titleService: Title
  ) {
    this.renderer.addClass(document.body, 'v2021');
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.selectedSuccessStory = params.selectedSuccessStory.split('-')[0];
      this.suggestions = [];
      this.getSuccessStoryById(this.selectedSuccessStory);
    });
    this.isOpen = this.cookieNewsletterService.checkCookie(this.newsletter.cookieName);

  }


  /**
   * Get Success Story by id
   */

  private getSuccessStoryById(id: number): void {
    this.successStoriesService.getSuccessStoryById(id)
      .subscribe(
        (data) => {
          this.successStory = data;
          this.titleService.setTitle(this.successStory.title);
          let taleezTags = this.getTaleeTags();
          if(taleezTags.length > 0){
            this.jobOfferRequest.tags = taleezTags;
            this.getJoboffers();
          }
        },
        (error) => {
          this.serverErrorInterceptor.intercept(error)
        },
        () => {
          this.loadSuggestions();
        }
      );
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'v2021');
  }

  private initJobOfferRequest(): void {
    this.jobOfferRequest.pageNumber = 0;
    this.jobOfferRequest.pageSize = 4;
    this.jobOfferRequest.status = 'PUBLISHED';
    this.jobOfferRequest.jobOfferType = JobOfferType.TANDEM;
  }

  private getJoboffers() {
    this.initJobOfferRequest();
    this.jobOfferService.getJobOffers(this.jobOfferRequest).subscribe(
      results => {
        results.jobOffers.forEach(offer => { offer.internalUrl = '/tandem/' + offer.id + '.html'; });
        this.offers = this.offers.concat(results.jobOffers);
        this.offer = this.offers[0];
      },
      error => console.error(error)
    );
    this.jobOfferRequest.jobOfferType = JobOfferType.ADVISOR;
    this.jobOfferService.getJobOffers(this.jobOfferRequest).subscribe(
      results => {
        results.jobOffers.forEach(offer => { offer.internalUrl = '/devenir-advisor/' + offer.id + '.html'; });
        this.offers = this.offers.concat(results.jobOffers);
        this.offer = this.offers[0];
      },
      error => console.error(error)
    )
  }

  goBack(): void {
    this.location.back();
  }

  setPopupStatus(isOpen : boolean): void {
    this.isOpenPopup = isOpen;
  }

  likesuccessStory(){
    
  }

  private loadSuggestions(): void {
    this.suggestionService.setupSuggestionsSuccessStories(this.suggestions,this.successStory,TypeArticle.SUCCESS_STORY).subscribe(
      (suggestions) => {
        this.suggestions = suggestions;
      },
      (error) => {
        console.error('Error fetching suggestions', error);
      }
    );
  }

  getTaleeTags(): string[] {
    return this.successStory.tags.flatMap(tag => tag.tagTaleez || []).map(tagtaleez => tagtaleez.name);
  }
  
}