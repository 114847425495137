<dep-navbar-public-space></dep-navbar-public-space>

<main class="main">
  <!-- Fil d'ariane et titre -->
  <dep-page-header pageTitle="Demande d’ajout d’une structure partenaire"> </dep-page-header>

  <!-- Formulaire d'ajout d'une structure -->
  <dep-page-section>
    <div class="newsletter grid-container">
      <div class="lg-grid-col-7 md-grid-col-6 sm-grid-col-4">
        <form [formGroup]="partnerForm" (ngSubmit)="onSubmit()">
          <dep-form-section>
            <!-- Nom structure -->
            <div class="lg-grid-col-6 md-grid-col-8 sm-grid-col-4 ds-input-group ds-input-group--required"
              [class.ds-input-group--error]="nameCtrl.dirty && nameCtrl.errors?.required">
              <label class="ds-input-group__label">Nom de la structure ​</label>
              <input formControlName="nameCtrl" type="text" name="nameCtrl" class="ds-input-group__input"
                placeholder="Veuillez renseigner le nom" />
              <ng-container *ngIf="nameCtrl.dirty && nameCtrl.errors?.required">
                <i class="ds-input-group__icon fa fa-exclamation-triangle"></i>
                <div class="ds-input-group__feedback">
                  Veuillez renseigner le nom de votre structure
                </div>
              </ng-container>
            </div>

            <!-- Email -->
            <div class="lg-grid-col-6 md-grid-col-8 sm-grid-col-4 ds-input-group ds-input-group--required"
              [class.ds-input-group--error]="emailCtrl.dirty && emailCtrl.errors ">
              <label class="ds-input-group__label">Adresse email </label>
              <input formControlName="emailCtrl" type="email" name="emailCtrl" class="ds-input-group__input"
                placeholder="mon-email@exemple.fr" />

              <ng-container *ngIf="emailCtrl.dirty && emailCtrl.value.length === 0 && emailCtrl.errors">
                <i class="ds-input-group__icon fa fa-exclamation-triangle"></i>
                <div class="ds-input-group__feedback">
                  Veuillez renseigner votre adresse email
                </div>
              </ng-container>
              <ng-container *ngIf="emailCtrl.dirty && emailCtrl.value.length !== 0 && emailCtrl.errors">
                <i class="ds-input-group__icon fa fa-exclamation-triangle"></i>
                <div class="ds-input-group__feedback">
                  Veuillez renseigner une adresse email valide (mon-email@exemple.fr)
                </div>
              </ng-container>
            </div>

            <!-- Compte twitter -->
            <div class="lg-grid-col-6 md-grid-col-8 sm-grid-col-4 ds-input-group ds-input-group"
              [class.ds-input-group--error]="twitterCtrl.dirty && twitterCtrl.errors ">
              <label class="ds-input-group__label">Compte twitter</label>
              <input formControlName="twitterCtrl" type="text" name="twitterCtrl" class="ds-input-group__input"
                placeholder="@MonCompteTwitter​" />
              <ng-container *ngIf="twitterCtrl.dirty && twitterCtrl.errors">
                <i class="ds-input-group__icon fa fa-exclamation-triangle"></i>
                <div class="ds-input-group__feedback">
                  Veuillez renseigner votre compte twitter
                </div>
              </ng-container>
            </div>

            <!-- Site Web -->
            <div class="lg-grid-col-6 md-grid-col-8 sm-grid-col-4 ds-input-group ds-input-group--required"
              [class.ds-input-group--error]="websiteCtrl.dirty && websiteCtrl.errors ">
              <label class="ds-input-group__label">Site internet</label>
              <input formControlName="websiteCtrl" type="text" name="websiteCtrl" class="ds-input-group__input"
                placeholder="www.mastructure.fr" />

              <ng-container *ngIf=" websiteCtrl.dirty && websiteCtrl.value.length === 0 && websiteCtrl.errors">
                <i class="ds-input-group__icon fa fa-exclamation-triangle"></i>
                <div class="ds-input-group__feedback">
                  Veuillez renseigner votre site web
                </div>
              </ng-container>
              <ng-container *ngIf=" websiteCtrl.dirty && websiteCtrl.value.length !== 0 && websiteCtrl.errors">
                <i class="ds-input-group__icon fa fa-exclamation-triangle"></i>
                <div class="ds-input-group__feedback">
                  Veuillez renseigner un site web valide (https://exemple.com)
                </div>
              </ng-container>
            </div>

            <!-- Type de structure -->

            <div class="lg-grid-col-7 md-grid-col-8 sm-grid-col-4 ds-input-group ds-input-group--required"
              [class.ds-input-group--error]="typeStructureCtrl.dirty && checkedTypes.length==0">
              <label class="ds-input-group__label">Type de structure</label>
              <div class="ds-input-group__select ds-input-group__select--with-icon form__input sector_select">
                <div class="select-box" (click)="showCheckboxes()">
                  <select name="typeStructureCtrl" type="text"
                    class="ds-input-group__select ds-input-group__select--with-icon ">
                    <option value="" disabled selected>Acteurs académique, incubateur…​</option>
                  </select>
                  <div class="over-select"></div>
                </div>
                <div id="checkBoxes">
                  <ng-container *ngFor="let type of types; let i = index">
                    <label>
                      <input #checkers type="checkbox" value="{{type}}" name="checkbox"
                        (change)="limitCheckbox($event)" />
                      {{type}}
                    </label>
                  </ng-container>
                </div>
              </div>
              <ng-container *ngIf="typeStructureCtrl.dirty && checkedTypes.length==0">
                <i class="ds-input-group__icon fa fa-exclamation-triangle"></i>
                <div class="ds-input-group__feedback">
                  Veuillez choisir votre type de structure
                </div>
              </ng-container>
            </div>

            <!-- Région -->
            <div class="lg-grid-col-5 md-grid-col-8 sm-grid-col-4 ds-input-group ds-input-group--required"
              [class.ds-input-group--error]="regionSelectCtrl.dirty && regionSelectCtrl.errors?.required">
              <label class="ds-input-group__label">Région</label>
              <div class="ds-input-group__select ds-input-group__select--with-icon form__input sector_select">
                <select formControlName="regionSelectCtrl" name="region" type="text"
                  class="ds-input-group__select ds-input-group__select--with-icon ">
                  <option *ngFor="let r of regions; let i = index" [value]="r">
                    {{r}}
                  </option>
                </select>
              </div>
              <ng-container *ngIf="regionSelectCtrl.dirty && regionSelectCtrl.errors">
                <i class="ds-input-group__icon fa fa-exclamation-triangle"></i>
                <div class="ds-input-group__feedback">
                  Veuillez choisir votre région
                </div>
              </ng-container>
            </div>

            <!-- Description -->
            <div class="lg-grid-col-12 md-grid-col-8 sm-grid-col-4 ds-input-group ds-input-group--required"
              [class.ds-input-group--error]="descriptionCtrl.dirty && descriptionCtrl.errors?.required">
              <label class="ds-input-group__label">Description</label>
              <textarea formControlName="descriptionCtrl" maxlength="300" class="ds-input-group__textarea textarea-size"
                placeholder="Décrivez votre offre d’accompagnement Deeptech (300 caractères max)">
                   </textarea>
              <ng-container *ngIf="descriptionCtrl.dirty && descriptionCtrl.errors">
                <i class="ds-input-group__icon fa fa-exclamation-triangle"></i>
                <div class="ds-input-group__feedback">
                  Veuillez décrire votre offre d'accompagnement Deeptech
                </div>
              </ng-container>
            </div>

            <!-- Recapcha-->
            <div class="lg-grid-col-12 md-grid-col-8 sm-grid-col-4">
              <div id="captcha_element" class="g-recaptcha" data-callback="getResponseCaptcha"></div>
              <div *ngIf="captchaError" class="ds-input-group--error">Veuillez cocher le captcha</div>
            </div>

            <!-- CGU -->
            <div class="lg-grid-col-12 md-grid-col-8 sm-grid-col-4" style="text-align: justify;">
              <p>Les informations recueillies à partir de ce formulaire sont transmises au Service Bpifrance en charge
                de la gestion de
                la Plateforme Deeptech pour répondre à vos questions et gérer vos suggestions concernant la Plateforme
                Deeptech.</p><br />

              <p>Pour en savoir plus sur la gestion de vos données à caractère personnel et vos droit à cet égard,
                reportez-vous aux
                <a href="./assets/docs/CGU lesdeeptech.fr site vitrine v2.pdf" target="_blank" rel="noopener noreferrer"
                  fragment="finalites-des-traitements">conditions générales d'utilisation</a>.
              </p>
            </div>
            <dep-button type="submit" class="lg-grid-col-12 md-grid-col-8 sm-grid-col-4 position-send-button">Envoyer
            </dep-button>
          </dep-form-section>
          <!-- FIN du Formulaire d'ajout d'une structure -->

        </form>
      </div>

      <img src="/assets/images/mail.svg" class="lg-grid-col-5 md-grid-col-2 sm-grid-col-4 position-logo-boite-lettre"
        alt="mail">

    </div>
  </dep-page-section>

</main>
<!-- TOASTER : error -->
<dep-toaster [status]="'error'" [toasterTitle]="'Une erreur s’est produite'"
  [toasterText]="'Votre demande n’a pas pu être envoyée, veuillez réessayer ultérieurement.'" [show]="toasterFailure"
  (closeToaster)='closeToaster()'>
</dep-toaster>

<!-- TOASTER : success -->
<dep-toaster [status]="'success'" [toasterTitle]="'Votre demande a bien été envoyée'"
  [toasterText]="'Merci, votre demande va être traitée. Vous serez informé(e)s par mail si votre structure est retenue.'"
  [show]="toasterSuccess" (closeToaster)='closeToaster()'>
</dep-toaster>
<dep-footer-public-space></dep-footer-public-space>