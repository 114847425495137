import { ViewportScroller } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router, Scroll } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { LoginResponse, OidcSecurityService } from "angular-auth-oidc-client";
import { CookieService } from "ngx-cookie-service";
import { filter } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Popup } from "./models/popup.model";
import { PopupService } from "./services/popup/popup.service";
import { AuthService } from "./shared/services/authentification/auth.service";
import { FeatureFlippingService } from "./shared/services/feature-flippling/feature-flipping.service";
import { SEOService } from "./shared/services/seo/seo.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})

export class AppComponent implements OnInit {
  title = "angulartitle";

  public featureToggleData: any = {};
  public isPopup: boolean = true;
  public popups: Popup[] = [];
  public isEP: boolean = false;
  public popup: Popup;
  public partneSpaceRequestInProcess$: Observable<boolean> = this.authService.partneSpaceRequestInProcess$

  constructor(
    private translate: TranslateService,
    private router: Router,
    private viewportScroller: ViewportScroller,
    private featureflippingService: FeatureFlippingService,
    private seoService: SEOService,
    private cookieService: CookieService,
    private oidcSecurityService: OidcSecurityService,
    private authService: AuthService,
    private popupService: PopupService
  ) {
    this.translate.addLangs(["en", "fr"]);
    this.translate.setDefaultLang("fr");

    this.featureToggleData = this.featureflippingService.getFeatureFlagsByEnv(
      environment.environmentName
    );


  }

  ngOnInit() {

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.seoService.updateCanonicalUrl(
          this.router.url.split("?")[0]
        );

        this.isEP = this.router.url.startsWith('/mon-espace-partenaire/');

      });
    this.scrollRestoration();
    this.isPopup = !this.cookieService.check("popupPUB");
    this.oidcSecurityService.checkAuthMultiple().subscribe(
      (loginResponse: LoginResponse[]) => {
        const isAuthenticated = loginResponse.find(login => login.isAuthenticated);
        if (isAuthenticated) {
          let redirect = sessionStorage.getItem('redirect');
          if (redirect) {
            sessionStorage.removeItem('redirect');
            this.router.navigate([redirect]);
          }
        }
      },
      error => {
        console.log("something went wrong : ", error);
      }
    );

    const userAgent = navigator.userAgent.toLowerCase();

    if (!userAgent.includes('prerender')) {
      this.popupService.getAllpopups().subscribe(
        results => {
          results.forEach(popup => {
            popup.technicalTagViews.forEach(item => {
              if (item.code === 'EPub')
                this.popup = popup
            })
          })

        },
        error => console.error(error)
      )
    }

  }

  scrollRestoration() {
    // https://angular.io/api/router/ExtraOptions#scrollPositionRestoration
    this.router.events
      .pipe(filter((e): e is Scroll => e instanceof Scroll))
      .subscribe((e) => {
        if (e.position) {
          // backward navigation
          setTimeout(() => {
            e.position && this.viewportScroller.scrollToPosition(e.position);
          }, 1200);
        } else if (e.anchor) {
          // anchor navigation
          this.viewportScroller.scrollToAnchor(e.anchor);
        } else {
          // forward navigation
          this.viewportScroller.scrollToPosition([0, 0]);
        }
      });
  }

  closePopup(): void {
    this.cookieService.set("popupPUB", "true", { path: "/", sameSite: "Lax" });
    this.isPopup = !this.cookieService.check("popupPUB");
  }
  
  closeToaster(): void {
    this.authService.partneSpaceRequestInProcessBehavior.next(false);
  }

}
