<article class="podcast-card">
    <div class="tags--mobile">
        <div class="tags__container">
            <ng-container *ngFor="let tag of podcast?.tags">
                <span class="tag" *ngIf="tag?.name !== 'France' && tag?.name !== 'Europe'">{{tag?.name}}</span>
            </ng-container>
        </div>
        <span class="date">{{podcast.publishDate | date :'d MMMM yyy' | titlecase}}</span>
    </div>
    <div class="podcast-card__image" *ngIf="isImage">
        <img src="{{podcast.imageUrl}}" alt="{{podcast.altImage}}" loading="lazy" class="customised">
    </div>
    <div class="podcast-card__content">
        <div class="podcast-card__wrapper">
            <div class="tags">
                <div class="tags__container">
                    <ng-container *ngFor="let tag of podcast?.tags">
                        <span class="tag" *ngIf="tag?.name !== 'France' && tag?.name !== 'Europe'">{{tag?.name}}</span>
                    </ng-container>
                </div>
                <span class="date">{{podcast.publishDate | date :'d MMMM yyy' | titlecase}}</span>
            </div>
            <span class="title">{{podcast.title}}</span>
            <p class="author">{{podcast.authorNameAndRole}}</p>
            <p class="intro">{{podcast.description}}</p>
        </div>
        <div class="podcast-card__footer">
            <span class="duration">
                <img src="/assets/images/v-2024/icones/icon-time.svg" alt="clock" class="customised" />
                {{podcast.duration}}
            </span>
            <a [routerLink]="replaceUrl()" class="ds-btn ds-btn--secondary btn-cta">
                Écouter
                <dep-icon [icon]="'icon-hearing'" class="icon"></dep-icon>
            </a>
        </div>
    </div>
</article>