<div class="study__card">
    <div class="study__card-content">
        <span class="study__date">
            <div class="tags__container">
            <span class="tag study__tag" *ngFor="let tag of study.tags">{{tag.name}}</span>
            </div>
            <p class="date">{{study.publishDate | date :'d MMMM' | titlecase}}</p>
        </span>
        <div class="study__image" *ngIf="isImage">
            <img  src="{{study.imageUrl}}" alt="{{study.altImage}}" class="customised">
        </div>
        <p class="study__title title">{{ study.title }}</p>
    </div>
    <div class="study__footer">
        <span class="time" *ngIf="study.readingTime">
            <img src="/assets/images/v-2024/icones/icon-time.svg" alt="clock" class="customised" />
            <time>{{study.readingTime}}</time>
        </span>
        <a [routerLink]="['/etudes', (study?.tags[0]?.name | formatUrl), study.id +'-'+ study.url + '.html']" class="ds-btn ds-btn--secondary">Lire
            <dep-icon [icon]="'icon-arrow_forward'"></dep-icon>
        </a>
    </div>
</div>