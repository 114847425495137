<article>
    <a [routerLink]="testimony.link" class="testimony">
        <div class="testimony__content">
            <div class="testimony__header">
                <p>{{testimony.date}}</p>
                <p>{{testimony.time}}</p>
            </div>
            <span class="tag">{{testimony.tag}}</span>
            <div class="testimony__image">
                <img [src]="testimony.image" alt="image" class="customised">
            </div>
            <p class="title">{{testimony.title}}</p>
            <p class="description">{{testimony.description}}</p>
        </div>
        <div class="cta">
            <span>Lire
                <img src="/assets/images/icones/arrow_forward.svg" alt="icon arrow" class="customised">
            </span>
        </div>
    </a>
</article>