import { Component, Input } from '@angular/core';
import { Study } from 'src/app/models/studies/study.model';

@Component({
  selector: 'dep-studies-card',
  templateUrl: './studies-card.component.html',
  styleUrls: ['./studies-card.component.scss']
})
export class StudiesCardComponent {
  @Input() studyIndex: number;
  @Input() study: Study;
  
  public documentUrl : string;

  constructor() { }

  ngOnInit(): void {
    this.study.contents.find(content=>{
      if(content.documentUrl){
        this.documentUrl = content.documentUrl;
      }
      
    });
  }
}
