import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'dep-hero-section',
  templateUrl: './hero-section.component.html',
  styleUrls: ['./hero-section.component.scss']
})
export class HeroSectionComponent {
  @Input() title: string;
  @Input() text: string;
  @Input() imageUrl: string;
  constructor(private location: Location) {
  }

  goBack() {
    this.location.back();
  }

}
