export const environment = {
  production: true,
  environmentName: 'eph',
  siteKeyCaptcha: '6LdFJeQZAAAAAPji7MtB9X4Uy-07n5pdVH4EbI33',
  apiUrlAws: 'https://dep.dev.api.cloud.bpifrance.fr/deeptech-eph',
  apiUrl: 'https://apiv2-nprd.cloud.bpifrance.fr/dev/dep/deeptech-eph',
  defaultReceiverEmail: 'test.email.dep@gmail.com',
  issuer: 'https://mst.mcb-nprd.cloud.bpifrance.fr:443/am/oauth2/mcb',
  clientId : 'deeptech-spa',
  tokenEndpointURL: 'https://mst.mcb-nprd.cloud.bpifrance.fr:443/am/oauth2/mcb/access_token',
  userinfoEndpointURL: 'https://mst.mcb-nprd.cloud.bpifrance.fr:443/am/oauth2/mcb/userinfo',
  authorizeEndpointURL: 'https://mst.mcb-nprd.cloud.bpifrance.fr:443/am/oauth2/mcb/authorize',
  logoutEndpointURL: 'https://mst.mcb-nprd.cloud.bpifrance.fr/am/oauth2/mcb/connect/endSession',
  privateCguURL: 'https://media.dev.dep-nprd.cloud.bpifrance.fr/cgu/cgu.pdf',
  myCommunityURL: 'https://bpifrance.pilot.insideboard.com/apis/auth/v1/login/sso/callback?ssoCode=VBOJXCKI',
  issuer2: 'https://authmfappd.web.bpifrance.fr',
  clientId2: 'deeptech-spa',
  redirectUrl: 'https://eph.dep-nprd.cloud.bpifrance.fr',
  backOfficeUrl:'https://backoffice.dev.dep-nprd.cloud.bpifrance.fr',

  talent: "https://bpifrance.pilot.insideboard.com/project/VCMSGFLY/community/643ff470591e72d62351f7cd/news",
  campus: "https://bpifrance.pilot.insideboard.com/project/VCMSGFLY/community/651d754ea5e39da88803702a/news",
  ssoCodeUrl: "https://bpifrance.pilot.insideboard.com/apis/auth/v1/login/sso?ssoCode=VBOJXCKI&redirectUrl=",
  urlProfileMCB: "https://mst.mcb-nprd.cloud.bpifrance.fr:443/am/oauth2/realms/root/realms/mcb/authorize?client_id=deeptech-spa&redirect_uri=https://moncompte.bpifrance.fr/profile&scope=openid%20profile%20email&response_type=code"
};
