import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'dep-navbar-public-space',
  templateUrl: './navbar-public-space.component.html',
  styleUrls: ['./navbar-public-space.component.scss']
})
export class NavbarPublicSpaceComponent {
  menuIsOpen: boolean = false;
  selectedItem: string = '';
  menuStates: { [key: string]: boolean } = {};
  activeParentItem: string;
  showDropdown: boolean = false;
  showTabletSearchBar: boolean = false;
  showSideBar: boolean = false;
  isTablet: boolean = false;
  innerWidth: number;

  constructor(router: Router) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.closeMenu();
      // active route
      const currentUrl = router.url;
      if (currentUrl.includes('discover-deeptech/') || currentUrl.includes('join-deeptech/') || currentUrl.includes('project-leader/')) {
        this.activeParentItem = 'Profil';
      }
      if (currentUrl.includes('tandem/') || currentUrl.includes('devenir-advisor/') || currentUrl.includes('mercato/') || currentUrl.includes('euroquity/') || currentUrl.includes('me-former/') || currentUrl.includes('communaute-startups-deeptech-france/') || currentUrl.includes('services/')) {
        this.activeParentItem = 'Services';
      } else if (currentUrl.includes('evenements/') || currentUrl.includes('concours-appels-a-projets/') || currentUrl.includes('actualites/') || currentUrl.includes('breves/') || currentUrl.includes('tribunes/') || currentUrl.includes('decouvrir-la-deeptech/') || currentUrl.includes('atlas-de-la-deeptech/') || currentUrl.includes('etudes/') || currentUrl.includes('videos/') || currentUrl.includes('podcasts/')) {
        this.activeParentItem = 'actualities';
      } else {
        this.activeParentItem = '';
      }
    });
  }

  ngOnInit() {
    this.detectWindowSize();
    window.addEventListener('resize', this.detectWindowSize.bind(this));
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.detectWindowSize.bind(this));
  }

  detectWindowSize(): void {
    this.innerWidth = window.innerWidth;
    this.isTablet = this.innerWidth <= 1024;
  };

  toggleMenu(item: string): void {
    this.menuStates[item] = !this.menuStates[item];

    Object.keys(this.menuStates).forEach(key => {
      if (key !== item) {
        this.menuStates[key] = false;
      }
    })
  }

  closeMenu(): void {
    Object.keys(this.menuStates).forEach(key => {
      this.menuStates[key] = false;
    });
  }

  getSelectedItem(item: string): void {
    this.selectedItem = item;
  }

  closeDropdown(): void {
    this.showDropdown = false;
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  toggleSearchBar() {
    this.showTabletSearchBar = !this.showTabletSearchBar;
  }

  toggleSideBar() {
    this.showSideBar = !this.showSideBar;
  }

}
