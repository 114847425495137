import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { SectorsComponent } from 'src/app/components/view/v-2024/sectors/sectors.component';
import { ActualityDetailComponent } from './components/view/v-2024/actualities/actuality-detail/actuality-detail.component';
import { ForumDetailComponent } from './components/view/v-2024/forums/forum-detail/forum-detail.component';
import { PartnerContactComponent } from './components/view/partner-contact/partner-contact.component';
import { PodcastDetailComponent } from './components/view/v-2024/podcasts/podcast-detail/podcast-detail.component';
import { StudyDetailComponent } from './components/view/v-2024/studies/study-detail/study-detail.component';
import { VideoDetailComponent } from './components/view/v-2024/videos/video-detail/video-detail.component';
import { SuccessStoryDetailComponent } from './components/view/v-2024/success-stories/success-story-detail/success-story-detail.component';

const routes: Routes = [
  // Pages Principales *********/
  {
    path: '', loadChildren: () => import(
      './components/view/v-2024/homepage/homepage.module'
    ).then(
      m => m.HomepageModule
    )
  },
  {
    path: 'decouvrir-la-deeptech/.',
    loadChildren: () =>
      import('./components/view/about-deeptech/about-deeptech.module').then(
        (m) => m.AboutDeeptechModule
      ),
  },

  {
    path: 'contact.html',
    loadChildren: () =>
      import('./components/view/v-2024/contact-form/contact-form.module').then(
        m => m.ContactFormModule
      ),
  },

  {
    path: 'partner-contact.html',
    loadChildren: () =>
      import('./components/view/partner-contact/partner-contact.module').then(
        m => m.PartnerContactModule
      ),
  },

  {
    path: 'qui-sommes-nous.html',
    loadChildren: () =>
      import('./components/view/about-us/about-us.module').then(
        m => m.AboutUsModule
      ),
  },

  {
    path: 'international-page/.',
    loadChildren: () =>
      import('./components/view/international-page/international-page.module').then(
        (m) => m.InternationalPageModule
      ),
  },
  {
    path: 'page-401/.',
    loadChildren: () =>
      import('./components/view/page401/page401.module').then(
        (m) => m.Page401Module
      ),
  },
  {
    path: 'page-404/.',
    loadChildren: () =>
      import('./components/view/page404/page404.module').then(
        (m) => m.Page404Module
      ),
  },
  {
    path: 'actualites-ressources/.',
    loadChildren: () =>
      import(
        './components/view/actualities-resources/actualities-resources.module'
      ).then((m) => m.ActualitiesResoucesModule),
  },

  /**TANDEM MODULES */
  {
    path: 'tandem/.',
    loadChildren: () =>
      import(
        './components/view/job-offers/tandem/home/job-offer-home.module').then(
          (m) => m.JobOfferHomeModule
        ),
  },
  {
    path: 'tandem/tous-les-projets.html',
    loadChildren: () =>
      import(
        './components/view/job-offers/tandem/offers/job-offers.module').then(
          (m) => m.JobOffersModule
        ),
  },
  {
    path: 'tandem/:selectedOffre',
    loadChildren: () =>
      import(
        './components/view/job-offers/tandem/offers-details/job-offer-detail.module').then(
          (m) => m.JobOfferDetailModule
        ),
  },
  {
    path: 'me-former/.',
    loadChildren: () =>
      import(
        './components/view/v-2024/training-home/training-home.module').then(
          (m) => m.TrainingHomeModule
        ),
  },
  {
    path: 'page-500/.',
    loadChildren: () =>
      import('./components/view/page500/page500.module').then(
        (m) => m.Page500Module
      ),
  },

  /*-------------------------------------------------------------------------------------------------- */
  /** Parent : communaute-startups-deeptech-france */
  {
    path: 'communaute-startups-deeptech-france/.',
    loadChildren: () => import('./components/view/v-2024/community/community.module').then(m => m.CommunityModule),
  },
  /*-------------------------------------------------------------------------------------------------- */


  // *Children : actualites-ressources-v2021 */

  {
    path: 'actualites/:selectedActualityTag/:selectedActuality',
    component: ActualityDetailComponent,
  },
  
  { path: 'tribunes/.', loadChildren: () => import('./components/view/v-2024/forums/forums.module').then(m => m.ForumsModule) },
  { path: 'tribunes/:selectedTag/:selectedForum', component: ForumDetailComponent },

  { path: 'etudes/.', loadChildren: () => import('./components/view/v-2024/studies/studies.module').then(m => m.StudiesModule) },
  { path: 'etudes/:selectedTag/:selectedStudy', component: StudyDetailComponent },

  {
    path: 'podcasts/.', loadChildren: () =>
      import('./components/view/v-2024/podcasts/podcasts.module').then(m => m.PodcastsModule)
  },
  { path: 'podcasts/:selectedTag/:selectedPodcast', component: PodcastDetailComponent },

  { path: 'videos/:selectedTag/:selectedVideo', component: VideoDetailComponent },

  { path: 'success-stories/:selectedTag/:selectedSuccessStory', component: SuccessStoryDetailComponent },


  /*------------------------------------------------------------------------------------*/

  /**** Parent : ADVISOR  ************* */
  {
    path: 'devenir-advisor/.',
    loadChildren: () => import(
      './components/view/job-offers/advisor/advisor.module').then(
        (m) => m.AdvisorModule
      )
  },
  {
    path: 'devenir-advisor/:selectedOffer', loadChildren: () => import('./components/view/job-offers/advisor/offers-details/job-offer-detail-advisor.module').then(
      m => m.JobOfferDetailAdvisorModule
    )
  },
  /*------------------------------------------------------------------------------------*/
  /*------------------------------------------------------------------------------------*/

  {
    path: 'secteurs/:selectedSector',
    loadChildren: () =>
      import('./components/view/v-2024/sectors/sectors.module').then(
        (m) => m.SectorsModule
      ),
  },

  /**** Partner-space **** */
  {
    path: 'mon-espace-partenaire/les-services.html?origin=intranet',
    redirectTo:'mon-espace-partenaire/les-services.html'
  },
  {
    path: 'mon-espace-partenaire/.?origin=intranet',
    redirectTo:'mon-espace-partenaire/.'
  },
  {
    path: 'mon-espace-partenaire',
    loadChildren: () =>
      import('./components/view/partner-space/partner-space.module').then(
        (m) => m.PartnerSpaceModule
      ),
  },


  /**** Sectors new Paths **** */

  {
    path: 'biotech-medtech/.', component: SectorsComponent,
  },
  {
    path: 'energie-greentech/.', component: SectorsComponent,
  },
  {
    path: 'foodtech-agritech/.', component: SectorsComponent,
  },
  {
    path: 'industrie-robotique-iot/.', component: SectorsComponent,
  },
  {
    path: 'numerique-IA-quantique/.', component: SectorsComponent,
  },



  {
    path: 'partnerform/.', component: PartnerContactComponent
  },

  /**Route Glossary */
  {
    path: 'glossaire/.',
    loadChildren: () =>
      import('./components/view/v-2024/glossary/glossary.module').then(
        (m) => m.GlossaryModule
      )
  },
  {
    path: 'regions/:regionId',
    loadChildren: () =>
      import('./components/view/regions/region.module').then(
        (m) => m.RegionModule
      ),
  },
  { path: 'publish-offer.html', loadChildren: () => import('./components/view/publish-offer/publish-offer.module').then(m => m.PublishOfferModule) },

  /*------------------------------------------------------------------------------------*/
  //formulaire de demande d'accès 
  {
    path: 'demande-acces-form/.',
    loadChildren: () => import('./components/view/access-request-form/access-request-form.module').then
      (m => m.AccessRequestFormModule)
  },

  // Lien temporaire pour tester la partie INT
  { path: 'search-results.html', loadChildren: () => import('./components/view/search-results/search-results.module').then(m => m.SearchResultsModule) },
   
  // refonte 2024 
  { path: 'services/.',
    loadChildren: () => import('./components/view/v-2024/services/services.module').then
    (m => m.ServicesModule)
  },
  { path: 'discover-deeptech/.',
    loadChildren: () => import('./components/view/v-2024/discover-deeptech/discover-deeptech.module').then
    (m => m.DiscoverDeeptechModule) 
  },
  { path: 'project-leader/.',
   loadChildren: () => import('./components/view/v-2024/project-leader/project-leader.module').then
   (m => m.ProjectLeaderModule) 
  },
  { 
    path: 'join-deeptech/.',
    loadChildren: () => import('./components/view/v-2024/join-deeptech/join-deeptech.module').then
    (m => m.JoinDeeptechModule) 
  },
  { path: 'landing-page/.', 
    loadChildren: () => import('./components/view/v-2024/ep-landing-page/ep-landing-page.module').then
    (m => m.EpLandingPageModule) 
  },
  { path: 'services/tandem/.',
  loadChildren: () => import('./components/view/v-2024/tandem/tandem.module').then
  (m => m.TandemModule)
  },
  { path: 'services/tango/.',
    loadChildren: () => import('./components/view/v-2024/tango/tango.module').then
    (m => m.TangoModule) 
  },
  {
    path: 'tango/tous-les-projets.html', loadChildren: () => import('./components/view/job-offers/advisor/offers/job-offers-advisor.module').then
    (m => m.JobOffersAdvisorModule),
  },
  { path: 'envie-d-entreprendre/.', loadChildren: () => import('./components/view/v-2024/faq/faq.module').then
    (m => m.FaqModule)
  },
  { path: 'atlas-de-la-deeptech/.', loadChildren: () => import('./components/view/v-2024/atlas-deeptech/atlas-deeptech.module').then
    (m => m.AtlasDeeptechModule)
  },
  { path: 'faq/:selectedFaqCategory/.', loadChildren: () => import('./components/view/v-2024/faq-detail/faq-detail.module').then(m => m.FaqDetailModule) 
  },
  { path: 'success-stories/.', loadChildren: () => import('./components/view/v-2024/success-stories/success-stories.module').then(m => m.SuccessStoriesModule)
  },
  { path: 'euroquity/.', loadChildren: () => import('./components/view/v-2024/euroquity/euroquity.module').then(m => m.EuroquityModule) 
  },
  { path: 'breves/.', loadChildren: () => import('./components/view/v-2024/briefs/briefs.module').then(m => m.BriefsModule)
  },
  { path: 'evenements/.', loadChildren: () => import('./components/view/v-2024/events/events.module').then(m => m.EventsModule) 
  },
  { path: 'videos/.', loadChildren: () => import('./components/view/v-2024/videos/videos.module').then(m => m.VideosModule) 
  },
  { path: 'concours-appels-a-projets/.', loadChildren: () => import('./components/view/v-2024/contests/contests.module').then(m => m.ContestsModule) 
  },
  { path: 'proposer-evenement-concours-appels-a-projets/.', loadChildren: () => import('./components/view/v-2024/events-contests-form/events-contests-form.module').then(m => m.EventsContestsFormModule)
  },
  { path: 'actualites/.', loadChildren: () => import('./components/view/v-2024/actualities/actualities.module').then(m => m.ActualitiesModule)
  },
  { path: 'toutes-les-formations/.', loadChildren: () => import('./components/view/v-2024/trainings/trainings.module').then(m => m.TrainingsModule) 
  },
  { path: 'aides-pour-mon-projet/.', loadChildren: () => import('./components/view/v-2024/project-help/project-help.module').then(m => m.ProjectHelpModule)
  },

  /*------------------------------------------------------------------------------------*/
  { path: 'delete-account/.', loadChildren: () => import('./components/view/account-deleted/account-deleted.module').then(m => m.AccountDeletedModule) },
  { path: 'actualites/.', loadChildren: () => import('./components/view/v-2024/actualities/actualities.module').then(m => m.ActualitiesModule) },
  { path: 'components/view/v-2024/studies', loadChildren: () => import('./components/view/v-2024/studies/studies.module').then(m => m.StudiesModule) },
  { path: 'components/view/v-2024/forums', loadChildren: () => import('./components/view/v-2024/forums/forums.module').then(m => m.ForumsModule) },
  { path: '**', redirectTo: '/page-404/.' },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledNonBlocking'
      // relativeLinkResolution: 'legacy'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
