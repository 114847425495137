<header class="main-header" *ngIf="!isMobile">
    <!-- Logo, link to homepage -->
    <section class="main-header__logo">
        <a routerLink="/" [routerLinkActive]="'is-active'" [routerLinkActiveOptions]="{exact:true}"
            (click)="getSelectedItem(''); closeMenu()" aria-label="Accueil" ariaCurrentPage>
            <img src="/assets/images/deep-tech/Logo-Lesdeeptech-bleu.png" alt="Les deeptech">
        </a>
    </section>
    <span class="separator customised"></span>
    <!-- main navigation -->
    <nav id="main-nav" class="main-nav" aria-label="Menu principal" (clickOutside)="getSelectedItem('');closeMenu()">
        <ul class="main-nav__level-1">
            <li class="main-nav__level-1-item">
                <a class="main-nav__level-1-link" [routerLink]="['/mon-espace-partenaire/.']"
                    (click)="getSelectedItem('')">
                    <span class="customised partner-space" style="font-weight: 700;padding-left: 0;">
                        <dep-icon [icon]="'icon-home'" class="icon-home"></dep-icon>
                        Espace partenaires
                    </span>
                </a>
            </li>
            <!-- sensibiliser -->
            <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'">
                <button class="main-nav__button main-nav__level-1-link" aria-controls="sensibiliser"
                    (click)="getSelectedItem('sensibiliser');toggleMenu('sensibiliser');">
                    <span class="customised" [class.is-active-parent]="activeParentItem === 'sensibiliser'">Sensibiliser
                        <dep-icon [icon]="'icon-keyboard_arrow_down'" class="desktop-icon arrow-icon"
                            [class.rotate]="menuStates['sensibiliser'] && selectedItem == 'sensibiliser'"></dep-icon>
                    </span>
                </button>
            </li>
            <div class="main-nav__sub-menu menu-1 customised" *ngIf="menuStates['sensibiliser']"
                [class.visible]="selectedItem == 'sensibiliser'">
                <ul class="main-nav__level-2">
                    <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'">
                        <a class="main-nav__level-1-link level-2"
                            [routerLink]="['/mon-espace-partenaire/vivier-de-temoins/.']" (click)="getSelectedItem('')"
                            ariaCurrentPage>
                            <span class="customised">Trouver un intervenant pour un évènement</span>
                        </a>
                    </li>
                    <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'">
                        <a class="main-nav__level-1-link level-2"
                            [routerLink]="['/mon-espace-partenaire/deeptech-campus/.']" (click)="getSelectedItem('')"
                            ariaCurrentPage>
                            <span class="customised">Accéder à la communauté <br><strong> Deeptech
                                    Campus</strong></span>
                        </a>
                    </li>
                    <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'">
                        <a class="main-nav__level-1-link level-2"
                            [routerLink]="['/mon-espace-partenaire/pass-crea/.']" (click)="getSelectedItem('')"
                            ariaCurrentPage>
                            <span class="customised">Accompagner avec le <br><strong>Pass Créa Deeptech</strong></span>
                        </a>
                    </li>
                </ul>
            </div>
            <!-- Recruter -->
            <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'">
                <button class="main-nav__button main-nav__level-1-link" aria-controls="recruter"
                    (click)="getSelectedItem('recruter');toggleMenu('recruter');">
                    <span class="customised" [class.is-active-parent]="activeParentItem === 'recruter'">Recruter
                        <dep-icon [icon]="'icon-keyboard_arrow_down'" class="desktop-icon arrow-icon"
                            [class.rotate]="menuStates['recruter'] && selectedItem == 'recruter'"></dep-icon>
                    </span>
                </button>
            </li>
            <div class="main-nav__sub-menu menu-2 customised" *ngIf="menuStates['recruter']"
                [class.visible]="selectedItem == 'recruter'">
                <ul class="main-nav__level-2">
                    <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'">
                        <a class="main-nav__level-1-link level-2" [routerLink]="['/mon-espace-partenaire/tandem/.']"
                            (click)="getSelectedItem('')" ariaCurrentPage>
                            <span class="customised"><strong>Tandem :</strong> recruter un associé</span>
                        </a>
                    </li>
                    <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'">
                        <a class="main-nav__level-1-link level-2" [routerLink]="['/mon-espace-partenaire/tango/.']"
                            (click)="getSelectedItem('')" ariaCurrentPage>
                            <span class="customised"><strong>Tango :</strong> recruter un advisor</span>
                        </a>
                    </li>
                    <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'">
                        <a class="main-nav__level-1-link level-2"
                            [routerLink]="['/mon-espace-partenaire/communaute-de-talents/.']"
                            (click)="getSelectedItem('')" ariaCurrentPage>
                            <span class="customised">Accéder à la communauté Talents</span>
                        </a>
                    </li>
                </ul>
            </div>
            <!-- Financer -->
            <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'">
                <button class="main-nav__button main-nav__level-1-link" aria-controls="financer"
                    (click)="getSelectedItem('financer');toggleMenu('financer');">
                    <span class="customised" [class.is-active-parent]="activeParentItem === 'financer'">Financer
                        <dep-icon [icon]="'icon-keyboard_arrow_down'" class="desktop-icon arrow-icon"
                            [class.rotate]="menuStates['financer'] && selectedItem == 'financer'"></dep-icon>
                    </span>
                </button>
            </li>
            <div class="main-nav__sub-menu menu-3 customised" *ngIf="menuStates['financer']"
                [class.visible]="selectedItem == 'financer'">
                <ul class="main-nav__level-2">
                    <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'">
                        <a class="main-nav__level-1-link level-2"
                            [routerLink]="['/mon-espace-partenaire/bourse-french-tech-lab/.']"
                            (click)="getSelectedItem('')" ariaCurrentPage>
                            <span class="customised">Déposer un dossier <strong> BFT Lab</strong></span>
                        </a>
                    </li>
                    <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'">
                        <a class="main-nav__level-1-link level-2"
                            [routerLink]="['/mon-espace-partenaire/bftlab-prestataires/.']"
                            (click)="getSelectedItem('')" ariaCurrentPage>
                            <span class="customised">Trouver un prestataire <strong> BFT Lab</strong></span>
                        </a>
                    </li>
                    <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'"
                        *featureToggle="'enableEuroquity'">
                        <a class="main-nav__level-1-link level-2" [routerLink]="['/mon-espace-partenaire/euroquity/.']"
                            (click)="getSelectedItem('')" ariaCurrentPage>
                            <span class="customised"><strong>EuroQuity :</strong> Rechercher des investisseurs</span>
                        </a>
                    </li>
                </ul>
            </div>
            <!-- Actualités -->
            <li class="main-nav__level-1-item">
                <button class="main-nav__button main-nav__level-1-link" aria-controls="mes chiffres et actualités"
                    (click)="getSelectedItem('chiffres-et-actualités');toggleMenu('chiffres-et-actualités');">
                    <span class="customised"
                        [class.is-active-parent]="activeParentItem === 'chiffres-et-actualités'">Actualités
                        <dep-icon [icon]="'icon-keyboard_arrow_down'" class="desktop-icon arrow-icon"
                            [class.rotate]="menuStates['chiffres-et-actualités'] && selectedItem == 'chiffres-et-actualités'"></dep-icon>
                    </span>
                </button>
            </li>
            <div class="main-nav__sub-menu menu-4 customised" *ngIf="menuStates['chiffres-et-actualités']"
                [class.visible]="selectedItem == 'chiffres-et-actualités'">
                <ul class="main-nav__level-2">
                    <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'">
                        <a class="main-nav__level-1-link level-2"
                            [routerLink]="['/mon-espace-partenaire/les-actualites/.']" (click)="getSelectedItem('')"
                            ariaCurrentPage>
                            <span class="customised">Découvrir toutes les actualités</span>
                        </a>
                    </li>
                    <li class="main-nav__level-1-item" id="actuality"
                        [ngClass]="{'active-event':eventType==='actuality'}">
                        <a class="main-nav__level-1-link level-2"
                            [routerLink]="['/mon-espace-partenaire/evenements-actualites-form/.']"
                            [queryParams]="{ type: 'actuality' }" (click)="getSelectedItem('')">
                            <span class="customised">Soumettre une actualité</span>
                        </a>
                    </li>
                    <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'">
                        <a class="main-nav__level-1-link level-2"
                            [routerLink]="['/mon-espace-partenaire/calendrier-des-evenements/.']"
                            (click)="getSelectedItem('')" ariaCurrentPage>
                            <span class="customised">Voir les évènements à venir</span>
                        </a>
                    </li>
                    <li class="main-nav__level-1-item" id="event" [ngClass]="{'active-event':eventType==='event'}">
                        <a class="main-nav__level-1-link level-2"
                            [routerLink]="['/mon-espace-partenaire/evenements-actualites-form/.']"
                            [queryParams]="{ type: 'event' }" (click)="getSelectedItem('')">
                            <span class="customised">Soumettre un évènement</span>
                        </a>
                    </li>
                    <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'">
                        <a class="main-nav__level-1-link level-2"
                            href="https://observatoire.lesdeeptech.fr/dashboard?applyDefaultFilters=true"
                            target="_blank" rel="noopener noreferrer"
                            (click)="getSelectedItem('')">
                            <span class="customised">Accéder à l’<strong>Observatoire de la Deeptech</strong></span>
                        </a>
                    </li>
                </ul>
            </div>
            <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'"
                *featureToggle="'enablePartnerServices'">
                <a class="main-nav__level-1-link" [routerLink]="['/mon-espace-partenaire/les-services.html']"
                    (click)="getSelectedItem(''); closeMenu()" ariaCurrentPage>
                    <span class="customised">Services</span>
                </a>
            </li>
            <ng-container *featureToggle="'enablePartnerContacts'">
                <li class="main-nav__level-1-item" [routerLinkActive]="'is-active'" >
                    <ng-container *ngIf="user?.showContact;else elseBlock">
                        <a class="main-nav__level-1-link" [routerLink]="['/mon-espace-partenaire/mes-contacts/.']" (click)="getSelectedItem(''); closeMenu()" ariaCurrentPage>
                          <span class="customised">Annuaire</span>
                        </a>
                      </ng-container>
                      <ng-template #elseBlock>
                        <span class="annuaire_container">
                            Annuaire
                            <img src="/assets/images/icones/info-blue.svg" alt="icon info" class="icon-info customised" (mouseover)="showTooltip()" (mouseleave)="hideTooltip()"  />
                        </span>
                      </ng-template>
                      <p class="tooltip" *ngIf="isTooltip">Pour accéder à l’annuaire des membres de l’espace partenaires, vous devez activer le partage de données dans votre profil. 
                      </p>
                    <div class="tooltip_step-2">
                        <dep-guided-tour-popup [title]="'Annuaire'" [text]="'Accédez aux informations et coordonnées des membres de l’espace partenaires !'" 
                        [stepId]="2"></dep-guided-tour-popup>
                     </div>
                </li>
            </ng-container>
            <!-- Profil -->
            <ul class="main-nav__level-1 profil_nav">
                <div class="profil_name">
                    <ng-container *ngIf="isProfil">
                        <p><strong>{{ user?.firstName | titlecase }} {{ user?.lastName | uppercase
                                }}</strong>
                        </p>
                    </ng-container>
                    <ng-container *ngIf="!isProfil">
                        <p>{{ user?.firstName | titlecase }} {{ user?.lastName | uppercase }}</p>
                    </ng-container>
                </div>
                <li class="main-nav__level-1-item">
                    <button class="profil-button" [style.background-color]="this.user?.profileColor.backgroundColor" [class.is-expanded]="selectedItem == 'profil'"
                        [attr.aria-expanded]="selectedItem == 'profil' ? true : false" aria-controls="profil_level-2"
                        (click)="getSelectedItem('profil');toggleMenu('profil');">
                            <p *ngIf ="!user || user.photoUrl == null" class="customised initials" [style.color]="this.user?.profileColor.letterColor">{{this.initials}}</p>
                            <img *ngIf ="user && user.photoUrl" class="profil customised" src="{{user.photoUrl}}" alt="profil icon" />                       
                    </button>
                    <div class="tooltip_step-3">
                        <dep-guided-tour-popup [title]="'Mon profil'" [text]="'Complétez votre profil pour activer toutes les fonctionnalités et profitez au mieux de votre Espace partenaires.'" 
                         [stepId]="3" [trianglePosition]="'top-right'"></dep-guided-tour-popup>
                     </div>
                    <!-- Second level of navigation -->
                    <ul id="profil_level-2" class="main-nav__level-2 " [class.is-visible]="selectedItem == 'profil'"
                        *ngIf="menuStates['profil']">
                        <li class="main-nav__level-2-item" [routerLinkActive]="'is-active'">
                            <a class="main-nav__profil" [routerLink]="['/mon-espace-partenaire/profil.html']"
                                (click)="getSelectedItem(''); closeMenu()" ariaCurrentPage>
                                <span class="customised">Voir mon profil
                                    <dep-icon [icon]="'icon-profil'" class="icon"></dep-icon>
                                </span>
                            </a>
                        </li>
                        <li class="main-nav__level-2-item" [routerLinkActive]="'is-active'">
                            <a class="main-nav__profil" [href]="backOfficeUrl" *ngIf="user.level"
                                (click)="getSelectedItem(''); closeMenu()" target="_blank" rel="noopener noreferrer"
                                ariaCurrentPage>
                                <span>Administration
                                    <dep-icon [icon]="'icon-table'" class="icon"></dep-icon>
                                </span>
                            </a>
                        </li>
                        <li class="main-nav__level-2-item" [routerLinkActive]="'is-active'">
                            <a class="main-nav__profil" (click)="logout(); getSelectedItem(''); closeMenu()"
                                ariaCurrentPage>
                                <span class="customised">Me déconnecter
                                    <dep-icon [icon]="'icon-loging-out'" class="icon"></dep-icon>
                                </span>
                            </a>
                        </li>
                    </ul>
                    <!-- END of second level of navigation -->
                </li>
            </ul>
        </ul>

    </nav>
</header>

<!---------------------- header Mobile ---------------------------->
<header class="main-header__mobile" *ngIf="isMobile">
    <nav class="main-header__mobile-container" (clickOutside)="getSelectedItem('');closeMenu()">
        <div class="main-nav__mobile">
            <button class="menu-button" [class.is-expanded]="selectedItem == 'menu-mobile'"
                [attr.aria-expanded]="selectedItem == 'menu-mobile' ? true : false" aria-controls="profil_level-2"
                (click)="getSelectedItem('menu-mobile');toggleMenu('menu-mobile');">
                <dep-icon [icon]="'icon-menu-mobile'" class="icon-menu" *ngIf="!menuStates['menu-mobile']"></dep-icon>
                <dep-icon [icon]="'icon-close'" class="icon-menu" *ngIf="menuStates['menu-mobile']"></dep-icon>
            </button>
            <a class="main-nav__link" [routerLink]="['/mon-espace-partenaire/.']">
                <span class="customised" style="font-weight: 700;display: flex;align-items: center;">
                    <dep-icon [icon]="'icon-home'" class="icon-home"></dep-icon>
                    Espace partenaires</span>
            </a>
        </div>
        <button class="profil-button" [class.is-expanded]="selectedItem == 'profil'"
            [attr.aria-expanded]="selectedItem == 'profil' ? true : false" aria-controls="profil_level-2"
            (click)="getSelectedItem('profil');toggleMenu('profil');">
            <span>
                <img *ngIf ="!user || user.photoUrl == null" class="profil customised" src="/assets/images/espace-partenaire/icon-profil.png"
                    alt="profil icon" />
                <img *ngIf ="user && user.photoUrl" class="profil customised" src="{{user.photoUrl}}"
                    alt="profil icon" />
            </span>
        </button>
        <!-- submenu mobile -->
        <div class="submenu-mobile" [class.show]="selectedItem == 'menu-mobile'" *ngIf="menuStates['menu-mobile']">
            <ul>
                <!-- Sensibiliser -->
                <li>
                    <dep-collapse-menu [title]="'Sensibiliser'" [selector]="'.sensibiliser'"
                        [isFocus]="true"></dep-collapse-menu>
                    <ul class="submenu-level-3 sensibiliser">
                        <li [routerLinkActive]="'is-active'">
                            <a [routerLink]="['/mon-espace-partenaire/vivier-de-temoins/.']"
                                (click)="getSelectedItem('')" ariaCurrentPage>
                                <span class="customised">Trouver un intervenant pour un évènement</span>
                            </a>
                        </li>
                        <li [routerLinkActive]="'is-active'">
                            <a [routerLink]="['/mon-espace-partenaire/deeptech-campus/.']" (click)="getSelectedItem('')"
                                ariaCurrentPage>
                                <span class="customised">Accéder à la communauté <br><strong> Deeptech
                                        Campus</strong></span>
                            </a>
                        </li>
                        <li [routerLinkActive]="'is-active'">
                            <a [routerLink]="['/mon-espace-partenaire/pass-crea/.']" (click)="getSelectedItem('')"
                                ariaCurrentPage>
                                <span class="customised">Accompagner avec le Pass Créa Deeptech</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <!-- Recruter -->
                <li>
                    <dep-collapse-menu [title]="'Recruter'" [selector]="'.recruter'"
                        [isFocus]="true"></dep-collapse-menu>
                    <ul class="submenu-level-3 recruter">
                        <li [routerLinkActive]="'is-active'">
                            <a [routerLink]="['/mon-espace-partenaire/tandem/.']" (click)="getSelectedItem('')"
                                ariaCurrentPage>
                                <span class="customised"><strong>Tandem :</strong> recruter un associé</span>
                            </a>
                        </li>
                        <li [routerLinkActive]="'is-active'">
                            <a [routerLink]="['/mon-espace-partenaire/tango/.']" (click)="getSelectedItem('')"
                                ariaCurrentPage>
                                <span class="customised"><strong>Tango :</strong> recruter un advisor</span>
                            </a>
                        </li>
                        <li [routerLinkActive]="'is-active'">
                            <a [routerLink]="['/mon-espace-partenaire/communaute-de-talents/.']"
                                (click)="getSelectedItem('')" ariaCurrentPage>
                                <span class="customised">Accéder à la communauté Talents</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <!-- Financer -->
                <li>
                    <dep-collapse-menu [title]="'Financer'" [selector]="'.financer'"
                        [isFocus]="true"></dep-collapse-menu>
                    <ul class="submenu-level-3 financer">
                        <li [routerLinkActive]="'is-active'">
                            <a [routerLink]="['/mon-espace-partenaire/bourse-french-tech-lab/.']"
                                (click)="getSelectedItem('')" ariaCurrentPage>
                                <span class="customised">Déposer un dossier <strong> BFT Lab</strong></span>
                            </a>
                        </li>
                        <li [routerLinkActive]="'is-active'">
                            <a [routerLink]="['/mon-espace-partenaire/bftlab-prestataires/.']"
                                (click)="getSelectedItem('')" ariaCurrentPage>
                                <span class="customised">Trouver un prestataire <strong> BFT Lab</strong></span>
                            </a>
                        </li>
                        <li [routerLinkActive]="'is-active'">
                            <a [routerLink]="['/mon-espace-partenaire/euroquity/.']" (click)="getSelectedItem('')"
                                ariaCurrentPage>
                                <span class="customised"><strong>EuroQuity :</strong> Rechercher des
                                    investisseurs</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <!-- Actualités -->
                <li>
                    <dep-collapse-menu [title]="'Actualités'" [selector]="'.mes-chiffres'"
                        [isFocus]="true"></dep-collapse-menu>
                    <ul class="submenu-level-3 mes-chiffres">
                        <li [routerLinkActive]="'is-active'">
                            <a [routerLink]="['/mon-espace-partenaire/les-actualites/.']" (click)="getSelectedItem('')"
                                ariaCurrentPage>
                                <span class="customised">Découvrir toutes les actualités</span>
                            </a>
                        </li>
                        <li [routerLinkActive]="'is-active'">
                            <a [routerLink]="['/mon-espace-partenaire/evenements-actualites-form/.']"
                                [queryParams]="{ type: 'actuality' }" [state]="{source:'actuality'}"
                                (click)="getSelectedItem('')" ariaCurrentPage>
                                <span class="customised">Soumettre une actualité</span>
                            </a>
                        </li>
                        <li [routerLinkActive]="'is-active'">
                            <a [routerLink]="['/mon-espace-partenaire/calendrier-des-evenements/.']"
                                (click)="getSelectedItem('')" ariaCurrentPage>
                                <span class="customised">Voir les évènements à venir</span>
                            </a>
                        </li>
                        <li [routerLinkActive]="'is-active'">
                            <a [routerLink]="['/mon-espace-partenaire/evenements-actualites-form/.']"
                                [queryParams]="{ type: 'event' }" [state]="{source:'event'}"
                                (click)="getSelectedItem('')" ariaCurrentPage>
                                <span class="customised">Soumettre un évènement</span>
                            </a>
                        </li>
                        <li [routerLinkActive]="'is-active'">
                            <a  href="https://observatoire.lesdeeptech.fr/dashboard?applyDefaultFilters=true"
                                target="_blank" rel="noopener noreferrer"
                                (click)="getSelectedItem('')">
                                <span class="customised">Accéder à l’<strong>Observatoire de la Deeptech</strong></span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="item-menu" [routerLinkActive]="'is-active'">
                    <a [routerLink]="['/mon-espace-partenaire/les-services.html']" (click)="getSelectedItem('')"
                        ariaCurrentPage>
                        <span class="customised">Services</span>
                    </a>
                </li>
                <ng-container *featureToggle="'enablePartnerContacts'">
                    <li class="item-menu" [routerLinkActive]="'is-active'" *ngIf="user.showContact">
                        <a [routerLink]="['/mon-espace-partenaire/mes-contacts/.']" (click)="getSelectedItem('')"
                            ariaCurrentPage>
                            <span>Annuaire</span>
                        </a>
                    </li>
                </ng-container>
            </ul>
        </div>

        <!-- submenu profil settings -->
        <div class="profil-settings" [class.show]="selectedItem == 'profil'" *ngIf="menuStates['profil']">
            <ul>
                <li [routerLinkActive]="'is-active'">
                    <a class="profil-settings__item" [routerLink]="['/mon-espace-partenaire/profil.html']"
                        (click)="getSelectedItem(''); closeMenu()" ariaCurrentPage>
                        <span>Voir mon profil
                            <dep-icon [icon]="'icon-profil'" class="icon"></dep-icon>
                        </span>
                    </a>
                </li>
                <li [routerLinkActive]="'is-active'">
                    <a class="profil-settings__item" [href]="backOfficeUrl" *ngIf="user.level"
                        (click)="getSelectedItem(''); closeMenu()" target="_blank" rel="noopener noreferrer"
                        ariaCurrentPage>
                        <span>Administration
                            <dep-icon [icon]="'icon-table'" class="icon"></dep-icon>
                        </span>
                    </a>
                </li>
                <li [routerLinkActive]="'is-active'">
                    <a class="profil-settings__item" (click)="logout(); getSelectedItem(''); closeMenu()"
                        ariaCurrentPage>
                        <span>Me déconnecter
                            <dep-icon [icon]="'icon-loging-out'" class="icon"></dep-icon>
                        </span>
                    </a>
                </li>
            </ul>
        </div>
    </nav>
</header>