import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'dep-hero-section-circles',
  templateUrl: './hero-section-circles.component.html',
  styleUrls: ['./hero-section-circles.component.scss']
})
export class HeroSectionCirclesComponent {
  @Input() title: string;
  @Input() text: string;
  @Input() imageUrl: string;
  constructor(private location: Location) {
  }

  goBack() {
    this.location.back();
  }
}
