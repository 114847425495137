import { Component, Input } from '@angular/core';
import { Training } from 'src/app/models/training/training.module';

@Component({
  selector: 'dep-training-card',
  templateUrl: './training-card.component.html',
  styleUrls: ['./training-card.component.scss']
})
export class TrainingCardComponent {
@Input() training: Training;
}
