<!-- Lien interne -->
<a *ngIf="!isExternalUrl" class="service__card" [routerLink]="url">
    <div class="service__card-header">
        <img src="/assets/images/v-2024/illustrations/vector-1.svg" alt="logo" class="customised" *ngIf="isLogo">
        <p class="title">{{title}}</p>
    </div>
    <div class="service__card-content">
        <p class="text" *ngIf="truncate">{{ text.length > 150 ? (text | slice:0:150) + '...' : text }}</p>
        <p class="text" *ngIf="!truncate">{{ text }}</p>
        <div class="icon-arrow__container">
            <dep-icon [icon]="'icon-arrow_forward'" class="icon"></dep-icon>
        </div>
    </div>
</a>

<!-- Lien externe -->
<a *ngIf="isExternalUrl" class="service__card" [attr.href]="url" target="_blank" rel="noopener noreferrer">
    <div class="service__card-header">
        <img src="/assets/images/v-2024/illustrations/vector-1.svg" alt="logo" class="customised" *ngIf="isLogo">
        <p class="title">{{title}}</p>
    </div>
    <div class="service__card-content">
        <p class="text" *ngIf="truncate">{{ text.length > 150 ? (text | slice:0:150) + '...' : text }}</p>
        <p class="text" *ngIf="!truncate">{{ text }}</p>
        <div class="icon-arrow__container">
            <dep-icon [icon]="'icon-arrow_forward'" class="icon"></dep-icon>
        </div>
    </div>
</a>