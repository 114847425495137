<article class="contest" *ngIf="contest">
    <a href="{{contest.externalLink}}"  target="_blank" rel="noopener noreferrer" class="contest__container">
      <p class="contest__categories">
        <span class="tag tag-type" *ngIf="contest.type !== 'APPEL_A_PROJETS'">{{ getFormattedType(contest.type | underscoreRemover) }}</span>
        <span class="tag tag-type" *ngIf="contest.type === 'APPEL_A_PROJETS'">Appel à projet</span>
        <span class="tag tag-category" *ngFor="let category of contest.tags">{{category.name}}</span>
      </p>
      <div class="contest__image">
        <div class="contest__image-wrapper"><img src="{{contest.imageUrl}}" alt="{{contest.altImage}}" loading="lazy"></div>
      </div>
      <p class="contest__date" *ngIf="displayStyle==1">
        <span>{{contest.startDate | date: 'dd'}}</span>
        {{contest.startDate | date: 'LLLL' | titlecase}}
      </p>
      <p class="contest__date" *ngIf="displayStyle==2">
        <span class="contest__dates">
          {{contest.startDate | date: 'dd'}}
          <dep-icon [icon]="'icon-arrow_forward'" class="icon-arrow"></dep-icon>
          {{contest.endDate | date: 'dd'}}
        </span>
        {{contest.startDate | date: 'LLLL' | titlecase}}
      </p>
      <p class="contest__date" *ngIf="displayStyle==3">
        <span>
          <p class="contest_months debut">{{contest.startDate | date: 'dd/MM'}}</p>
          <p class="contest_months fin">{{contest.endDate | date: 'dd/MM'}}</p>
        </span>
      </p>
      <span class="tag tag-date" *ngIf="dayLeft">{{dayLeft}}</span>
      <h3 class="title contest__title">
        {{contest.title}}
      </h3>
      <p class="contest__intro" [innerHTML]="truncatedDescription"></p>
    </a>
    <a class="icon__container" href="{{contest.externalLink}}"  target="_blank" rel="noopener noreferrer">
      <dep-icon [icon]="'icon-open_in_new'"></dep-icon>
    </a>
  </article>
  