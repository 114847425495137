import { Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { Financing } from 'src/app/models/financing/financing.model';

@Component({
  selector: 'dep-finance-dropdown',
  templateUrl: './finance-dropdown.component.html',
  styleUrls: ['./finance-dropdown.component.scss']
})
export class FinanceDropdownComponent {
  @Input() finance: Financing;
  isOpen: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  public toggleDropdown(): void {
    this.isOpen = !this.isOpen;
  }

}