import { Component, Input, OnInit, SecurityContext} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'dep-offer-card',
  templateUrl: './offer-card.component.html',
  styleUrls: ['./offer-card.component.scss']
})
export class OfferCardComponent implements OnInit{
  @Input() offerImage: string;
  @Input() offerImageAlt: string;
  @Input() offerCategories: string[];
  @Input() offerTitle: string;
  @Input() offerIntro: string;
  @Input() offerDate: Date;
  @Input() offerLocation: string;
  @Input() offerContract: string;
  @Input() offerRouterLink: string;
  @Input() offerType: string;

  public offerCategoriesToDisplay: string[] = [];

  constructor(private domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    if (this.offerCategories !== null)
      this.extractOfferCategories('secteur');
    if (this.offerIntro)
      this.offerIntro = this.domSanitizer.sanitize(SecurityContext.HTML, this.offerIntro.slice(0, 500) + '...').replace(/<p>&#160;<\/p>/g,'').toString();
  }

  extractOfferCategories(category: string) {
    this.offerCategories.forEach(tag => {
      if (category === tag.split(":")[0]) {
        this.offerCategoriesToDisplay.push(tag.split(":")[1])
      }
    })
  }
}

