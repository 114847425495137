<button class="dropdown" (click)="toggleDropdown()" [class.add-border]="!isOpen">
    <div class="dropdown__container">
        <span class="title">{{finance.financingTitle}}</span>
        <dep-icon [icon]="'icon-keyboard_arrow_down'" class="icon" [class.rotate]="isOpen"></dep-icon>
    </div>
</button>
<div class="dropdown__content" *ngIf="isOpen">
    <div class="article__wrapper">
        <ng-container *ngFor="let financingContent of finance.financingContent">
            <!-- Text -->
            <ng-container *ngIf="financingContent.textId">
                <div class="financing__content" [innerHTML]="financingContent.paragraph | markdown | async"></div>
            </ng-container>

            <!-- Banner -->
            <ng-container *ngIf="financingContent.bannerId">
                <div class="banner__container">
                    <div class="banner__content">

                        <!-- Case 1 : text & button -->
                        <ng-container *ngIf="financingContent.linkButton && !financingContent.email">
                            <p class="title">{{financingContent.text}}</p>
                            <a [href]="financingContent.linkButton" target="_blank" rel="noopener noreferrer"
                                class="ds-btn ds-btn--primary">{{financingContent.nameButton}}</a>
                        </ng-container>

                        <!-- Case 2 : text & email -->
                        <ng-container *ngIf="financingContent.email && !financingContent.linkButton">
                            <p class="title">{{financingContent.text}} : 
                                <a [href]="'mailto:' + financingContent.email" class="email">
                                    <strong>{{financingContent.email}}</strong>
                                </a>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>