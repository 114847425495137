import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalURLs } from 'src/app/GlobalURLs';
import { DetailsSu } from 'src/app/models/observatory/su-details/DetailsSu';
import { SuDetails } from 'src/app/models/observatory/su-details/SuDetails';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SuDetailsService {

  private ObsUrl = '';

  constructor(private httpClient: HttpClient, globalUrls: GlobalURLs) {
    this.ObsUrl = environment.apiUrlAws + globalUrls.apiDeeptechSu;
  }

  public getSuDetails(): Observable<SuDetails[]> {
    return this.httpClient.get<DetailsSu[]>(this.ObsUrl).pipe(
      map(
        results =>
          results.map(
            result => this.mapToSuDetails(result)
          )
      )
    );
  }
 

  private mapToSuDetails(data: DetailsSu): SuDetails {
    return {
      siren: data.siren,
      sector: data.secteur,
      companyName: data.name,
      description: data.description,
      logo: data.logo,
      postalCode: data.zip_code,
      adressLat: data.address_lat,
      adressLng: data.address_lng,
      to_follow: data.a_suivre
    }
  }
}
