<main>
    <dep-navbar-public-space class="main-header"></dep-navbar-public-space>
    <!-- Article header content -->
    <dep-article-header-v2 [articleTitle]="successStory?.title"
        [articleDate]="successStory?.publishDate | date :'d MMMM yyy' | titlecase"
        [articleDateTime]="successStory?.publishDate | date :'d MMMM yyy'"
        [articleReadingTime]="successStory?.readingTime" [buttonIcon]="'icon-share'" [buttonText]="'Partager'"
        [articleCategories]="successStory?.tags" [isPopover]="true" [title]="successStory?.title">
        <div class="breadcrumb" breadcrumb>
            <a [routerLink]="['/']">Accueil</a>
            <span>/</span>
            <a [routerLink]="['/actualites-ressources/.']">Actualités et ressources</a>
            <span>/</span>
            <a [routerLink]="['/success-stories/.']">Success stories</a>
            <span>/</span>
            <a class="highlight">{{successStory?.title | slice:0:30 }}...</a>
        </div>
        <button class="btn-previous" (click)="goBack()" breadcrumb>
            <dep-icon [icon]="'icon-arrow_previous'" class="arrow"></dep-icon>
            <p>Retour</p>
        </button>
    </dep-article-header-v2>

    <section class="success-story">
        <div class="story__container">
            <!-- Article content -->
            <div class="article-wrapper" [class.modify-margin]="!offer">
                <!-- Image -->
                <ng-container *ngIf="successStory?.bannerImageUrl ? successStory?.bannerImageUrl : successStory?.image">
                    <img src="{{successStory?.bannerImageUrl ? successStory?.bannerImageUrl : successStory?.image}}"
                        alt="{{successStory.altImage}}" class="story__image customised">
                </ng-container>
                <ng-container *ngFor="let successStoryContent of successStory?.contenuDynamique">
                    <!-- Text -->
                    <ng-container *ngIf="successStoryContent.textId">
                        <p [innerHTML]="successStoryContent.paragraph | markdown | async"></p>
                    </ng-container>

                    <!-- Quote -->
                    <ng-container *ngIf="successStoryContent.quoteId">
                        <dep-article-quote [quote]="successStoryContent.quote"
                            [quoteSource]="successStoryContent.authorName">
                        </dep-article-quote>
                    </ng-container>

                    <!-- Video -->
                    <ng-container *ngIf="successStoryContent.videoId && successStoryContent.videoUrl">
                        <dep-article-video [videoUrl]="successStoryContent.videoUrl" [videoType]="'video/mp4'"
                            [videoTitle]="successStoryContent.videoTitle"
                            [videoSource]="successStoryContent.videoSource">
                        </dep-article-video>
                    </ng-container>

                    <!-- YOUTUBE video -->
                    <!-- TODO : Allow youtube urls in inputs -->
                    <ng-container
                        *ngIf="successStoryContent.videoId && !successStoryContent.videoUrl && successStoryContent.videoSource">
                        <dep-article-youtube-video [videoUrl]="successStoryContent.videoSource"
                            [videoTitle]="successStoryContent.videoTitle"></dep-article-youtube-video>
                    </ng-container>

                    <!-- Images carousel -->

                    <ng-container *ngIf="successStoryContent.imagesId">
                        <div class="data__carrousel">
                            <dep-new-carousel #carousel>
                                <ng-container *ngFor="let imageUrl of successStoryContent.imagesUrls;let i=index">
                                    <dep-new-carousel-item [imageSrc]="imageUrl"
                                        [imageAlt]="successStoryContent.altImages[i]" *appCarouselSlide>
                                    </dep-new-carousel-item>
                                </ng-container>
                            </dep-new-carousel>
                        </div>
                        <br />
                        <!--   case of independant title and title -->
                        <p class="image-carousel__slide-title">{{successStoryContent.slideShowTitle}}</p>
                        <p class="image-carousel__slide-text">{{successStoryContent.imageSource}}</p>
                    </ng-container>

                    <!-- Image -->
                    <ng-container *ngIf="successStoryContent.imageId">
                        <dep-article-image-carousel [carouselTitle]="successStoryContent.imageTitle"
                            [carouselSource]="successStoryContent.imageSource">
                            <dep-article-image-carousel-item [imageSrc]="successStoryContent.imageUrl"
                                [imageAlt]="successStoryContent.altImage"
                                *appCarouselSlide></dep-article-image-carousel-item>
                        </dep-article-image-carousel>
                    </ng-container>

                    <!-- Document -->
                    <ng-container *ngIf="successStoryContent.documentId">
                        <dep-article-document [fileName]="successStoryContent.documentTitle"
                            [fileButtonName]="successStoryContent.downloadButtonTitle"
                            [fileURL]="successStoryContent.documentUrl"></dep-article-document>
                    </ng-container>

                    <!-- Podcast -->
                    <ng-container *ngIf="successStoryContent.podcastId">
                        <dep-article-podcast [podcast]="successStoryContent.iframe"></dep-article-podcast>
                    </ng-container>
                </ng-container>
                <!-- Likes & vues -->
                <div class="story-data" [class.add-margin]="isOpenPopup && suggestions.length == 0">
                    <div class="story-data__container">
                        <div class="story-data__item">
                            <button (click)="likesuccessStory()" [disabled]="hasLiked">
                                <img src="/assets/images/v-2024/icones/icon-like.svg" alt="icon like"
                                    class="customised">
                            </button>
                            <span>{{ successStory?.like | formatLikes }}</span>
                        </div>
                    </div>
                    <!-- btn partager -->
                    <dep-btn-cta-article [buttonIcon]="'icon-share'" (buttonAction)="setPopupStatus($event)"
                        [buttonText]="'Partager'" [isPopover]="true" [title]="successStory?.title">
                    </dep-btn-cta-article>
                </div>
            </div>
            <div class="stories__sidebar" *ngIf="offer">
                <p class="title">Offre de recrutement affiliée</p>
                <div class="offer__container">
                    <div class="offer__content">
                        <img *ngIf="offer?.logo" src="{{offer?.logo}}" alt="logo startup" class="customised" />
                        <p class="title company__title">{{offer?.companyLabel}}</p>
                        <p class="title company__profile">{{offer?.projectName}}</p>
                        <span class="city">
                            <dep-icon [icon]="'icon-place'"></dep-icon>
                            {{offer?.city}}
                        </span>
                    </div>
                    <a class="ds-btn ds-btn--secondary apply-cta" [routerLink]="offer?.internalUrl">Consulter l'offre
                        <dep-icon [icon]="'icon-open_in_new'"></dep-icon>
                    </a>
                </div>
                <a class="bloc-tango__container" [routerLink]="'/devenir-advisor/.'">
                    <img src="/assets/images/v-2024/logos/logo-tango.svg" alt="logo tango" class="customised">
                    <p class="title">Tango : recrutez ou rejoignez un advisory board</p>
                    <div class="bloc-tango__content">
                        <p class="description">Un advisory board est un comité qui apporte une expertise sur des sujets
                            stratégiques à
                            titre consultatif.</p>
                        <dep-icon [icon]="'icon-arrow_forward'"></dep-icon>
                    </div>
                </a>
            </div>
        </div>
    </section>
    <!-- Suggestions -->
    <section class="suggestions" *ngIf="suggestions?.length > 0">
        <div class="suggestions__container">
            <div class="section__header">
                <p class="title">Sur le même <strong class="highlight">thème</strong></p>
                <a class="ds-btn ds-btn--secondary" [routerLink]="'/success-stories/.'">Voir tout</a>
            </div>
            <div class="suggestions__cards">
                <ng-container *ngFor="let suggestion of suggestions| slice:0:3">
                    <dep-suggestion-card [btnLibelle]="" [suggestion]="suggestion"></dep-suggestion-card>
                </ng-container>
            </div>
            <div class="stories__cta">
                <a class="ds-btn ds-btn--secondary section__cta" [routerLink]="'/success-stories/.'">Voir tout</a>
            </div>
        </div>
    </section>
</main>
<dep-footer-public-space></dep-footer-public-space>