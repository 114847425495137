<article class="podcast-card">
    <div class="podcast-card__content">
        <span class="title">{{podcast.title}}</span>
        <p class="author">{{podcast.authorNameAndRole}}</p>
        <p class="intro">{{podcast.description}}</p>
    </div>
    <div class="podcast-card__footer">
        <p class="duration">{{podcast.duration}}</p>
        <a [routerLink]="replaceUrl()" class="ds-btn ds-btn--secondary">
            Ecouter
        </a>
    </div>
</article>