<ng-container *ngIf="show">
  <div class="toaster" [class.toaster_status_success]="status==='success'"
    [class.toaster_status_error]="status==='error'">
    <dep-icon class="toaster__icon" [icon]="'icon-check_circle'" *ngIf="status==='success'"></dep-icon>
    <dep-icon class="toaster__icon" [icon]="'icon-warning'" *ngIf="status==='error'"></dep-icon>
    <p class="toaster__title">{{toasterTitle}}</p>
    <p class="toaster__text" [innerHTML]="toasterText | markdown | async"></p>
    <button type="button" class="toaster__close" area-label="close" (click)="close()">
      <dep-icon [icon]="'icon-close'"></dep-icon>
    </button>
  </div>
</ng-container>