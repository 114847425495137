import { Component, Input } from '@angular/core';

@Component({
  selector: 'dep-testimony-card',
  templateUrl: './testimony-card.component.html',
  styleUrls: ['./testimony-card.component.scss']
})
export class TestimonyCardComponent {
   @Input() testimony: any;
}
