import { Tag } from "../tags/tag.model";

export class Suggestion {
    id: number;
    title: string;
    description: string;
    readingTime:string;
    imageUrl:string;
    publishDate:Date;
    typeArticle:TypeArticle;
    url:string;
    tag?: Tag;
}

export enum TypeArticle {
    ACTUALITE = "actualites",
    PODCAST = "podcasts",
    FORUM = "tribunes",
    ETUDE = "etudes",
    VIDEO = "videos",
    SUCCESS_STORY = "success-stories",
    SUGGESTION = "SUGGESTION"
}