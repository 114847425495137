import { Component, Input, OnInit } from '@angular/core';
import { Event } from 'src/app/models/events/events.model';

@Component({
  selector: 'dep-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss']
})
export class EventCardComponent implements OnInit {
  @Input() event: Event;
  @Input() eventIndex: number;
  displayStyle: number = 1;
  truncatedDescription: string;

  constructor() { }

  ngOnInit(): void {
    this.truncatedDescription = this.event.description.length > 250 ? this.event.description.substring(0, 250) + '...' : this.event.description;
    if(this.event!=null && this.event.endDate!=null){
      let start = new Date(this.event.date);
      let end = new Date(this.event.endDate);
      if(start.getFullYear()==end.getFullYear() && start.getMonth()==end.getMonth()){
        this.displayStyle = 2;
      }
      else{
        this.displayStyle = 3;
      }
    }
  }

}
