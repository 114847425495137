<a class="card_container" href="{{ssoCodeUrl+post.url}}" target="_blank" rel="noopener noreferrer">
    <div class="card_content">
    <div class="post_author">
        <img *ngIf="post.author.avatar && isUrl(post.author.avatar)" src="{{post.author.avatar}}" alt="image author" class="customised" />
        <div *ngIf="post.author.avatar && !isUrl(post.author.avatar)" [style.background-color]="post.author.avatar" class="circle">
            <span [style.color]="post.author.avatar | darkenColor" class="initials">{{getInitials(post.author.name)}}</span>
        </div>
        <span>{{post.author.name}} - {{post.createdAt | date :'d/MM/YYYY' }}</span>
    </div>
    <!-- <p class="text" [innerHTML]="truncateDescription(post.content) | markdown | async"></p> -->
    <p class="text" [innerHTML]="truncateDescription(post.content,100)"></p>
    <div class="post_infos">
        <div class="post_likes">
            <img src="/assets/images/icones/icon-like.svg" alt="icon like" class="customised">
            <p>{{post.reactions.likes}} Likes</p>
        </div>
        <p>{{post.reactions.comments}} Commentaires</p>
    </div>
</div>
</a>