import { Component, Input } from '@angular/core';
import { Actuality } from 'src/app/models/actualities/actuality.model';

@Component({
  selector: 'dep-actualities-list-v2',
  templateUrl: './actualities-list.component.html',
  styleUrls: ['./actualities-list.component.scss']
})
export class ActualitiesListComponentV2 {
 @Input() actualities : Actuality[];
 @Input() highlightFirst : boolean = false;
 @Input() responsiveDisplay: string;

}
