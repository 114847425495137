<div class="popup_container" [ngClass]="{'hide':close}">
  <div class="popup_wrapper">
    <div class="popup_content">
      <div class="popup_header">
        <p class="popup_title">{{title}}</p>
        <button (click)="closePopup()" area-label="close button"><img src="/assets/images/icons-map/close.png" alt="close button"/></button>
      </div>
      <p class="popup_msg" [innerHTML]="message | markdown | async"></p>
      <div class="popup_button">
        <button class="ds-btn ds-btn--primary" area-label="go to url" [routerLink]="btnUrl" [state]="{source:'popup'}">{{btnTitle}}</button>
      </div>
     <p class="popup_msg text-position">Nous analyserons votre demande et reviendrons vers vous dans les plus brefs délais.
      Bonne navigation sur la plateforme <a href="https://www.lesdeeptech.fr/" target="_blank" rel="noopener noreferrer" class="dep-link" >lesdeeptech.fr</a>
       <img src="/assets/images/icon-smile.png" alt="smiley icon" class="icon customised">
    </p>
    </div>
  </div>
</div>