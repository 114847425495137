<a class="training__card" href="{{training.externalLink}}" target="_blank" rel="noopener noreferrer">
    <div class="training__card-content">
        <span class="training__date">
            <span class="training__tag">{{training.tags[0].name}}</span>
        </span>
        <div class="training__image">
            <img  src="{{training.logoUrl}}" alt="logo training" class="customised">
        </div>
        <p class="training__title title">{{ training.title }}</p>
        <p class="training__description" [innerHTML]="(training.description | slice:0:200) + '...'"></p>
        <p class="training__expert">Expert : {{training.intervener}}</p>
    </div>
    <div class="training__footer">
        <span class="time">
            <img src="/assets/images/v-2024/icones/icon-time.svg" alt="clock" class="customised" />
            <time>{{training.duration}}</time>
        </span>
        <dep-icon [icon]="'icon-export-link'"></dep-icon>
    </div>
</a>