<div class="svg__background">
<svg width="100%" height="100%" viewBox="0 0 1957 709" fill="none" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_127_133)">
        <rect width="1955.86" height="709" transform="translate(0.275879)" fill="#1C2463" />
        <g style="mix-blend-mode:overlay" filter="url(#filter0_f_127_133)">
            <circle cx="1552.74" cy="300.17" r="493.04" fill="#0077FF" fill-opacity="0.5" />
        </g>
        <g filter="url(#filter1_d_127_133)">
            <circle cx="1738.14" cy="245.162" r="95.7557" fill="url(#paint0_radial_127_133)" />
        </g>
        <g style="mix-blend-mode:overlay" filter="url(#filter2_f_127_133)">
            <path
                d="M55.7612 495.226C123.481 443.947 373.832 73.7322 602.037 74.5581C830.241 75.3841 271.286 -461.175 93.1271 -306.952C-83.5581 -154.006 -11.9591 546.504 55.7612 495.226Z"
                fill="url(#paint1_linear_127_133)" fill-opacity="0.8" />
        </g>
        <g filter="url(#filter3_d_127_133)">
            <circle cx="1217.26" cy="73.3449" r="157.556" fill="url(#paint2_radial_127_133)" />
        </g>
        <g filter="url(#filter4_d_127_133)">
            <circle cx="665.812" cy="666.895" r="65.1954" fill="url(#paint3_radial_127_133)" />
        </g>
    </g>
    <defs>
        <filter id="filter0_f_127_133" x="733.724" y="-518.847" width="1638.03" height="1638.03"
            filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="162.988" result="effect1_foregroundBlur_127_133" />
        </filter>
        <filter id="filter1_d_127_133" x="1636.95" y="149.406" width="283.872" height="292.021"
            filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha" />
            <feOffset dx="40.7471" dy="54.3295" />
            <feGaussianBlur stdDeviation="23.09" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_127_133" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_127_133" result="shape" />
        </filter>
        <filter id="filter2_f_127_133" x="-479.448" y="-796.606" width="1597.2" height="1756.31"
            filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="230.9" result="effect1_foregroundBlur_127_133" />
        </filter>
        <filter id="filter3_d_127_133" x="1054.27" y="-84.2107" width="407.471" height="415.621"
            filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha" />
            <feOffset dx="40.7471" dy="54.3295" />
            <feGaussianBlur stdDeviation="23.09" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_127_133" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_127_133" result="shape" />
        </filter>
        <filter id="filter4_d_127_133" x="595.184" y="601.699" width="222.751" height="230.9"
            filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha" />
            <feOffset dx="40.7471" dy="54.3295" />
            <feGaussianBlur stdDeviation="23.09" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_127_133" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_127_133" result="shape" />
        </filter>
        <radialGradient id="paint0_radial_127_133" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1774.21 228.415) rotate(117.261) scale(126.559)">
            <stop offset="0.235" stop-color="#FFD00E" />
            <stop offset="1" stop-color="#EB6009" />
        </radialGradient>
        <linearGradient id="paint1_linear_127_133" x1="588.085" y1="239.758" x2="-48.4934" y2="19.2572"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#0077FF" />
            <stop offset="1" stop-color="#93ABFF" />
        </linearGradient>
        <radialGradient id="paint2_radial_127_133" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1276.61 45.7903) rotate(117.261) scale(208.239)">
            <stop offset="0.235" stop-color="#6A82FB" />
            <stop offset="1" stop-color="#284CA3" />
        </radialGradient>
        <radialGradient id="paint3_radial_127_133" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
            gradientTransform="translate(690.37 655.493) rotate(117.261) scale(86.1677)">
            <stop offset="0.235" stop-color="#FF95AB" />
            <stop offset="1" stop-color="#AA56F7" />
        </radialGradient>
        <clipPath id="clip0_127_133">
            <rect width="1955.86" height="709" fill="white" transform="translate(0.275879)" />
        </clipPath>
    </defs>
</svg>
</div>