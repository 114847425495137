import { Component, ElementRef, Input, Renderer2 } from '@angular/core';

@Component({
  selector: 'dep-page-header-v2',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponentV2 {
  @Input() title: string;
  @Input() description: string;
  @Input() maxWidth: string = '1600px';

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.applyStylesToElements('h1.title p');
    this.applyStylesToElements('p.description p');
  }

  private applyStylesToElements(selector: string) {
    const elements = this.el.nativeElement.querySelectorAll(selector);
    elements.forEach((element: HTMLElement) => {
      this.renderer.setStyle(element, 'margin-block-start', '0em');
      this.renderer.setStyle(element, 'margin-block-end', '0em');
    });
  }
}
