<button class="dropdown" (click)="toggleDropdown()" [class.add-border]="!isOpen">
    <div class="dropdown__container">
       <span>{{faqDetail.question}}</span>
       <dep-icon [icon]="'icon-keyboard_arrow_down'" class="icon" [class.rotate]="isOpen"></dep-icon>
    </div>
</button>
<div class="dropdown__content" *ngIf="isOpen">
    <div class="article__wrapper">
        <ng-container *ngFor="let faqDetailContent of faqDetail?.contents">
          <!-- Text -->
          <ng-container *ngIf="faqDetailContent.textId">
            <p [innerHTML]="faqDetailContent.paragraph | markdown | async"></p>
          </ng-container>
    
          <!-- Quote -->
          <ng-container *ngIf="faqDetailContent.quoteId">
            <dep-article-quote [quote]="faqDetailContent.quote" [quoteSource]="faqDetailContent.authorName">
            </dep-article-quote>
          </ng-container>
    
          <!-- Related content -->
          <ng-container *ngIf="faqDetailContent.linkId">
            <dep-article-related-content [relatedContent]="faqDetailContent.linkTitle"
              [relatedLinkContent]="faqDetailContent.linkUrl">
            </dep-article-related-content>
          </ng-container>
    
          <!-- Video -->
          <ng-container *ngIf="faqDetailContent.videoId && faqDetailContent.videoUrl">
            <dep-article-video [videoUrl]="faqDetailContent.videoUrl" [videoType]="'video/mp4'"
              [videoTitle]="faqDetailContent.videoTitle" [videoSource]="faqDetailContent.videoSource">
            </dep-article-video>
          </ng-container>
    
          <!-- YOUTUBE video -->
          <ng-container *ngIf="
              faqDetailContent.videoId &&
              !faqDetailContent.videoUrl &&
              faqDetailContent.videoSource
            ">
            <dep-article-youtube-video [videoUrl]="faqDetailContent.videoSource"
              [videoTitle]="faqDetailContent.videoTitle"></dep-article-youtube-video>
          </ng-container>
    
          <!-- Images carousel -->
          <ng-container *ngIf="faqDetailContent.imagesId">
            <div class="data__carrousel">
              <dep-new-carousel #carousel>
                <ng-container *ngFor="let imageUrl of faqDetailContent.imagesUrls;let i=index">
                  <dep-new-carousel-item [imageSrc]="imageUrl" [imageAlt]="faqDetailContent.altImages[i]" *appCarouselSlide>
                  </dep-new-carousel-item>
                </ng-container>
              </dep-new-carousel>
            </div>
            <br />
            <!--  case of independant title and title -->
            <p class="image-carousel__slide-title">{{faqDetailContent.slideShowTitle}}</p>
            <p class="image-carousel__slide-text">{{faqDetailContent.imagesSource}}</p>
          </ng-container>
    
          <!-- Image -->
          <ng-container *ngIf="faqDetailContent.imageId">
            <dep-article-image-carousel [carouselTitle]="faqDetailContent.imageTitle"
              [carouselSource]="faqDetailContent.imageSource">
              <dep-article-image-carousel-item [imageSrc]="faqDetailContent.imageUrl" [imageAlt]="faqDetailContent.altImage"
                *appCarouselSlide></dep-article-image-carousel-item>
            </dep-article-image-carousel>
          </ng-container>
    
          <!-- Document -->
          <ng-container *ngIf="faqDetailContent.documentId">
            <dep-article-document [fileName]="faqDetailContent.documentTitle"
              [fileButtonName]="faqDetailContent.downloadButtonTitle" [fileURL]="faqDetailContent.documentUrl">
            </dep-article-document>
          </ng-container>
    
          <!-- Podcast -->
          <ng-container *ngIf="faqDetailContent.podcastId">
            <dep-article-podcast [podcast]="faqDetailContent.iframe"></dep-article-podcast>
          </ng-container>
        </ng-container>
      </div>
</div>