<dep-navbar-public-space class="main-header"></dep-navbar-public-space>

<main class="main" *ngIf="study">
    <!-- Article header content -->
    <dep-article-header-v2 
        [articleTitle]="study.title"
        [articleDate]="study?.publishDate | date :'d MMMM yyy' | titlecase" 
        [articleDateTime]="study?.publishDate"
        [articleReadingTime]="study?.readingTime" 
        [buttonIcon]="'icon-download'"
        [buttonText]="documentExist ?'Télécharger':null" 
        [articleCategories]="study?.tags" 
        (buttonAction)="scroll()" 
        [title]="study.title" content>
        <div class="breadcrumb" breadcrumb>
            <a [routerLink]="['/']">Accueil</a>
            <span>/</span>
            <a [routerLink]="['/actualites-ressources/.']">Actualités et ressources</a>
            <span>/</span>
            <a [routerLink]="['/etudes/.']">Études</a>
            <span>/</span>
            <a [routerLink]="['/etudes/.']" class="highlight">{{study.title | slice:0:30 }}...</a>
        </div>
        <button class="btn-previous" (click)="goBack()" breadcrumb>
            <dep-icon [icon]="'icon-arrow_forward'" class="arrow"></dep-icon>
            <p>Retour</p>
        </button>
    </dep-article-header-v2>

    <!-- Article content -->
    <div class="article__wrapper">
        <ng-container *ngIf="study?.bannerImageUrl ? study?.bannerImageUrl : study?.imageUrl">
            <img src="{{study?.bannerImageUrl ? study?.bannerImageUrl : study?.imageUrl}}" alt="{{study.altImage}}" class="actuality__image customised">
          </ng-container>

        <ng-container *ngFor="let studyContent of study?.contents">

            <!-- Text -->
            <ng-container *ngIf="studyContent.textId">
                <p [innerHTML]="studyContent.paragraph | markdown | async"></p>
            </ng-container>

            <!-- Quote -->
            <ng-container *ngIf="studyContent.quoteId">
                <dep-article-quote [quote]="studyContent.quote" [quoteSource]="studyContent.authorName">
                </dep-article-quote>
            </ng-container>

            <!-- Video -->
            <ng-container *ngIf="studyContent.videoId && studyContent.videoUrl">
                <dep-article-video [videoUrl]="studyContent.videoUrl" [videoType]="'video/mp4'"
                    [videoTitle]="studyContent.videoTitle" [videoSource]="studyContent.videoSource">
                </dep-article-video>
            </ng-container>

            <!-- YOUTUBE video -->
            <!-- TODO : Allow youtube urls in inputs -->
            <ng-container *ngIf="studyContent.videoId && studyContent.videoUrl == '' && studyContent.videoSource">
                <dep-article-youtube-video [videoUrl]="studyContent.videoSource" [videoTitle]="studyContent.videoTitle">
                </dep-article-youtube-video>
            </ng-container>

            <!-- Images carousel -->
            <ng-container *ngIf="studyContent.imagesId">
                <dep-article-image-carousel [carouselTitle]="studyContent.slideShowTitle"
                    [carouselSource]="studyContent.imagesSource">
                    <ng-container *ngFor="let image of studyContent.imagesUrls">
                        <dep-article-image-carousel-item [imageSrc]="image" [imageAlt]="studyContent.altImages"
                            *appCarouselSlide></dep-article-image-carousel-item>
                    </ng-container>
                </dep-article-image-carousel>
            </ng-container>

            <!-- Image -->
            <ng-container *ngIf="studyContent.imageId">
                <dep-article-image-carousel [carouselTitle]="studyContent.imageTitle"
                    [carouselSource]="studyContent.imageSource">
                    <dep-article-image-carousel-item [imageSrc]="studyContent.imageUrl"
                        [imageAlt]="studyContent.altImage" *appCarouselSlide></dep-article-image-carousel-item>
                </dep-article-image-carousel>
            </ng-container>

            <!-- Document -->
            <section>
                <ng-container *ngIf="studyContent.documentId">
                    <dep-article-document [fileName]="studyContent.documentTitle"
                        [fileButtonName]="studyContent.downloadButtonTitle" [fileURL]="studyContent.documentUrl"
                        id="myElem"></dep-article-document>
                </ng-container>
            </section>

            <!-- Podcast -->
            <ng-container *ngIf="studyContent.podcastId">
                <dep-article-podcast [podcast]="studyContent.iframe"></dep-article-podcast>
            </ng-container>
        </ng-container>
            <!-- Likes & vues -->
            <div class="actuality-data" [class.add-margin]="isOpenPopup && suggestions.length == 0">
            <div class="actuality-data__container">
                <div class="actuality-data__item">
                    <button (click)="likeStudy()" [disabled]="hasLiked">
                        <img src="/assets/images/v-2024/icones/icon-like.svg" alt="icon like" class="customised">
                    </button>
                    <span>{{ study?.like | formatLikes }}</span>
                </div>
            </div>
            <!-- btn partager -->
            <dep-btn-cta-article [buttonIcon]="'icon-share'" [buttonText]="'Partager'" (buttonAction)="setPopupStatus($event)" [isPopover]="true" [title]="study.title" >
            </dep-btn-cta-article>
        </div>
    </div>
    <!-- Suggestions -->
    <section class="suggestions" *ngIf="suggestions?.length > 0">
        <div class="suggestions__container">
            <div class="section__header">
                <p class="title">Sur le même <strong class="highlight">thème</strong></p>
                <a class="ds-btn ds-btn--secondary" [routerLink]="'/etudes/.'">Voir toutes les études</a>
            </div>
            <div class="suggestions__cards">
                <ng-container *ngFor="let suggestion of suggestions| slice:0:3">
                    <dep-suggestion-card [btnLibelle]="" [suggestion]="suggestion"></dep-suggestion-card>
                </ng-container>
            </div>
            <div class="actus__cta">
                <a class="ds-btn ds-btn--secondary section__cta" [routerLink]="'/etudes/.'">Voir toutes les études</a>
            </div>
        </div>
    </section>
</main>
<dep-footer-public-space></dep-footer-public-space>