<dep-navbar-public-space class="main-header"></dep-navbar-public-space>
<main  *ngIf="sector">
    <!-- Hero section -->
    <section class="hero">
        <div class="breadcrumb">
            <a [routerLink]="['/']">Accueil</a>
            <span>/</span>
            <a [routerLink]="[url]"><strong class="highlight">{{sector.title}}</strong></a>
        </div>
        <div class="hero__container">
            <div class="hero__content">
                <div class="hero__title title">
                    <span class="highlight">{{sector.title}}</span>
                </div>
                <div class="hero__subtitle">Avec 215 startups référencées, dont 71 deeptech, la France est le troisième
                    pays au monde en termes de création de startups Foodtech & Agritech, tout en étant le premier pays
                    agricole de l’Union Européenne. </div>
            </div>
            <div class="hero__image">
                <img src="/assets/images/v-2024/illustrations/vector-2x.svg" alt="image" class="customised" />
            </div>
        </div>
    </section>
    <!-- NavBar -->
    <dep-menu-sticky [links]="links" [url]="url"></dep-menu-sticky>
    <!-- Description -->
    <section class="description" id="description">
        <div class="description__container">
            <div class="description__wrapper">
                <p class="title">Description</p>
                <p id="descText" [innerHTML]="sector.description| markdown" class="description__truncate"></p>
                <div class="description__btn-plus">
                    <button id="btnShowMore" class="ds-btn ds-btn--secondary" area-label="load more"
                        (click)="getAllDescription()">En savoir
                        plus</button>
                </div>
            </div>
            <div class="description__figures">
                <p class="title">Agritech et Foodtech en quelques chiffres</p>
                <ul class="description__figures-list" *ngIf="!selectedSectoralPage">
                    <li class="description__figure" *ngIf="sector.keyMetrics[0]?.value">
                        <strong>{{ sector.keyMetrics[0]?.value}}</strong>
                        <span>startups deeptech</span>
                    </li>
                    <li class="description__figure" *ngIf="sector.keyMetrics[3]?.value">
                        <strong>{{ sector.keyMetrics[3]?.value }}</strong>
                        <span>des montants levés en deeptech en France</span>
                    </li>
                    <li class="description__figure" *ngIf="sector.keyMetrics[4]?.value">
                        <strong>{{ sector.keyMetrics[4]?.value}}</strong>
                        <span>emplois générés</span>
                    </li>
                </ul>
                <ul class="description__figures-list" *ngIf="selectedSectoralPage">
                    <li class="description__figure">
                        <strong>{{selectedSectoralPage.suCount}}</strong>
                        <span>startups deeptech</span>
                    </li>
                    <li class="description__figure">
                        <strong>{{funds}}</strong>
                        <span>levés sur les 5 dernières années</span>
                    </li>
                    <li class="description__figure">
                        <strong>{{ selectedSectoralPage.jobsCount}}</strong>
                        <span>emplois générés</span>
                    </li>
                </ul>
                <a href="https://observatoire.lesdeeptech.fr/dashboard?applyDefaultFilters=true" class="ds-btn ds-btn--primary">
                    Accéder à l’Observatoire</a>
            </div>
        </div>
    </section>
    <!-- Section : Actualités -->
    <section class="actualities" id="actualities" *ngIf="actualities.length!= 0">
        <div class="section__header section__header--right">
            <p class="section__title title"><span class="highlight">Actualités</span></p>
            <a class="ds-btn ds-btn--secondary" [routerLink]="'/actualites/.'">Voir toutes les actualités</a>
        </div>
        <dep-actualities-carousel [actualities]="actualities" [isImage]="true"></dep-actualities-carousel>
        <div class="btn__cta">
            <a class="ds-btn ds-btn--secondary section__cta" [routerLink]="'/actualites/.'"
                style="margin-right: 16px;">Voir toutes les actualités</a>
        </div>
    </section>
    <!-- Section : Podcasts -->
    <section class="podcasts" id="podcasts" *ngIf="podcasts.length!= 0">
        <div class="section__header">
            <p class="section__title title"><span class="highlight">Podcasts</span></p>
            <a class="ds-btn ds-btn--secondary" [routerLink]="'/podcasts/.'">Voir tous les podcasts</a>
        </div>
        <div class="podcasts__container">
            <dep-podcast-card *ngFor="let podcast of podcasts" [podcast]="podcast"></dep-podcast-card>
        </div>
        <div class="btn__cta">
            <a class="ds-btn ds-btn--secondary section__cta" [routerLink]="'/podcasts/.'"
                style="margin-right: 16px;">Voir tous les podcasts</a>
        </div>
    </section>
       <!-- Section : Etudes -->
       <section class="etudes" id="etudes" *ngIf="studies.length!= 0">
        <div class="section__header">
            <p class="section__title title"><span class="highlight">Etudes</span></p>
            <a class="ds-btn ds-btn--secondary" [routerLink]="'/etudes/.'">Voir toutes les études</a>
        </div>
        <div class="etudes__container">
            <dep-studies-card *ngFor="let study of studies" [study]="study"></dep-studies-card>
        </div>
        <div class="btn__cta">
            <a class="ds-btn ds-btn--secondary section__cta" [routerLink]="'/etudes/.'"
                style="margin-right: 16px;">Voir toutes les études</a>
        </div>
    </section>
</main>
<dep-footer-public-space></dep-footer-public-space>