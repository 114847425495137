<article>
    <a [routerLink]="offerRouterLink" class="offer">
        <div class="offer__image">
            <img *ngIf="offerImage" [src]="offerImage" [alt]="offerImageAlt" class="customised">
            <img *ngIf="offerImage===null && offerType=='tandem'" src="assets/images/logo_tandem.jpg" alt="logo tandem" class="customised">
            <img *ngIf="offerImage===null && offerType=='advisor'" src="assets/images/logo_tango.jpg" alt="logo tango" class="customised">
            <img *ngIf="offerImage===null && offerType=='mercato'" src="assets/images/mercato.png" alt="logo mercato" class="customised">
        </div>
        <div class="offer__container">
            <div class="offer__header">
                <div class="offer__tags">
                    <span class="offer__tag" *ngFor="let category of offerCategoriesToDisplay">{{category}}</span>
                </div>
                <div class="offer__infos">
                    <span>{{(offerDate | nbDaysElapsed)}}</span>
                    <span><dep-icon class="icon" [icon]="'icon-place'"></dep-icon>{{offerLocation}}</span>
                </div>
            </div>
            <p class="title offer__title">{{offerTitle}}</p>
            <div class="offer__description" *ngIf="offerIntro">
                <p class="offer__intro" [innerHTML]="offerIntro"></p>
                <img src="/assets/images/icones/arrow_forward.svg" alt="icon arrow" class="customised">
            </div>
        </div>
    </a>
</article>