<article
  class="formation"
  [class.formation_type_ott]="formationType === 'OTT'"
>
  <a
    [href]="formationExternalLink"
    rel="noopener noreferrer"
    target="_blank"
    class="formation__container"
    [attr.aria-labelledby]="'formation-' + formationIndex"
  >
    <p
      class="formation__tag" *ngIf="formationTag"
      dep-tag-background-color 
    >
      <span class="sr-only">Catégorie : </span>{{formationTag.name}}
    </p>
    <p class="formation__duration">
      <dep-icon [icon]="'icon-timer'" class="icon"></dep-icon>
      <span class="sr-only">Durée : </span>{{formationDuration}}
    </p>
    <img
      src="{{formationLogo}}"
      alt="{{formationLogoAlt}}"
      class="formation__logo"
      *ngIf="formationLogo"
    >
    <h3 id="formation-{{formationIndex}}" class="formation__title">
      <span class="sr-only">Aller vers la formation : </span>{{formationTitle}}
    </h3>
    <p class="formation__intro" [innerHTML]="formationIntro | markdown | async"></p>
    <p
      class="formation__expert"
      *ngIf="formationExpert"
    >
      Expert&nbsp;: {{formationExpert}}
    </p>
    <div
      class="formation__rating"
      *ngIf="formationLevel"
    >
      <!-- <img src="/assets/images/redesign-v2021/formations/rating-0.svg" width="88" height="32" alt="Niveau : 0" *ngIf="formationLevel == 0"> -->
      <img src="/assets/images/redesign-v2021/formations/rating-1.svg" width="88" height="32" alt="Niveau : 1" *ngIf="formationLevel == 'initiation' ">
      <img src="/assets/images/redesign-v2021/formations/rating-2.svg" width="88" height="32" alt="Niveau : 2" *ngIf="formationLevel == 'perfectionnement' ">
      <img src="/assets/images/redesign-v2021/formations/rating-3.svg" width="88" height="32" alt="Niveau : 3" *ngIf="formationLevel == 'expertise' ">
    </div>
  </a>
</article>
