<div class="swiper-container">
    <div class="swiper-wrapper">
        <div class="swiper-slide" *ngFor="let actuality of actualities">
            <a class="actuality__card" [routerLink]="replaceUrl(actuality)">
                <div class="actuality__card-content">
                    <span class="actuality__date">
                        <p>{{actuality.publishDate | date: 'dd LLLL' }}</p>
                        <p>{{actuality.readingTime}}</p>
                    </span>
                    <span class="actuality__tag">{{actuality.tags[0].name}}</span>
                    <div class="actuality__image" *ngIf="isImage">
                        <img  src="{{actuality.imageUrl}}" alt="{{actuality.altImage}}" class="customised">
                    </div>
                    <p class="actuality__title title">{{ actuality.title }}</p>
                    <p class="actuality__description" [innerHTML]="(actuality.description | slice:0:200) + '...'"></p>
                </div>
                <div class="actuality__footer">
                    <span>Lire
                        <img src="/assets/images/icones/arrow_forward.svg" alt="icon arrow" class="customised">
                    </span>
                </div>
            </a>
        </div>
    </div>
    <!-- Add Pagination -->
    <div class="swiper-pagination"></div>
</div>