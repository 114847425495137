import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ObsNumberFormatterService {

  constructor() { }

  public fundsFormatter(funds: number): string {
    funds = this.convertScientificNotation(funds)/1000000;
    var milliards: number;
    var numberSize: number = Math.round(funds).toPrecision().length;
    switch (numberSize) {
      case 4:
      case 3:
        milliards = funds / 1000;
        milliards = Math.round(milliards * 10) / 10;
        if (milliards >= 2)
          return milliards + "Mds €";
        else
          return milliards + "Md €";
      case 2:
        return Math.round(funds) + "M €";
      case 1:
        return Math.round(funds * 10) / 10 + "M €";
      default:
        return null;
    }
  }

  public regionFundsFormatter(funds: number): string {
    var milliards: number;
    var numberSize: number = Math.round(funds).toFixed().length;
    funds = Number.parseInt(Math.round(funds).toFixed());
    if (numberSize > 9) {
      milliards = funds / 1000000000;
      milliards = Math.round(milliards * 10) / 10;
      if (milliards >= 2)
        return milliards + "Mds €";
      else
        return milliards + "Md €";
    }
    if (numberSize < 10) {
      milliards = funds / 1000000;
      return Math.round(milliards) + "M €";
    }
    return null;

  }

  public percentageFormatter(percentage:number){
    return Math.round(percentage*10)/10;
  }

  public convertScientificNotation(value): number {
    return Number.parseFloat(value);
  }

}
