import { CUSTOM_ELEMENTS_SCHEMA, NgModule, SecurityContext } from '@angular/core';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { MarkdownModule } from 'ngx-markdown';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FormatUrlPipe } from 'src/app/shared/pipes/format-url.pipe';
import { SharedModule } from 'src/app/shared/shared.module';
import { BasicElementsModule } from '../basic-elements/basic-elements.module';
import { ActualitiesListComponent } from './actualities/actualities-list/actualities-list.component';
import { ActualityBlocHorizontalComponent } from './actualities/actuality-bloc-horizontal/actuality-bloc-horizontal.component';
import { ActualityBlocComponent } from './actualities/actuality-bloc/actuality-bloc.component';
import { ActualityThumbnailHomeComponent } from './actualities/actuality-thumbnail-home/actuality-thumbnail-home.component';
import { ActualityThumbnailComponent } from './actualities/actuality-thumbnail/actuality-thumbnail.component';
import { ArticleImageCarouselItemComponent } from './article-image-carousel/article-image-carousel-item/article-image-carousel-item.component';
import { ArticleImageCarouselComponent } from './article-image-carousel/article-image-carousel/article-image-carousel.component';
import { ArticleThumbnailComponent } from './article-thumbnail/article-thumbnail.component';
import { ArticleCategoriesComponent } from './articles/article-categories/article-categories.component';
import { ArticleHeaderComponent } from './articles/article-header/article-header.component';
import { ArticleStickyHeaderComponent } from './articles/article-sticky-header/article-sticky-header.component';
import { BdHorizontalThumbnailComponent } from './bande-de-dessin/bd-horizontal-thumbnail/bd-horizontal-thumbnail.component';
import { BdListItemComponent } from './bande-de-dessin/bd-list-item/bd-list-item.component';
import { BdThumbnailComponent } from './bande-de-dessin/bd-thumbnail/bd-thumbnail.component';
import { BreadcrumbItemComponent } from './breadcrumb/breadcrumb-item/breadcrumb-item.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { BriefsCarouselComponent } from './briefs/briefs-carousel/briefs-carousel.component';
import { BriefsListItemComponent } from './briefs/briefs-list-item/briefs-list-item.component';
import { BriefsListComponent } from './briefs/briefs-list/briefs-list.component';
import { BriefsThumbnailComponent } from './briefs/briefs-thumbnail/briefs-thumbnail.component';
import { BtnHelpComponent } from './btn-help/btn-help.component';
import { CardsCarouselComponent } from './cards-carousel/cards-carousel.component';
import { CardsListComponent } from './cards-list/cards-list.component';
import { ContactListComponent } from './contacts/contact-list/contact-list.component';
import { ContactComponent } from './contacts/contact/contact.component';
import { ArticleDocumentComponent } from './contents/article-document/article-document.component';
import { ArticlePodcastComponent } from './contents/article-podcast/article-podcast.component';
import { ArticleQuoteComponent } from './contents/article-quote/article-quote.component';
import { ArticleRelatedContentComponent } from './contents/article-related-content/article-related-content.component';
import { ArticleVideoComponent } from './contents/article-video/article-video.component';
import { ArticleYoutubeVideoComponent } from './contents/article-youtube-video/article-youtube-video.component';
import { DocumentsListComponent } from './contents/documents-list/documents-list.component';
import { QuoteBlockComponent } from './contents/quote-block/quote-block.component';
import { ContestsListComponent } from './contests/contests-list/contests-list.component';
import { ContestsThumbnailComponent } from './contests/contests-thumbnail/contests-thumbnail.component';
import { DatatableObservatoryComponent } from './datatables/datatable-observatory/datatable-observatory.component';
import { DatatableTaleezComponent } from './datatables/datatable-taleez/datatable-taleez.component';
import { DefinitionsListItemComponent } from './definitions/definitions-list-item/definitions-list-item.component';
import { DefinitionsListComponent } from './definitions/definitions-list/definitions-list.component';
import { DesireUndertakeHeaderComponent } from './desire-undertake-header/desire-undertake-header.component';
import { DynamicMapComponent } from './dynamic-map/dynamic-map.component';
import { EncartComponent } from './encart/encart.component';
import { EventBlocComponent } from './events/event-bloc/event-bloc.component';
import { EventThumbnailComponent } from './events/event-thumbnail/event-thumbnail.component';
import { EventsListComponent } from './events/events-list/events-list.component';
import { FormSectionComponent } from './form-section/form-section.component';
import { ForumsListItemComponent } from './forums/forums-list-item/forums-list-item.component';
import { ForumsListComponent } from './forums/forums-list/forums-list.component';
import { GoFurtherComponent } from './go-further/go-further.component';
import { JobOffersListComponent } from './job-offers-list/job-offers-list.component';
import { KeyContributorsComponent } from './key-contributors/key-contributors.component';
import { LargeStepsItemComponent } from './large-steps-item/large-steps-item.component';
import { LargeStepsComponent } from './large-steps/large-steps.component';
import { ListFilterCombinedComponent } from './list-filter-combined/list-filter-combined.component';
import { MapComponent } from './map/map.component';
import { MemberComponent } from './members/member/member.component';
import { MembersListComponent } from './members/members-list/members-list.component';
import { NewsCarouselComponent } from './news-carousel/news-carousel.component';
import { NewsThumbnailComponent } from './news-thumbnail/news-thumbnail.component';
import { NumberCardComponent } from './number-card/number-card.component';
import { NumbersGridComponent } from './numbers-grid/numbers-grid.component';
import { OfferComponent } from './offer/offer.component';
import { OfferLinkComponent } from './offers/offer-link/offer-link.component';
import { OffersListHeaderComponent } from './offers/offers-list-header/offers-list-header.component';
import { OffersListItemComponent } from './offers/offers-list-item/offers-list-item.component';
import { PageHeaderImageComponent } from './page-headers/page-header-image/page-header-image.component';
import { PageHeaderNavComponent } from './page-headers/page-header-nav/page-header-nav.component';
import { PageHeaderSimpleComponent } from './page-headers/page-header-simple/page-header-simple.component';
import { PageHeaderTextImageComponent } from './page-headers/page-header-text-image/page-header-text-image.component';
import { PageHeaderTimelineComponent } from './page-headers/page-header-timeline/page-header-timeline.component';
import { PageHeaderComponent } from './page-headers/page-header/page-header.component';
import { PageSectionNewComponent } from './page-section-new/page-section-new.component';
import { PageSectionComponent } from './page-section/page-section.component';
import { PaginationSystemComponent } from './pagination-system/pagination-system.component';
import { PaginationComponent } from './pagination/pagination.component';
import { PartnerThumbnailDetailComponent } from './partners/partner-thumbnail-detail/partner-thumbnail-detail.component';
import { PartnerThumbnailComponent } from './partners/partner-thumbnail/partner-thumbnail.component';
import { PartnersListComponent } from './partners/partners-list/partners-list.component';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { PodcastBlocComponent } from './podcasts/podcast-bloc/podcast-bloc.component';
import { PodcastThumbnailComponent } from './podcasts/podcast-thumbnail/podcast-thumbnail.component';
import { PodcastsCarouselComponent } from './podcasts/podcasts-carousel/podcasts-carousel.component';
import { PodcastsListItemComponent } from './podcasts/podcasts-list-item/podcasts-list-item.component';
import { PodcastsListComponent } from './podcasts/podcasts-list/podcasts-list.component';
import { PopupCguComponent } from './popup-cgu/popup-cgu.component';
import { PopupEventComponent } from './popup-event/popup-event.component';
import { PopupComponent } from './popup/popup.component';
import { ProjectThumbnailComponent } from './project-thumbnail/project-thumbnail.component';
import { QuoteBlocksCarouselComponent } from './quote-blocks-carousel/quote-blocks-carousel.component';
import { ServiceThumbnailDetailComponent } from './services/service-thumbnail-detail/service-thumbnail-detail.component';
import { ServiceThumbnailComponent } from './services/service-thumbnail/service-thumbnail.component';
import { ServicesListComponent } from './services/services-list/services-list.component';
import { StartupCardComponent } from './startup-card/startup-card.component';
import { StepsGridItemComponent } from './steps-grid/steps-grid-item/steps-grid-item.component';
import { StepsGridComponent } from './steps-grid/steps-grid.component';
import { StructureThumbnailComponent } from './structures-thumbnail/structure-thumbnail.component';
import { StudiesListComponent } from './studies/studies-list/studies-list.component';
import { StudyThumbnailComponent } from './studies/study-thumbnail/study-thumbnail.component';
import { TemoinComponent } from './temoins/temoin/temoin.component';
import { TemoinsListComponent } from './temoins/temoins-list/temoins-list.component';
import { TextComponent } from './text/text.component';
import { TimelineSectionComponent } from './timelines/timeline-section/timeline-section.component';
import { TimelineComponent } from './timelines/timeline/timeline.component';
import { ToasterComponent } from './toaster/toaster.component';
import { ToolboxComponent } from './toolbox/toolbox.component';
import { TrainingListComponent } from './training/training-list/training-list.component';
import { TrainingThumbnailComponent } from './training/training-thumbnail/training-thumbnail.component';
import { VaguesBleuClairComponent } from './vagues-bleu-clair/vagues-bleu-clair.component';
import { VideoBlocComponent } from './videos/video-bloc/video-bloc.component';
import { VideoCardListComponent } from './videos/video-card-list/video-card-list.component';
import { VideoCardComponent } from './videos/video-card/video-card.component';
import { VideoCarouselComponent } from './videos/video-carousel/video-carousel.component';
import { VideoListItemComponent } from './videos/video-list-item/video-list-item.component';
import { VideoListComponent } from './videos/video-list/video-list.component';
import { VideoThumbnailComponent } from './videos/video-thumbnail/video-thumbnail.component';
import { ServiceBlocComponent } from './service-bloc/service-bloc.component';
import { PostComponent } from './post/post.component';
import { PostCardComponent } from './post-card/post-card.component';
import { DarkenColorPipe } from 'src/app/shared/pipes/darken-color/darken-color.pipe';
import { CardStructureComponent } from './card-structure/card-structure.component';
import { PopupContainerComponent } from './popup-container/popup-container.component';
import { DropdownComponent } from './backoffice/dropdown/dropdown.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { RssCarouselComponent } from './rss-carousel/rss-carousel.component';
import { StartupsCarouselComponent } from './v-2024/startups-carousel/startups-carousel.component';
import { ActualitiesCarouselComponent } from './v-2024/actualities-carousel/actualities-carousel.component';
import { CardComponent } from './v-2024/card/card.component';
import { OfferCardComponent } from './v-2024/offer-card/offer-card.component';
import { TestimonyCardComponent } from './v-2024/testimony-card/testimony-card.component';
import { PodcastCardComponent } from './v-2024/podcast-card/podcast-card.component';
import { StudiesCardComponent } from './v-2024/studies-card/studies-card.component';
import { MenuStickyComponent } from './v-2024/menu-sticky/menu-sticky.component';

@NgModule({
  declarations: [
    CardsListComponent,
    FormSectionComponent,
    GoFurtherComponent,

    PageSectionComponent,
    ArticleThumbnailComponent,
    PdfViewerComponent,
    VaguesBleuClairComponent,
    TextComponent,


    // module keys
    KeyContributorsComponent,

    // module PageSection
    PageSectionComponent,
    PageSectionNewComponent,

    // module PageHeaders
    PageHeaderComponent,
    PageHeaderImageComponent,
    PageHeaderNavComponent,
    PageHeaderSimpleComponent,
    PageHeaderTimelineComponent,
    PageHeaderTextImageComponent,
    DesireUndertakeHeaderComponent,

    // articles
    ArticleCategoriesComponent,
    ArticleStickyHeaderComponent,
    ArticleHeaderComponent,

    // timeline
    TimelineSectionComponent,
    TimelineComponent,

    // module Breadcrumb
    BreadcrumbComponent,
    BreadcrumbItemComponent,


    // module Actualities
    ActualitiesListComponent,
    ActualityThumbnailComponent,

    // module Briefs
    BriefsCarouselComponent,
    BriefsListComponent,
    BriefsListItemComponent,
    BriefsThumbnailComponent,

    // module Studies
    StudiesListComponent,
    StudyThumbnailComponent,

    // module Podcasts
    PodcastThumbnailComponent,
    PodcastsCarouselComponent,
    PodcastsListItemComponent,
    PodcastsListComponent,

    // module Forums
    ForumsListComponent,
    ForumsListItemComponent,

    // module events
    EventsListComponent,
    EventThumbnailComponent,

    // module Contests
    ContestsListComponent,
    ContestsThumbnailComponent,

    // module contents
    ArticleDocumentComponent,
    ArticlePodcastComponent,
    ArticleQuoteComponent,
    ArticleRelatedContentComponent,
    ArticleVideoComponent,
    ArticleYoutubeVideoComponent,
    DocumentsListComponent,
    QuoteBlockComponent,
    QuoteBlocksCarouselComponent,

    ListFilterCombinedComponent,

    NewsCarouselComponent,
    NewsThumbnailComponent,
    ActualityThumbnailHomeComponent,
    ArticleImageCarouselComponent,
    ArticleImageCarouselItemComponent,

    StepsGridComponent,
    StepsGridItemComponent,
    NumbersGridComponent,
    NumberCardComponent,

    // module joboffers
    OfferLinkComponent,
    OffersListItemComponent,
    OffersListHeaderComponent,
    JobOffersListComponent,

    // module Toaster
    ToasterComponent,

    //training module
    TrainingListComponent,
    TrainingThumbnailComponent,
    EncartComponent,
    DefinitionsListComponent,
    DefinitionsListItemComponent,
    ToolboxComponent,
    StructureThumbnailComponent,
    ProjectThumbnailComponent,
    LargeStepsComponent,
    LargeStepsItemComponent,
    VideoListComponent,
    VideoListItemComponent,
    VideoThumbnailComponent,
    VideoCarouselComponent,
    VideoCardComponent,
    VideoCardListComponent,
    MapComponent,
    CardsCarouselComponent,
    StartupCardComponent,
    VideoBlocComponent,
    PodcastBlocComponent,

    // partner space
    ServiceThumbnailComponent,
    ServicesListComponent,
    PartnersListComponent,
    PartnerThumbnailComponent,
    PopupComponent,
    ServiceThumbnailDetailComponent,
    PartnerThumbnailDetailComponent,
    PopupCguComponent,
    TemoinComponent,
    TemoinsListComponent,
    ActualityBlocHorizontalComponent,
    ActualityBlocComponent,
    EventBlocComponent,
    BtnHelpComponent,   
    MembersListComponent,
    MemberComponent,
    OfferComponent,
    ContactComponent,
    ContactListComponent,
    ServiceBlocComponent,
    PostComponent,
    PostCardComponent,
    CardStructureComponent,
    ProgressBarComponent,
    RssCarouselComponent,

    // Pagination
    PaginationComponent,
    PaginationSystemComponent,

    // Dynamic Map
    DynamicMapComponent,

    // DataTables
    DatatableTaleezComponent,
    DatatableObservatoryComponent,
    VideoCardListComponent,

    //bande dessinée
    BdThumbnailComponent,
    BdHorizontalThumbnailComponent,
    BdListItemComponent,

    //popups
    PopupEventComponent,
    PopupContainerComponent,
    
    //pipes
    DarkenColorPipe,

    //backoffice
    DropdownComponent,

    //carousel
    StartupsCarouselComponent,
    ActualitiesCarouselComponent,

    //V-2024
    CardComponent,
    OfferCardComponent,
    TestimonyCardComponent,
    PodcastCardComponent,
    StudiesCardComponent,
    MenuStickyComponent,

  ],

  imports: [
    BasicElementsModule,
    SharedModule,
    //  BasicFormsModule,
    //  BreadcrumbV2021Module,
    // BlocksActualitiesModule,
    // BlocksBriefsModule,
    // BlocksContentsModule,
    // BlocksForumsModule,
    // BlocksPodcastsModule,
    // BlocksStudiesModule,
    //  BlocksPageHeadersModule,
    // BlocksEventsModule,
    
    SlickCarouselModule,
    FeatureToggleModule,
    MarkdownModule.forRoot({
      sanitize: SecurityContext.NONE, // turn off sanitization
    }),
    // RouterModule
  ],
  exports: [
    // LargeStepItem component
    LargeStepsItemComponent,
    //Large Step component
    LargeStepsComponent,
    //Project aka CONTEST thumbnail
    ProjectThumbnailComponent,
    //Structure-Thumbnail
    StructureThumbnailComponent,

    //toolbox
    ToolboxComponent,

    //definitions components
    DefinitionsListComponent,
    DefinitionsListItemComponent,

    //training module
    TrainingListComponent,
    TrainingThumbnailComponent,
    // module Toaster
    ToasterComponent,

    CardsListComponent,
    FormSectionComponent,
    GoFurtherComponent,

    PageSectionComponent,
    ArticleThumbnailComponent,
    PdfViewerComponent,
    VaguesBleuClairComponent,
    TextComponent,


    // module keys
    KeyContributorsComponent,

    // module PageSection
    PageSectionComponent,
    PageSectionNewComponent,

    // module PageHeaders
    PageHeaderComponent,
    PageHeaderImageComponent,
    PageHeaderNavComponent,
    PageHeaderSimpleComponent,
    PageHeaderTimelineComponent,
    PageHeaderTextImageComponent,
    DesireUndertakeHeaderComponent,

    // articles
    ArticleCategoriesComponent,
    ArticleStickyHeaderComponent,
    ArticleHeaderComponent,

    // timeline
    TimelineSectionComponent,
    TimelineComponent,

    // module Breadcrumb
    BreadcrumbComponent,
    BreadcrumbItemComponent,

    // module Actualities
    ActualitiesListComponent,
    ActualityThumbnailComponent,

    // module Briefs
    BriefsCarouselComponent,
    BriefsListComponent,
    BriefsListItemComponent,
    BriefsThumbnailComponent,

    // module Studies
    StudiesListComponent,
    StudyThumbnailComponent,

    // module Podcasts
    PodcastThumbnailComponent,
    PodcastsCarouselComponent,
    PodcastsListItemComponent,
    PodcastsListComponent,
    PodcastBlocComponent,

    // module Forums
    ForumsListComponent,
    ForumsListItemComponent,

    // module events
    EventsListComponent,
    EventThumbnailComponent,

    // module Contests
    ContestsListComponent,
    ContestsThumbnailComponent,

    // module contents
    ArticleDocumentComponent,
    ArticlePodcastComponent,
    ArticleQuoteComponent,
    ArticleRelatedContentComponent,
    ArticleVideoComponent,
    ArticleYoutubeVideoComponent,
    DocumentsListComponent,
    QuoteBlockComponent,
    QuoteBlocksCarouselComponent,

    ListFilterCombinedComponent,

    NewsCarouselComponent,
    NewsThumbnailComponent,
    ActualityThumbnailHomeComponent,
    ArticleImageCarouselComponent,
    ArticleImageCarouselItemComponent,

    OffersListHeaderComponent,
    PageHeaderSimpleComponent,
    StepsGridComponent,
    StepsGridItemComponent,
    NumbersGridComponent,
    NumberCardComponent,

    // module joboffers
    OfferLinkComponent,
    OffersListItemComponent,
    JobOffersListComponent,

    ToasterComponent,
    EncartComponent,
    
    //Videos
    VideoListComponent,
    VideoListItemComponent,
    VideoThumbnailComponent,
    VideoCarouselComponent,
    VideoCardComponent,
    VideoCardListComponent,
    VideoBlocComponent,

    //map
    MapComponent,
    CardsCarouselComponent,
    StartupCardComponent,

    // partner space
    ServiceThumbnailComponent,
    ServicesListComponent,
    PartnersListComponent,
    PartnerThumbnailComponent,
    PopupComponent,
    ServiceThumbnailDetailComponent,
    PartnerThumbnailDetailComponent,
    PopupCguComponent,
    TemoinComponent,
    TemoinsListComponent,
    ActualityBlocHorizontalComponent,
    ActualityBlocComponent,
    EventBlocComponent,
    BtnHelpComponent,   
    MembersListComponent,
    MemberComponent,
    OfferComponent,
    ContactComponent,
    ContactListComponent,
    ServiceBlocComponent,
    PostComponent,
    PostCardComponent,
    CardStructureComponent,
    ProgressBarComponent,
    RssCarouselComponent,

    // Pagination
    PaginationComponent,
    PaginationSystemComponent,

    // Dynamic Map
    DynamicMapComponent,

    // DataTables
    DatatableTaleezComponent,
    DatatableObservatoryComponent,

    //bande dessinée
    BdThumbnailComponent,
    BdHorizontalThumbnailComponent,
    BdListItemComponent,

    //popups
    PopupEventComponent,
    PopupContainerComponent,
    
    //pipes
    DarkenColorPipe,

    //backoffice
    DropdownComponent,

    //carousel
    StartupsCarouselComponent,
    ActualitiesCarouselComponent,

    //V-2024
    CardComponent,
    OfferCardComponent,
    TestimonyCardComponent,
    PodcastCardComponent,
    StudiesCardComponent,
    MenuStickyComponent,
   
  ],
  providers: [FormatUrlPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BlocksModule { }
